export default [
  {
    path: '/roszdravnadzor',
    name: 'roszdravnadzor',
    component: () => import(/* webpackChunkName: "roszdravnadzor" */ '@/views/roszdravnadzor/RoszdavnadzorList.vue'),
    meta: {
      auth: ['Super-Admin', 'Admin', 'Manager'],
    },
  },
  {
    path: '/roszdravnadzor/:id/edit',
    name: 'roszdravnadzorEdit',
    component: () => import(/* webpackChunkName: "roszdravnadzor-edit" */ '@/views/roszdravnadzor/RoszdavnadzorEdit.vue'),
    meta: {
      auth: ['Super-Admin', 'Admin', 'Manager'],
    },
  },
  {
    path: '/roszdravnadzor-create',
    name: 'roszdravnadzorCreate',
    component: () => import(/* webpackChunkName: "roszdravnadzor-create" */ '@/views/roszdravnadzor/RoszdavnadzorCreate.vue'),
    meta: {
      auth: ['Super-Admin', 'Admin', 'Manager'],
    },
  }
];
