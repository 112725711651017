<template>
  <g id="surface1">
    <path d="M 10.320312 0.152344 C 9.375 0.308594 8.808594 0.445312 8.019531 0.722656 C 4.574219 1.910156 1.734375 4.824219 0.628906 8.285156 C 0.0234375 10.179688 -0.128906 11.921875 0.152344 13.734375 C 0.566406 16.417969 1.6875 18.636719 3.574219 20.507812 C 5.464844 22.375 7.617188 23.457031 10.28125 23.863281 C 11.492188 24.054688 12.523438 24.054688 13.734375 23.863281 C 16.394531 23.457031 18.613281 22.335938 20.476562 20.476562 C 22.335938 18.613281 23.457031 16.394531 23.863281 13.734375 C 24.34375 10.648438 23.53125 7.425781 21.636719 4.871094 C 20.714844 3.636719 19.28125 2.355469 17.96875 1.605469 C 15.660156 0.285156 12.84375 -0.253906 10.320312 0.152344 Z M 14.125 2.824219 C 17.253906 3.636719 19.6875 5.738281 20.785156 8.59375 C 21.269531 9.835938 21.367188 10.410156 21.367188 12.007812 C 21.367188 13.605469 21.269531 14.179688 20.785156 15.421875 C 19.589844 18.523438 16.664062 20.847656 13.351562 21.335938 C 12.367188 21.476562 10.648438 21.390625 9.722656 21.144531 C 6.34375 20.269531 3.75 17.671875 2.863281 14.292969 C 2.601562 13.273438 2.53125 11.425781 2.714844 10.394531 C 3.4375 6.542969 6.316406 3.589844 10.152344 2.761719 C 11.09375 2.554688 13.226562 2.59375 14.125 2.824219 Z M 14.125 2.824219 "/>
    <path d="M 8.15625 5.808594 L 7.980469 5.839844 L 7.980469 12.660156 L 6.0625 12.660156 L 6.0625 14.578125 L 7.980469 14.578125 L 7.980469 15.730469 L 6.0625 15.730469 L 6.0625 17.648438 L 7.980469 17.648438 L 7.980469 19.871094 L 10.195312 19.871094 L 10.21875 18.773438 L 10.242188 17.683594 L 11.1875 17.664062 L 12.121094 17.640625 L 12.121094 15.730469 L 10.203125 15.730469 L 10.203125 14.59375 L 13.066406 14.554688 C 15.476562 14.53125 15.988281 14.5 16.375 14.394531 C 18.152344 13.871094 18.714844 12.882812 18.722656 10.257812 C 18.722656 7.003906 17.90625 6.023438 15.039062 5.832031 C 13.988281 5.753906 8.523438 5.738281 8.15625 5.808594 Z M 15.972656 7.957031 C 16.304688 8.109375 16.34375 8.15625 16.472656 8.570312 C 16.640625 9.136719 16.664062 11.054688 16.503906 11.601562 C 16.242188 12.515625 15.667969 12.652344 12.300781 12.660156 L 10.203125 12.660156 L 10.203125 7.742188 L 12.914062 7.765625 C 15.5 7.789062 15.628906 7.796875 15.972656 7.957031 Z M 15.972656 7.957031 "/>
  </g>
</template>

<script>
export default {
  name: "NotPaidIcon"
}
</script>

<style scoped>

</style>