<template>
    <div class="upload-image">
        <div class="input-wrap" v-show="!previewImage || !showPreview">
            <span @click="$refs[refKey].click">
                <slot name="content">
                    <span class="btn btn-primary inline-block">{{ title }}</span>
                </slot>
            </span>

            <input
                :ref="refKey"
                type="file"
                :accept="acceptExtensions"
                @change="uploadFile"
                class="input"
                :class="{ 'input-w-0': !showPreview }"
            />
        </div>
        <div class="preview" v-if="acceptExtensions === '.mp3' && previewImage && showPreview">
            <audio controls>
                <source :src="previewImage" type="audio/ogg" />
                <source :src="previewImage" type="audio/mpeg" />
            </audio>
            <slot name="remove" remove="remove">
              <span class="cursor-pointer" @click="remove"><XCircleIcon /></span>
            </slot>
        </div>
        <div v-else class="preview" :class="{ active: previewImage && showPreview }">
            <div v-if="previewImage && showPreview" class="item">
                <slot name="image" :preview="previewImage">
                    <img :src="previewImage" :alt="previewImage" />
                </slot>
                <slot name="remove" remove="remove">
                    <span @click="remove"><XCircleIcon /></span>
                </slot>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'UploadImage',
    data() {
        return {
            previewImage: null,
        };
    },
    props: {
        showPreview: {
            default: true,
        },
        refKey: {
            type: String,
            default: 'uploadImageInput',
        },
        title: {
            type: String,
            default: 'Загрузить изображение',
        },
        image: {
            type: String,
            default: null,
        },
        modelValue: null,
        delete: Boolean,
        acceptExtensions: {
            type: String,
            default: 'image/jpeg, image/png, image/gif, image/gif, .pdf, .doc, .dotx, .docx, .xls, .xlsx,.zip',
        },
    },
    mounted() {
        this.fillPreviewImageOnInit();
    },
    watch: {
        image: function(val) {
            this.previewImage = val;
        },
    },
    methods: {
        fillPreviewImageOnInit() {
            if (this.image) this.previewImage = this.image;
        },
        uploadFile(e) {
            this.$emit(`update:modelValue`, e.target.files[0]);
            this.$emit('uploadFile', e.target.files[0]);
            this.previewImage = window.URL.createObjectURL(e.target.files[0]);
            this.$emit('previewImage', this.previewImage);
        },
        remove() {
            if (this.image === this.previewImage) {
                this.$emit(`update:delete`, true);
                this.$emit('delete');
            }
            window.URL.revokeObjectURL(this.previewImage);
            this.previewImage = null;
            this.$emit(`update:modelValue`, null);
            this.$refs[this.refKey].value = null;
        },
    },
};
</script>

<style scoped lang="scss">
.upload-image {
    box-sizing: border-box;
    background: white;
}

.input {
    visibility: hidden;
}

.input-w-0 {
    width: 0;
}

.preview {
    height: auto;
    width: 100%;
    display: flex;

    .item {
        max-width: 100%;
        position: relative;

        span {
            position: absolute;
            right: -5px;
            top: -5px;
            cursor: pointer;
            background-color: #fff;
            border-radius: 50%;
        }

        img {
            overflow: hidden;
            max-width: 90px;
        }
    }
}
</style>
