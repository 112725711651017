<template>
  <div>
    <ModalShow :show="!editShow" :data="dataShow" v-on:edit="edit" v-on:closeModal="$emit('close')"/>
    <ModalEdit :form="form" :copy=copy :show="editShow" v-on:close="editShow=false" v-on:save="save" v-on:closeModal="editShow=false"/>
  </div>
</template>

<script>
import ModalEdit from "@/components/modals/modals-users/ModalEdit";
import ModalShow from "@/components/modals/modals-users/ModalShow";
import {usersMixin} from "@/mixins/form/users-mixin";
import {errorResponse, formPreparation} from "@/mixins/form/form-mixin";
export default {
  name: "ModalJnt",
  components:{
    ModalShow,
    ModalEdit
  },
  mixins:[
    usersMixin,
    errorResponse,
    formPreparation
  ],
  props:{
    userId:{
      required:true,
    }
  },

  data(){
    return{
      dataShow:{},
      dataEdit:{},
      editShow:false,
      copy:{}
    }
  },
  async mounted(){
    await this.fetchShow();


  },
  methods:{
    async fetchShow(){
      const {data} = await this.axios.get(`users/${this.userId}`);
      this.dataShow = data.data;
    },
    async save() {
      try {
        this.form.permissions = this.permissions_active;

        this.form.roles = this.roles_active;
        const form = this.formPreparation();

        const {data} = await this.axios.post('/users/' + this.userId, form, {
          params: {
            _method: 'patch',
          },
        });
        this.$notify("Пользователь сохранен");
        await this.fetchShow();
        this.editShow = false;
        this.$emit('update',data.data);
      } catch (ex) {
        this.errorResponse(ex);
      }
    },
    async edit(){
      await this.fetchUser(this.userId);
      this.form.avatarPath = this.form.avatar;
      this.copy = JSON.parse(JSON.stringify(this.form));
      this.copy.password = this.dataShow.password;
      this.form.delete_avatar = false;
      this.copy.delete_avatar = false;
      this.editShow = true;
    }
  }
}
</script>

<style scoped>

</style>
