<template>
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
        <path
            fill="currentColor"
            d="M5 11q-.825 0-1.413-.588T3 9V5q0-.825.588-1.413T5 3h4q.825 0 1.413.588T11 5v4q0 .825-.588 1.413T9 11H5Zm0-2h4V5H5v4Zm0 12q-.825 0-1.413-.588T3 19v-4q0-.825.588-1.413T5 13h4q.825 0 1.413.588T11 15v4q0 .825-.588 1.413T9 21H5Zm0-2h4v-4H5v4Zm10-8q-.825 0-1.413-.588T13 9V5q0-.825.588-1.413T15 3h4q.825 0 1.413.588T21 5v4q0 .825-.588 1.413T19 11h-4Zm0-2h4V5h-4v4Zm4.5 12q-.2 0-.35-.15T19 20.5v-1q0-.2.15-.35t.35-.15h1q.2 0 .35.15t.15.35v1q0 .2-.15.35t-.35.15h-1Zm-6-6q-.2 0-.35-.15T13 14.5v-1q0-.2.15-.35t.35-.15h1q.2 0 .35.15t.15.35v1q0 .2-.15.35t-.35.15h-1Zm2 2q-.2 0-.35-.15T15 16.5v-1q0-.2.15-.35t.35-.15h1q.2 0 .35.15t.15.35v1q0 .2-.15.35t-.35.15h-1Zm-2 2q-.2 0-.35-.15T13 18.5v-1q0-.2.15-.35t.35-.15h1q.2 0 .35.15t.15.35v1q0 .2-.15.35t-.35.15h-1Zm2 2q-.2 0-.35-.15T15 20.5v-1q0-.2.15-.35t.35-.15h1q.2 0 .35.15t.15.35v1q0 .2-.15.35t-.35.15h-1Zm2-2q-.2 0-.35-.15T17 18.5v-1q0-.2.15-.35t.35-.15h1q.2 0 .35.15t.15.35v1q0 .2-.15.35t-.35.15h-1Zm0-4q-.2 0-.35-.15T17 14.5v-1q0-.2.15-.35t.35-.15h1q.2 0 .35.15t.15.35v1q0 .2-.15.35t-.35.15h-1Zm2 2q-.2 0-.35-.15T19 16.5v-1q0-.2.15-.35t.35-.15h1q.2 0 .35.15t.15.35v1q0 .2-.15.35t-.35.15h-1Z"
        />
    </svg>
</template>

<script>
export default {
    name: 'QrCodeIcon',
};
</script>
