export default [
    {
        path: '/reestrs',
        name: 'reestrs',
        component: () => import(/* webpackChunkName: "roszdravnadzor" */ '@/views/reestrs/ReestrView.vue'),
        meta: {
            auth: ['Super-Admin', 'Admin', 'Manager'],
        },
        children: [
            {
                path: 'medreestr',
                name: 'medreestr',
                component: () =>
                    import(/* webpackChunkName: "roszdravnadzor" */ '@/views/reestrs/pages/MedreestrPageComponent.vue'),
            },
        ],
    },
];
