import axios from 'axios';

const state = () => {
    return {
        showMedia: false,
        options: {
            mediaId: null,
            src: null,
        },
    };
};

// getters
const getters = {};

// mutations
const mutations = {
    setShowModal(state, value) {
        state.showMedia = Boolean(value);
    },
    setOptions(state, options) {
        state.options = options;
    },
};

// actions
const actions = {};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
};
