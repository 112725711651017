<template>
  <g id="surface1">
    <path d="M 4.039062 22.070312 C 3.761719 22.070312 3.492188 21.984375 3.265625 21.828125 C 2.796875 21.511719 2.574219 20.949219 2.699219 20.398438 L 3.464844 16.945312 C 3.515625 16.710938 3.429688 16.46875 3.242188 16.324219 C 1.207031 14.738281 0.0859375 12.671875 0.0859375 10.503906 C 0.0859375 5.777344 5.429688 1.929688 12 1.929688 C 18.570312 1.929688 23.914062 5.777344 23.914062 10.503906 C 23.914062 15.226562 18.570312 19.074219 12 19.074219 C 11.207031 19.074219 10.410156 19.015625 9.636719 18.902344 C 9.386719 18.867188 9.132812 18.925781 8.925781 19.066406 L 4.804688 21.835938 C 4.578125 21.988281 4.3125 22.070312 4.039062 22.070312 Z M 12 3.421875 C 6.253906 3.421875 1.578125 6.597656 1.578125 10.503906 C 1.578125 12.203125 2.496094 13.851562 4.15625 15.144531 C 4.796875 15.644531 5.097656 16.476562 4.921875 17.265625 L 4.21875 20.429688 L 8.09375 17.828125 C 8.609375 17.480469 9.234375 17.335938 9.847656 17.425781 C 10.554688 17.527344 11.277344 17.582031 12 17.582031 C 17.746094 17.582031 22.421875 14.40625 22.421875 10.503906 C 22.421875 6.597656 17.746094 3.421875 12 3.421875 Z M 12 3.421875 "/>
  </g>
</template>

<script>
export default {
  name: "CommentIcon"
}
</script>

<style scoped>

</style>