<template>
    <div v-if="user">
        <div class="flex items-center">
            <img
                v-if="user.avatar"
                :src="user.avatar"
                class="avatar mr-1"
            />
            <p class="mb-0">
                <a @click.prevent.stop="handleShowModalUser(user)">
                    {{ user.name }}
                </a>
            </p>
        </div>
    </div>
</template>

<script>
export default {
    name: "UserNotificationDealUser",
    props: {
        user: {
            required: true,
        },
    },
    methods: {
        handleShowModalUser(user) {
            this.$emit('show-modal-user', user);
        },
    },
}
</script>

<style scoped lang="scss">
p {
    @apply text-left;
}
</style>
