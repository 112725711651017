import axios from 'axios';
import { notify } from '@kyvg/vue3-notification';
import router from '@/router';

axios.defaults.baseURL = process.env.VUE_APP_API_URL;
axios.defaults.withCredentials = true;

axios.interceptors.request.use(
    (config) => {
        let token = localStorage.getItem('access_token');
        if (token) config.headers['Authorization'] = `Bearer ${token}`;
        return config;
    },
    (error) => {
        return Promise.reject(error);
    }
);
axios.interceptors.response.use(
    (response) => response,
    async function(error) {
        if (error) {
            const resp = error.response;
            const path = resp.config.url;
            if (path.includes('inbox') && resp.status == 422) {
                if (resp.data && resp.data.errors) {
                    const key_errors = Object.keys(resp.data.errors)[0];

                    const errorText = resp.data.errors[key_errors][0];
                    console.log(errorText);
                    notify({
                        title: 'Ошибка',
                        text: errorText,
                        type: 'error',
                    });
                }
                await router.push({ name: 'auth-inbox' });
            }
            if (path.includes('inbox') && resp.s)
                if (error.response.status === 401 || error.response.status === 403) {
                    localStorage.removeItem('access_token');
                    await router.push('/login');
                }
            if (error.response.status === 404) {
                await router.push('NotFoundPage');
            }
        }
        console.log('inside inteceptor', error);
        throw error;
    }
);

export default axios;
