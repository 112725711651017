<template>
  <g id="surface1">
    <path d="M 1.269531 0.0117188 C 1.246094 0.015625 1.179688 0.03125 1.121094 0.0429688 C 0.609375 0.144531 0.175781 0.578125 0.0390625 1.121094 C -0.00390625 1.285156 -0.00390625 22.714844 0.0390625 22.878906 C 0.171875 23.40625 0.539062 23.789062 1.078125 23.953125 C 1.183594 23.988281 1.613281 23.988281 7.96875 23.992188 C 12.011719 23.996094 14.738281 23.992188 14.730469 23.980469 C 14.71875 23.972656 14.5 23.808594 14.242188 23.621094 L 13.777344 23.28125 L 7.683594 23.28125 C 2.160156 23.28125 1.578125 23.277344 1.476562 23.246094 C 1.222656 23.175781 1.03125 23.023438 0.890625 22.792969 C 0.746094 22.550781 0.761719 23.542969 0.761719 12.015625 C 0.761719 4.640625 0.765625 1.59375 0.78125 1.507812 C 0.847656 1.152344 1.117188 0.859375 1.476562 0.753906 C 1.578125 0.722656 2.328125 0.71875 10.078125 0.71875 C 17.644531 0.71875 18.585938 0.722656 18.691406 0.75 C 18.960938 0.820312 19.183594 1.007812 19.3125 1.269531 L 19.390625 1.429688 L 19.398438 11.554688 L 19.527344 11.578125 C 19.59375 11.59375 19.757812 11.636719 19.890625 11.683594 C 20.023438 11.722656 20.140625 11.761719 20.148438 11.761719 C 20.152344 11.761719 20.15625 9.382812 20.152344 6.472656 C 20.148438 1.539062 20.144531 1.183594 20.113281 1.078125 C 19.953125 0.554688 19.605469 0.207031 19.082031 0.046875 C 18.976562 0.0117188 18.449219 0.0117188 10.140625 0.0078125 C 5.285156 0.0078125 1.292969 0.0078125 1.269531 0.0117188 Z M 1.269531 0.0117188 "/>
    <path d="M 3.839844 5.398438 L 3.839844 6.960938 L 6.960938 6.960938 L 6.960938 3.839844 L 3.839844 3.839844 Z M 6.480469 5.398438 L 6.480469 6.480469 L 4.320312 6.480469 L 4.320312 4.320312 L 6.480469 4.320312 Z M 6.480469 5.398438 "/>
    <path d="M 8.398438 5.28125 L 8.398438 5.519531 L 15.601562 5.519531 L 15.601562 5.039062 L 8.398438 5.039062 Z M 8.398438 5.28125 "/>
    <path d="M 3.839844 9.71875 L 3.839844 11.28125 L 6.960938 11.28125 L 6.960938 8.160156 L 3.839844 8.160156 Z M 6.480469 9.71875 L 6.480469 10.800781 L 4.320312 10.800781 L 4.320312 8.640625 L 6.480469 8.640625 Z M 6.480469 9.71875 "/>
    <path d="M 8.398438 9.601562 L 8.398438 9.839844 L 15.601562 9.839844 L 15.601562 9.359375 L 8.398438 9.359375 Z M 8.398438 9.601562 "/>
    <path d="M 17.640625 12.480469 C 15.90625 12.671875 14.371094 13.601562 13.414062 15.039062 C 13.222656 15.335938 12.921875 15.917969 12.808594 16.226562 C 12.441406 17.234375 12.363281 18.351562 12.585938 19.429688 C 12.757812 20.273438 13.179688 21.160156 13.746094 21.859375 C 14.574219 22.882812 15.726562 23.589844 17.023438 23.867188 C 17.460938 23.960938 17.746094 23.988281 18.359375 23.988281 C 18.921875 23.988281 18.933594 23.988281 19.160156 23.933594 C 19.285156 23.902344 19.511719 23.847656 19.660156 23.808594 C 19.808594 23.773438 20.046875 23.703125 20.191406 23.648438 C 22.800781 22.707031 24.363281 20.035156 23.917969 17.292969 C 23.679688 15.828125 22.886719 14.519531 21.699219 13.621094 C 20.910156 13.027344 20.0625 12.675781 19.019531 12.511719 C 18.773438 12.46875 17.902344 12.453125 17.640625 12.480469 Z M 20.9375 15.628906 C 21.269531 15.886719 21.539062 16.105469 21.539062 16.113281 C 21.539062 16.117188 21.363281 16.382812 21.144531 16.707031 C 20.925781 17.027344 20.167969 18.144531 19.460938 19.191406 C 18.753906 20.234375 18.109375 21.183594 18.035156 21.292969 L 17.894531 21.496094 L 17.753906 21.386719 C 16.277344 20.234375 14.78125 19.050781 14.78125 19.035156 C 14.78125 18.996094 15.671875 17.699219 15.691406 17.707031 C 15.707031 17.714844 16.820312 18.582031 17.53125 19.140625 C 17.546875 19.152344 18.003906 18.5 18.921875 17.140625 C 19.746094 15.925781 20.296875 15.128906 20.308594 15.136719 C 20.320312 15.148438 20.601562 15.367188 20.9375 15.628906 Z M 20.9375 15.628906 "/>
    <path d="M 3.839844 14.039062 L 3.839844 15.601562 L 6.960938 15.601562 L 6.960938 12.480469 L 3.839844 12.480469 Z M 6.480469 14.039062 L 6.480469 15.121094 L 4.320312 15.121094 L 4.320312 12.960938 L 6.480469 12.960938 Z M 6.480469 14.039062 "/>
    <path d="M 8.398438 13.921875 L 8.398438 14.160156 L 12.851562 14.160156 L 13.003906 13.976562 C 13.085938 13.875 13.171875 13.769531 13.195312 13.734375 L 13.242188 13.679688 L 8.398438 13.679688 Z M 8.398438 13.921875 "/>
    <path d="M 3.839844 18.359375 L 3.839844 19.921875 L 6.960938 19.921875 L 6.960938 16.800781 L 3.839844 16.800781 Z M 6.480469 18.359375 L 6.480469 19.441406 L 4.320312 19.441406 L 4.320312 17.28125 L 6.480469 17.28125 Z M 6.480469 18.359375 "/>
    <path d="M 8.398438 18.238281 L 8.398438 18.480469 L 11.488281 18.480469 L 11.488281 18 L 8.398438 18 Z M 8.398438 18.238281 "/>
  </g>
</template>

<script>
export default {
  name: "TaskIcon"
}
</script>

<style scoped>

</style>
