<template>
  <g id="ic_fluent_text_description_24_regular">
    <path d="M2.75,17 L15.25,17 C15.6642136,17 16,17.3357864 16,17.75 C16,18.1296958 15.7178461,18.443491 15.3517706,18.4931534 L15.25,18.5 L2.75,18.5 C2.33578644,18.5 2,18.1642136 2,17.75 C2,17.3703042 2.28215388,17.056509 2.64822944,17.0068466 L2.75,17 L15.25,17 L2.75,17 Z M2.75,13 L21.25,13 C21.6642136,13 22,13.3357864 22,13.75 C22,14.1296958 21.7178461,14.443491 21.3517706,14.4931534 L21.25,14.5 L2.75,14.5 C2.33578644,14.5 2,14.1642136 2,13.75 C2,13.3703042 2.28215388,13.056509 2.64822944,13.0068466 L2.75,13 L21.25,13 L2.75,13 Z M2.75,9 L21.25,9 C21.6642136,9 22,9.33578644 22,9.75 C22,10.1296958 21.7178461,10.443491 21.3517706,10.4931534 L21.25,10.5 L2.75,10.5 C2.33578644,10.5 2,10.1642136 2,9.75 C2,9.37030423 2.28215388,9.05650904 2.64822944,9.00684662 L2.75,9 L21.25,9 L2.75,9 Z M2.75,5 L21.25,5 C21.6642136,5 22,5.33578644 22,5.75 C22,6.12969577 21.7178461,6.44349096 21.3517706,6.49315338 L21.25,6.5 L2.75,6.5 C2.33578644,6.5 2,6.16421356 2,5.75 C2,5.37030423 2.28215388,5.05650904 2.64822944,5.00684662 L2.75,5 L21.25,5 L2.75,5 Z" id="🎨-Color"></path>
  </g>
</template>

<script>
export default {
  name: "TaskDescriptionIcon"
}
</script>

<style scoped>

</style>