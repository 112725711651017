<template>
    <div class="overlay" @click.self="$emit('closeModal')" v-if="modalOpen">
        <div
            class="modal__window shadow-xl"
            :style="{ width: width, height: height, 'max-height': maxHeight, minHeight: minHeight }"
        >
            <div class="modal__header flex justify-between">
                <slot name="header"></slot>
                <div class="ml-auto">
                    <a class="modal__close mb-1" @click="$emit('closeModal')"><XCircleIcon /></a>
                    <slot name="actions"></slot>
                </div>
            </div>
            <div class="modal__body scroll-custom flex-1 overflow-x-hidden" :class="{ 'overflow-x-auto': scroll }">
                <slot name="body"></slot>
            </div>
            <div class="modal__footer">
                <slot name="footer"></slot>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'ModalContainer',
    emits: ['closeModal'],
    props: {
        modalOpen: {
            type: Boolean,
            required: true,
        },
        scroll: {
            type: Boolean,
            default: true,
        },
        width: {
            default: '840px',
        },
        height: {
            default: '580px',
        },
        maxHeight: {
            default: 'auto',
        },
        minHeight: {
            default: 'auto',
        },
    },
};
</script>

<style scoped lang="scss">
.overlay {
    position: fixed;
    background: rgba(#fff, 0.4);
    width: 100%;
    height: 100%;
    z-index: 1000;
    left: 0;
    top: 0;
    display: flex;
    align-items: center;
    justify-content: center;
}
.modal {
    &__window {
        background: white;
        border-radius: 6px;
        padding: 1.5rem 1rem;
        display: flex;
        flex-direction: column;
        //justify-content: space-between;
        position: relative;
    }
    &__close {
        //position: absolute;
        //right: 15px;
        //top: 15px;
        transition: 0.2s;
        cursor: pointer;
        border-radius: 50%;
        background: #fff;
        &:hover {
            color: #fff;
            background: #4d4d4d;
        }
    }
    &__body {
        padding: 0 3px;
    }
    &__header {
        font-size: 20px;
        font-weight: 900;
        margin-bottom: 15px;
    }
    &__footer {
        display: flex;
        justify-content: flex-end;
    }
}
</style>
