<template>
    <ModalContainer width="540px" height="auto" :modal-open="show" v-on:closeModal="$emit('close', false)">
        <template v-slot:header>Предупреждение</template>
        <template v-slot:body>
            <div>
                <div class="bg-gray-300 p-3 mb-3 text-center">
                    <p class="font-medium text-lg">
                        Вы не сохранили изменения.<br />
                        Уверенны, что хотите закрыть ?
                    </p>
                </div>
            </div>
        </template>
        <template v-slot:footer>
            <div class="actions">
                <button type="button" class="btn btn-sm btn-primary w-24 mr-1" @click="$emit('close', true)">
                    Закрыть
                </button>
                <button type="button" class="btn btn-sm btn-secondary w-24" @click="$emit('close', false)">
                    Отменить
                </button>
            </div>
        </template>
    </ModalContainer>
</template>

<script>
import ModalContainer from '@/components/modals/ModalContainer';
export default {
    name: 'saveChanges',
    components: {
        ModalContainer,
    },
    props: {
        show: {
            required: true,
        },
    },
    emits: ['close'],
};
</script>

<style scoped>
.changes {
    max-width: 650px;
}
</style>
