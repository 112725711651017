import * as types from './mutation-types';

const state = () => {
  return {
    darkMode: false,
    breadcrumbs: null,
  };
};

// getters
const getters = {
  darkMode: state => state.darkMode,
  getBreadcrumbs: (state) => state.breadcrumbs,
};

// actions
const actions = {
  setDarkMode({ commit }, darkMode) {
    commit(types.SET_DARK_MODE, { darkMode });
  },
  dispatchBreadcrumbs({ commit }, payload) {
    commit('setBreadcrumbs', payload);
  },
};

// mutations
const mutations = {
  [types.SET_DARK_MODE](state, { darkMode }) {
    state.darkMode = darkMode;
  },
  setBreadcrumbs(state, payload) {
    state.breadcrumbs = payload;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
