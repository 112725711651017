import { serialize } from '@/utils/object-to-form-data';

export const errorResponse = {
  methods: {
    errorResponse(ex) {
      this.loading = false;
      console.log('cant send request: ' + ex);
      let errorText = '';
      let key_errors = '';
      if (ex.response.status === 400) {
        for (const key in ex.response.data.errors) {
          this.form[key] = null;
        }
        errorText = 'Введенные данные неверные';
      } else if (ex.response.data && ex.response.data.errors) {
        key_errors = Object.keys(ex.response.data.errors)[0];

        errorText = ex.response.data.errors[key_errors][0];
      }else if(ex.response.status === 422){
        errorText = 'Неверные данные авторизации';
      }
      else {
        errorText = ex.response.data && !!ex.response.data.message ? JSON.stringify(ex.response.data.message) : '';
      }

      this.$notify({
        title: 'Ошибка',
        text: errorText,
        type: 'error',
      });
    },
  },
};

export const formPreparation = {
  methods: {
    formPreparation(formSerialize=null) {
      const form = new FormData;
      const options = {
        /**
         * добавляет индексы массивам
         * defaults to false
         */
        indices: true,

        /**
         * treat null values like undefined values and ignore them
         * defaults to false
         */
        nullsAsUndefineds: false,

        /**
         * convert true or false to 1 or 0 respectively
         * defaults to false
         */
        booleansAsIntegers: true,

        /**
         * store arrays even if they're empty
         * defaults to false
         */
        allowEmptyArrays: false,
      };

      if(!formSerialize){
        formSerialize = this.form;
      }
      const formDataFormatted = serialize(
        formSerialize,
        options,
        form,
      );

      return formDataFormatted;
    },
  },
};
