const state = () => {
  return {
    shipTitle: null,
  }
}

// getters
const getters = {
  shipTitle(state) {
    return state.shipTitle
  }
}

// actions
const actions = {

}

// mutations
const mutations = {
  shipTitle(state, payload) {
    state.shipTitle = payload
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
