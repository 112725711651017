<template>
  <form
    method="post"
    class="grid grid-cols-12 gap-6"
    :class="{ 'disabled-form': loading }"
    @submit.prevent="save"
  >
    <div
      v-if="loading"
      class="absolute z-100 left-0 top-0 w-full h-full flex items-center justify-center"
    >
      <Preloader/>
    </div>

    <div class="col-span-6 pos-rel">
      <label for="form-name" class="form-label">Ф.И.О</label>
      <input
        id="form-name"
        v-model='form.name'
        type="text"
        class="form-control w-full"
        placeholder="Введите Ф.И.О"
        autocomplete="off"
        required
      >
    </div>

    <div class="col-span-6 pos-rel">
      <label for="form-email" class="form-label">Логин</label>
      <input
        id="form-email"
        v-model='form.email'
        type="email"
        class="form-control w-full"
        placeholder="Введите email"
        autocomplete="off"
        required
      >
    </div>

    <div class="col-span-6 relative">
      <label for="form-email" class="form-label">Должность</label>
      <input
        id="form-position"
        type="text"
        class="form-control w-full"
        placeholder="Введите должность"
        required
        autocomplete="off"
        v-model='form.position'
      >
    </div>
    <div class="col-span-6 relative">
      <label for="form-email" class="form-label">Телефон</label>
      <input
        id="form-position"
        type="text"
        class="form-control w-full"
        placeholder="Введите телефон"
        required
        autocomplete="off"
        v-model='form.phone'
      >
    </div>
    <div class="col-span-6 relative">
      <label for="form-email" class="form-label">Добавочный телефон</label>
      <input
        id="form-position"
        type="text"
        class="form-control w-full"
        placeholder="Введите телефон"
        required
        autocomplete="off"
        v-model='form.additional_phone'
      >
    </div>

    <div class="col-span-6 pos-rel" style="position: relative">
      <label for="form-password" class="form-label">Пароль</label>
      <input
        id="form-password"
        v-model='form.password'
        type="password"
        class="form-control w-full"
        :disabled="!changePassword"
        placeholder="Введите пароль"
      >
      <div v-if="!changePassword" class="change__password">
        <a href="#" class="btn btn-secondary" @click.prevent="changePassword = !changePassword">
          Сменить пароль
        </a>
      </div>
    </div>
    <div class="col-span-12 pos-rel">
      <label class="form-label">Аватар</label>
      <single-dropzone
        v-model="form.avatar"
        :image="form.avatarPath"
        @uploadFile="form.delete_avatar=false"
        @delete="deleteAvatar"
      >
      </single-dropzone>
    </div>
    <slot name="body"></slot>
    <div class="col-span-12 flex items-center justify-end mt-5">
      <a @click.prevent="close" class="btn btn-secondary w-24 mb-2 mr-2">
        Отмена
      </a>
      <button @click.prevent="save" type="button" class="btn btn-primary mb-2">Сохранить</button>
    </div>
  </form>
  <modal-save-changes :show="showChanges" v-on:close="closeChange"/>
</template>

<script>
import Preloader from '@/components/preloader/Main';
import SingleDropzone from '@/components/dropzone/SingleDropzone.vue'
import {errorResponse, formPreparation} from "@/mixins/form/form-mixin";
import ModalSaveChanges from "@/components/modals/ModalSaveChanges";
import isEqual from 'lodash.isequal';
export default {
  name: "formUser",
  components:{
    Preloader,
    SingleDropzone,
    ModalSaveChanges
  },
  mixins: [errorResponse,formPreparation],

  props:{
    form:{
      required:true,
    },
    copy:{
      required:true,
    }
  },
  data(){
    return{
      changePassword:false,
      loading:false,
      showChanges:false,

    }
  },
  emits:[
    'close',
    'save',
  ],

  methods:{

   close(){
      if(isEqual(this.form,this.copy)) {
        this.$emit('close');
      }else{
        this.showChanges = true;
      }

    },
    deleteAvatar(){
     this.form.avatarPath = null;
     this.form.delete_avatar = true;
    },
    closeChange(flag){
      if(flag){
        this.$emit('close');
      }else{
        this.showChanges = false;
      }
    },
    save(){
      this.loading = true;
      this.$emit('save');
      this.loading = false;
    }
  }
}
</script>

<style scoped>
.change__password {
  position: absolute;
  top: 42%;
  right: 0;
}
form{
  padding: 3px;
}

</style>
