<template>
  <svg xmlns="http://www.w3.org/2000/svg" class="w-5 h-5" viewBox="0 0 24 24">
    <path fill="currentColor" d="M3 21q-.825 0-1.412-.587Q1 19.825 1 19V6h2v13h17v2Zm4-4q-.825 0-1.412-.587Q5 15.825 5 15V4q0-.825.588-1.413Q6.175 2 7 2h5l2 2h7q.825 0 1.413.588Q23 5.175 23 6v9q0 .825-.587 1.413Q21.825 17 21 17Zm0-2h14V6h-7.825l-2-2H7v11Zm0 0V4v11Z"/>
  </svg>
</template>

<script>
export default {
  name: "MoveFolderIcon"
}
</script>

<style scoped>

</style>
