export default {
  "Эмоции": [
    {
      "emoji": "😀",
      "name": "grinning face",
    },
    {
      "emoji": "😃",
      "name": "grinning face with big eyes",
    },
    {
      "emoji": "😄",
      "name": "grinning face with smiling eyes",
    },
    {
      "emoji": "😁",
      "name": "beaming face with smiling eyes",
    },
    {
      "emoji": "😆",
      "name": "grinning squinting face",
    },
    {
      "emoji": "😅",
      "name": "grinning face with sweat",
    },
    {
      "emoji": "🤣",
      "name": "rolling on the floor laughing",
    },
    {
      "emoji": "😂",
      "name": "face with tears of joy",
    },
    {
      "emoji": "🙂",
      "name": "slightly smiling face",
    },
    {
      "emoji": "🙃",
      "name": "upside-down face",
    },
    {
      "emoji": "😉",
      "name": "winking face",
    },
    {
      "emoji": "😊",
      "name": "smiling face with smiling eyes",
    },
    {
      "emoji": "😇",
      "name": "smiling face with halo",
    },
    {
      "emoji": "🥰",
      "name": "smiling face with hearts",
    },
    {
      "emoji": "😍",
      "name": "smiling face with heart-eyes",
    },
    {
      "emoji": "🤩",
      "name": "star-struck",
    },
    {
      "emoji": "😘",
      "name": "face blowing a kiss",
    },
    {
      "emoji": "😗",
      "name": "kissing face",
    },
    {
      "emoji": "😚",
      "name": "kissing face with closed eyes",
    },
    {
      "emoji": "😙",
      "name": "kissing face with smiling eyes",
    },
    {
      "emoji": "🥲",
      "name": "smiling face with tear",
    },
    {
      "emoji": "😋",
      "name": "face savoring food",
    },
    {
      "emoji": "😛",
      "name": "face with tongue",
    },
    {
      "emoji": "😜",
      "name": "winking face with tongue",
    },
    {
      "emoji": "🤪",
      "name": "zany face",
    },
    {
      "emoji": "😝",
      "name": "squinting face with tongue",
    },
    {
      "emoji": "🤑",
      "name": "money-mouth face",
    },
    {
      "emoji": "🤗",
      "name": "smiling face with open hands",
    },
    {
      "emoji": "🤭",
      "name": "face with hand over mouth",
    },
    {
      "emoji": "🤫",
      "name": "shushing face",
    },
    {
      "emoji": "🤔",
      "name": "thinking face",
    },
    {
      "emoji": "🤐",
      "name": "zipper-mouth face",
    },
    {
      "emoji": "🤨",
      "name": "face with raised eyebrow",
    },
    {
      "emoji": "😐",
      "name": "neutral face",
    },
    {
      "emoji": "😑",
      "name": "expressionless face",
    },
    {
      "emoji": "😶",
      "name": "face without mouth",
    },
    {
      "emoji": "😶‍🌫️",
      "name": "face in clouds",
    },
    {
      "emoji": "😏",
      "name": "smirking face",
    },
    {
      "emoji": "😒",
      "name": "unamused face",
    },
    {
      "emoji": "🙄",
      "name": "face with rolling eyes",
    },
    {
      "emoji": "😬",
      "name": "grimacing face",
    },
    {
      "emoji": "😮‍💨",
      "name": "face exhaling",
    },
    {
      "emoji": "🤥",
      "name": "lying face",
    },
    {
      "emoji": "😌",
      "name": "relieved face",
    },
    {
      "emoji": "😔",
      "name": "pensive face",
    },
    {
      "emoji": "😪",
      "name": "sleepy face",
    },
    {
      "emoji": "🤤",
      "name": "drooling face",
    },
    {
      "emoji": "😴",
      "name": "sleeping face",
    },
    {
      "emoji": "😷",
      "name": "face with medical mask",
    },
    {
      "emoji": "🤒",
      "name": "face with thermometer",
    },
    {
      "emoji": "🤕",
      "name": "face with head-bandage",
    },
    {
      "emoji": "🤢",
      "name": "nauseated face",
    },
    {
      "emoji": "🤮",
      "name": "face vomiting",
    },
    {
      "emoji": "🤧",
      "name": "sneezing face",
    },
    {
      "emoji": "🥵",
      "name": "hot face",
    },
    {
      "emoji": "🥶",
      "name": "cold face",
    },
    {
      "emoji": "🥴",
      "name": "woozy face",
    },
    {
      "emoji": "😵",
      "name": "face with crossed-out eyes",
    },
    {
      "emoji": "😵‍💫",
      "name": "face with spiral eyes",
    },
    {
      "emoji": "🤯",
      "name": "exploding head",
    },
    {
      "emoji": "🤠",
      "name": "cowboy hat face",
    },
    {
      "emoji": "🥳",
      "name": "partying face",
    },
    {
      "emoji": "🥸",
      "name": "disguised face",
    },
    {
      "emoji": "😎",
      "name": "smiling face with sunglasses",
    },
    {
      "emoji": "🤓",
      "name": "nerd face",
    },
    {
      "emoji": "🧐",
      "name": "face with monocle",
    },
    {
      "emoji": "😕",
      "name": "confused face",
    },
    {
      "emoji": "😟",
      "name": "worried face",
    },
    {
      "emoji": "🙁",
      "name": "slightly frowning face",
    },
    {
      "emoji": "☹️",
      "name": "frowning face",
    },
    {
      "emoji": "😮",
      "name": "face with open mouth",
    },
    {
      "emoji": "😯",
      "name": "hushed face",
    },
    {
      "emoji": "😲",
      "name": "astonished face",
    },
    {
      "emoji": "😳",
      "name": "flushed face",
    },
    {
      "emoji": "🥺",
      "name": "pleading face",
    },
    {
      "emoji": "😦",
      "name": "frowning face with open mouth",
    },
    {
      "emoji": "😧",
      "name": "anguished face",
    },
    {
      "emoji": "😨",
      "name": "fearful face",
    },
    {
      "emoji": "😰",
      "name": "anxious face with sweat",
    },
    {
      "emoji": "😥",
      "name": "sad but relieved face",
    },
    {
      "emoji": "😢",
      "name": "crying face",
    },
    {
      "emoji": "😭",
      "name": "loudly crying face",
    },
    {
      "emoji": "😱",
      "name": "face screaming in fear",
    },
    {
      "emoji": "😖",
      "name": "confounded face",
    },
    {
      "emoji": "😣",
      "name": "persevering face",
    },
    {
      "emoji": "😞",
      "name": "disappointed face",
    },
    {
      "emoji": "😓",
      "name": "downcast face with sweat",
    },
    {
      "emoji": "😩",
      "name": "weary face",
    },
    {
      "emoji": "😫",
      "name": "tired face",
    },
    {
      "emoji": "🥱",
      "name": "yawning face",
    },
    {
      "emoji": "😤",
      "name": "face with steam from nose",
    },
    {
      "emoji": "😡",
      "name": "pouting face",
    },
    {
      "emoji": "😠",
      "name": "angry face",
    },
    {
      "emoji": "🤬",
      "name": "face with symbols on mouth",
    },
    {
      "emoji": "😈",
      "name": "smiling face with horns",
    },
    {
      "emoji": "👿",
      "name": "angry face with horns",
    },
    {
      "emoji": "💀",
      "name": "skull",
    },
    {
      "emoji": "☠️",
      "name": "skull and crossbones",
    },
    {
      "emoji": "💩",
      "name": "pile of poo",
    },
    {
      "emoji": "🤡",
      "name": "clown face",
    },
    {
      "emoji": "👹",
      "name": "ogre",
    },
    {
      "emoji": "👺",
      "name": "goblin",
    },
    {
      "emoji": "👻",
      "name": "ghost",
    },
    {
      "emoji": "👽",
      "name": "alien",
    },
    {
      "emoji": "👾",
      "name": "alien monster",
    },
    {
      "emoji": "🤖",
      "name": "robot",
    },
    {
      "emoji": "😺",
      "name": "grinning cat",
    },
    {
      "emoji": "😸",
      "name": "grinning cat with smiling eyes",
    },
    {
      "emoji": "😹",
      "name": "cat with tears of joy",
    },
    {
      "emoji": "😻",
      "name": "smiling cat with heart-eyes",
    },
    {
      "emoji": "😼",
      "name": "cat with wry smile",
    },
    {
      "emoji": "😽",
      "name": "kissing cat",
    },
    {
      "emoji": "🙀",
      "name": "weary cat",
    },
    {
      "emoji": "😿",
      "name": "crying cat",
    },
    {
      "emoji": "😾",
      "name": "pouting cat",
    },
    {
      "emoji": "🙈",
      "name": "see-no-evil monkey",
    },
    {
      "emoji": "🙉",
      "name": "hear-no-evil monkey",
    },
    {
      "emoji": "🙊",
      "name": "speak-no-evil monkey",
    },
    {
      "emoji": "💋",
      "name": "kiss mark",
    },
    {
      "emoji": "💌",
      "name": "love letter",
    },
    {
      "emoji": "💘",
      "name": "heart with arrow",
    },
    {
      "emoji": "💝",
      "name": "heart with ribbon",
    },
    {
      "emoji": "💖",
      "name": "sparkling heart",
    },
    {
      "emoji": "💗",
      "name": "growing heart",
    },
    {
      "emoji": "💓",
      "name": "beating heart",
    },
    {
      "emoji": "💞",
      "name": "revolving hearts",
    },
    {
      "emoji": "💕",
      "name": "two hearts",
    },
    {
      "emoji": "💟",
      "name": "heart decoration",
    },
    {
      "emoji": "❣️",
      "name": "heart exclamation",
    },
    {
      "emoji": "💔",
      "name": "broken heart",
    },
    {
      "emoji": "❤️‍🔥",
      "name": "heart on fire",
    },
    {
      "emoji": "❤️‍🩹",
      "name": "mending heart",
    },
    {
      "emoji": "❤️",
      "name": "red heart",
    },
    {
      "emoji": "🧡",
      "name": "orange heart",
    },
    {
      "emoji": "💛",
      "name": "yellow heart",
    },
    {
      "emoji": "💚",
      "name": "green heart",
    },
    {
      "emoji": "💙",
      "name": "blue heart",
    },
    {
      "emoji": "💜",
      "name": "purple heart",
    },
    {
      "emoji": "🤎",
      "name": "brown heart",
    },
    {
      "emoji": "🖤",
      "name": "black heart",
    },
    {
      "emoji": "🤍",
      "name": "white heart",
    },
    {
      "emoji": "💯",
      "name": "hundred points",
    },
    {
      "emoji": "💢",
      "name": "anger symbol",
    },
    {
      "emoji": "💥",
      "name": "collision",
    },
    {
      "emoji": "💫",
      "name": "dizzy",
    },
    {
      "emoji": "💦",
      "name": "sweat droplets",
    },
    {
      "emoji": "💨",
      "name": "dashing away",
    },
    {
      "emoji": "🕳️",
      "name": "hole",
    },
    {
      "emoji": "💣",
      "name": "bomb",
    },
    {
      "emoji": "💬",
      "name": "speech balloon",
    },
    {
      "emoji": "👁️‍️",
      "name": "eye in speech bubble",
    },
    {
      "emoji": "🗨️",
      "name": "left speech bubble",
    },
    {
      "emoji": "🗯️",
      "name": "right anger bubble",
    },
    {
      "emoji": "💭",
      "name": "thought balloon",
    },
    {
      "emoji": "💤",
      "name": "zzz",
    }
  ],
  "Люди": [
    {
      "emoji": "👋",
      "skin_tone_support_unicode_version": "1.0",
      "name": "waving hand",
    },
    {
      "emoji": "🤚",
      "skin_tone_support_unicode_version": "3.0",
      "name": "raised back of hand",
    },
    {
      "emoji": "🖐️",
      "skin_tone_support_unicode_version": "1.0",
      "name": "hand with fingers splayed",
    },
    {
      "emoji": "✋",
      "skin_tone_support_unicode_version": "1.0",
      "name": "raised hand",
    },
    {
      "emoji": "🖖",
      "skin_tone_support_unicode_version": "1.0",
      "name": "vulcan salute",
    },
    {
      "emoji": "👌",
      "skin_tone_support_unicode_version": "1.0",
      "name": "OK hand",
    },
    {
      "emoji": "🤌",
      "skin_tone_support_unicode_version": "13.0",
      "name": "pinched fingers",
    },
    {
      "emoji": "🤏",
      "skin_tone_support_unicode_version": "12.0",
      "name": "pinching hand",
    },
    {
      "emoji": "✌️",
      "skin_tone_support_unicode_version": "1.0",
      "name": "victory hand",
    },
    {
      "emoji": "🤞",
      "skin_tone_support_unicode_version": "3.0",
      "name": "crossed fingers",
    },
    {
      "emoji": "🤟",
      "skin_tone_support_unicode_version": "5.0",
      "name": "love-you gesture",
    },
    {
      "emoji": "🤘",
      "skin_tone_support_unicode_version": "1.0",
      "name": "sign of the horns",
    },
    {
      "emoji": "🤙",
      "skin_tone_support_unicode_version": "3.0",
      "name": "call me hand",
    },
    {
      "emoji": "👈",
      "skin_tone_support_unicode_version": "1.0",
      "name": "backhand index pointing left",
    },
    {
      "emoji": "👉",
      "skin_tone_support_unicode_version": "1.0",
      "name": "backhand index pointing right",
    },
    {
      "emoji": "👆",
      "skin_tone_support_unicode_version": "1.0",
      "name": "backhand index pointing up",
    },
    {
      "emoji": "🖕",
      "skin_tone_support_unicode_version": "1.0",
      "name": "middle finger",
    },
    {
      "emoji": "👇",
      "skin_tone_support_unicode_version": "1.0",
      "name": "backhand index pointing down",
    },
    {
      "emoji": "☝️",
      "skin_tone_support_unicode_version": "1.0",
      "name": "index pointing up",
    },
    {
      "emoji": "👍",
      "skin_tone_support_unicode_version": "1.0",
      "name": "thumbs up",
    },
    {
      "emoji": "👎",
      "skin_tone_support_unicode_version": "1.0",
      "name": "thumbs down",
    },
    {
      "emoji": "✊",
      "skin_tone_support_unicode_version": "1.0",
      "name": "raised fist",
    },
    {
      "emoji": "👊",
      "skin_tone_support_unicode_version": "1.0",
      "name": "oncoming fist",
    },
    {
      "emoji": "🤛",
      "skin_tone_support_unicode_version": "3.0",
      "name": "left-facing fist",
    },
    {
      "emoji": "🤜",
      "skin_tone_support_unicode_version": "3.0",
      "name": "right-facing fist",
    },
    {
      "emoji": "👏",
      "skin_tone_support_unicode_version": "1.0",
      "name": "clapping hands",
    },
    {
      "emoji": "🙌",
      "skin_tone_support_unicode_version": "1.0",
      "name": "raising hands",
    },
    {
      "emoji": "👐",
      "skin_tone_support_unicode_version": "1.0",
      "name": "open hands",
    },
    {
      "emoji": "🤲",
      "skin_tone_support_unicode_version": "5.0",
      "name": "palms up together",
    },
    {
      "emoji": "🤝",
      "skin_tone_support_unicode_version": "14.0",
      "name": "handshake",
    },
    {
      "emoji": "🙏",
      "skin_tone_support_unicode_version": "1.0",
      "name": "folded hands",
    },
    {
      "emoji": "✍️",
      "skin_tone_support_unicode_version": "1.0",
      "name": "writing hand",
    },
    {
      "emoji": "💅",
      "skin_tone_support_unicode_version": "1.0",
      "name": "nail polish",
    },
    {
      "emoji": "🤳",
      "skin_tone_support_unicode_version": "3.0",
      "name": "selfie",
    },
    {
      "emoji": "💪",
      "skin_tone_support_unicode_version": "1.0",
      "name": "flexed biceps",
    },
    {
      "emoji": "🦾",
      "name": "mechanical arm",
    },
    {
      "emoji": "🦿",
      "name": "mechanical leg",
    },
    {
      "emoji": "🦵",
      "skin_tone_support_unicode_version": "11.0",
      "name": "leg",
    },
    {
      "emoji": "🦶",
      "skin_tone_support_unicode_version": "11.0",
      "name": "foot",
    },
    {
      "emoji": "👂",
      "skin_tone_support_unicode_version": "1.0",
      "name": "ear",
    },
    {
      "emoji": "🦻",
      "skin_tone_support_unicode_version": "12.0",
      "name": "ear with hearing aid",
    },
    {
      "emoji": "👃",
      "skin_tone_support_unicode_version": "1.0",
      "name": "nose",
    },
    {
      "emoji": "🧠",
      "name": "brain",
    },
    {
      "emoji": "🫀",
      "name": "anatomical heart",
    },
    {
      "emoji": "🫁",
      "name": "lungs",
    },
    {
      "emoji": "🦷",
      "name": "tooth",
    },
    {
      "emoji": "🦴",
      "name": "bone",
    },
    {
      "emoji": "👀",
      "name": "eyes",
    },
    {
      "emoji": "👁️",
      "name": "eye",
    },
    {
      "emoji": "👅",
      "name": "tongue",
    },
    {
      "emoji": "👄",
      "name": "mouth",
    },
    {
      "emoji": "👶",
      "skin_tone_support_unicode_version": "1.0",
      "name": "baby",
    },
    {
      "emoji": "🧒",
      "skin_tone_support_unicode_version": "5.0",
      "name": "child",
    },
    {
      "emoji": "👦",
      "skin_tone_support_unicode_version": "1.0",
      "name": "boy",
    },
    {
      "emoji": "👧",
      "skin_tone_support_unicode_version": "1.0",
      "name": "girl",
    },
    {
      "emoji": "🧑",
      "skin_tone_support_unicode_version": "5.0",
      "name": "person",
    },
    {
      "emoji": "👱",
      "skin_tone_support_unicode_version": "1.0",
      "name": "person blond hair",
    },
    {
      "emoji": "👨",
      "skin_tone_support_unicode_version": "1.0",
      "name": "man",
    },
    {
      "emoji": "🧔",
      "skin_tone_support_unicode_version": "5.0",
      "name": "person beard",
    },
    {
      "emoji": "🧔‍♂️",
      "skin_tone_support_unicode_version": "13.1",
      "name": "man beard",
    },
    {
      "emoji": "🧔‍♀️",
      "skin_tone_support_unicode_version": "13.1",
      "name": "woman beard",
    },
    {
      "emoji": "👨‍🦰",
      "skin_tone_support_unicode_version": "11.0",
      "name": "man red hair",
    },
    {
      "emoji": "👨‍🦱",
      "skin_tone_support_unicode_version": "11.0",
      "name": "man curly hair",
    },
    {
      "emoji": "👨‍🦳",
      "skin_tone_support_unicode_version": "11.0",
      "name": "man white hair",
    },
    {
      "emoji": "👨‍🦲",
      "skin_tone_support_unicode_version": "11.0",
      "name": "man bald",
    },
    {
      "emoji": "👩",
      "skin_tone_support_unicode_version": "1.0",
      "name": "woman",
    },
    {
      "emoji": "👩‍🦰",
      "skin_tone_support_unicode_version": "11.0",
      "name": "woman red hair",
    },
    {
      "emoji": "🧑‍🦰",
      "skin_tone_support_unicode_version": "12.1",
      "name": "person red hair",
    },
    {
      "emoji": "👩‍🦱",
      "skin_tone_support_unicode_version": "11.0",
      "name": "woman curly hair",
    },
    {
      "emoji": "🧑‍🦱",
      "skin_tone_support_unicode_version": "12.1",
      "name": "person curly hair",
    },
    {
      "emoji": "👩‍🦳",
      "skin_tone_support_unicode_version": "11.0",
      "name": "woman white hair",
    },
    {
      "emoji": "🧑‍🦳",
      "skin_tone_support_unicode_version": "12.1",
      "name": "person white hair",
    },
    {
      "emoji": "👩‍🦲",
      "skin_tone_support_unicode_version": "11.0",
      "name": "woman bald",
    },
    {
      "emoji": "🧑‍🦲",
      "skin_tone_support_unicode_version": "12.1",
      "name": "person bald",
    },
    {
      "emoji": "👱‍♀️",
      "skin_tone_support_unicode_version": "4.0",
      "name": "woman blond hair",
    },
    {
      "emoji": "👱‍♂️",
      "skin_tone_support_unicode_version": "4.0",
      "name": "man blond hair",
    },
    {
      "emoji": "🧓",
      "skin_tone_support_unicode_version": "5.0",
      "name": "older person",
    },
    {
      "emoji": "👴",
      "skin_tone_support_unicode_version": "1.0",
      "name": "old man",
    },
    {
      "emoji": "👵",
      "skin_tone_support_unicode_version": "1.0",
      "name": "old woman",
    },
    {
      "emoji": "🙍",
      "skin_tone_support_unicode_version": "1.0",
      "name": "person frowning",
    },
    {
      "emoji": "🙍‍♂️",
      "skin_tone_support_unicode_version": "4.0",
      "name": "man frowning",
    },
    {
      "emoji": "🙍‍♀️",
      "skin_tone_support_unicode_version": "4.0",
      "name": "woman frowning",
    },
    {
      "emoji": "🙎",
      "skin_tone_support_unicode_version": "1.0",
      "name": "person pouting",
    },
    {
      "emoji": "🙎‍♂️",
      "skin_tone_support_unicode_version": "4.0",
      "name": "man pouting",
    },
    {
      "emoji": "🙎‍♀️",
      "skin_tone_support_unicode_version": "4.0",
      "name": "woman pouting",
    },
    {
      "emoji": "🙅",
      "skin_tone_support_unicode_version": "1.0",
      "name": "person gesturing NO",
    },
    {
      "emoji": "🙅‍♂️",
      "skin_tone_support_unicode_version": "4.0",
      "name": "man gesturing NO",
    },
    {
      "emoji": "🙅‍♀️",
      "skin_tone_support_unicode_version": "4.0",
      "name": "woman gesturing NO",
    },
    {
      "emoji": "🙆",
      "skin_tone_support_unicode_version": "1.0",
      "name": "person gesturing OK",
    },
    {
      "emoji": "🙆‍♂️",
      "skin_tone_support_unicode_version": "4.0",
      "name": "man gesturing OK",
    },
    {
      "emoji": "🙆‍♀️",
      "skin_tone_support_unicode_version": "4.0",
      "name": "woman gesturing OK",
    },
    {
      "emoji": "💁",
      "skin_tone_support_unicode_version": "1.0",
      "name": "person tipping hand",
    },
    {
      "emoji": "💁‍♂️",
      "skin_tone_support_unicode_version": "4.0",
      "name": "man tipping hand",
    },
    {
      "emoji": "💁‍♀️",
      "skin_tone_support_unicode_version": "4.0",
      "name": "woman tipping hand",
    },
    {
      "emoji": "🙋",
      "skin_tone_support_unicode_version": "1.0",
      "name": "person raising hand",
    },
    {
      "emoji": "🙋‍♂️",
      "skin_tone_support_unicode_version": "4.0",
      "name": "man raising hand",
    },
    {
      "emoji": "🙋‍♀️",
      "skin_tone_support_unicode_version": "4.0",
      "name": "woman raising hand",
    },
    {
      "emoji": "🧏",
      "skin_tone_support_unicode_version": "12.0",
      "name": "deaf person",
    },
    {
      "emoji": "🧏‍♂️",
      "skin_tone_support_unicode_version": "12.0",
      "name": "deaf man",
    },
    {
      "emoji": "🧏‍♀️",
      "skin_tone_support_unicode_version": "12.0",
      "name": "deaf woman",
    },
    {
      "emoji": "🙇",
      "skin_tone_support_unicode_version": "1.0",
      "name": "person bowing",
    },
    {
      "emoji": "🙇‍♂️",
      "skin_tone_support_unicode_version": "4.0",
      "name": "man bowing",
    },
    {
      "emoji": "🙇‍♀️",
      "skin_tone_support_unicode_version": "4.0",
      "name": "woman bowing",
    },
    {
      "emoji": "🤦",
      "skin_tone_support_unicode_version": "3.0",
      "name": "person facepalming",
    },
    {
      "emoji": "🤦‍♂️",
      "skin_tone_support_unicode_version": "4.0",
      "name": "man facepalming",
    },
    {
      "emoji": "🤦‍♀️",
      "skin_tone_support_unicode_version": "4.0",
      "name": "woman facepalming",
    },
    {
      "emoji": "🤷",
      "skin_tone_support_unicode_version": "3.0",
      "name": "person shrugging",
    },
    {
      "emoji": "🤷‍♂️",
      "skin_tone_support_unicode_version": "4.0",
      "name": "man shrugging",
    },
    {
      "emoji": "🤷‍♀️",
      "skin_tone_support_unicode_version": "4.0",
      "name": "woman shrugging",
    },
    {
      "emoji": "🧑‍⚕️",
      "skin_tone_support_unicode_version": "12.1",
      "name": "health worker",
    },
    {
      "emoji": "👨‍⚕️",
      "skin_tone_support_unicode_version": "4.0",
      "name": "man health worker",
    },
    {
      "emoji": "👩‍⚕️",
      "skin_tone_support_unicode_version": "4.0",
      "name": "woman health worker",
    },
    {
      "emoji": "🧑‍🎓",
      "skin_tone_support_unicode_version": "12.1",
      "name": "student",
    },
    {
      "emoji": "👨‍🎓",
      "skin_tone_support_unicode_version": "4.0",
      "name": "man student",
    },
    {
      "emoji": "👩‍🎓",
      "skin_tone_support_unicode_version": "4.0",
      "name": "woman student",
    },
    {
      "emoji": "🧑‍🏫",
      "skin_tone_support_unicode_version": "12.1",
      "name": "teacher",
    },
    {
      "emoji": "👨‍🏫",
      "skin_tone_support_unicode_version": "4.0",
      "name": "man teacher",
    },
    {
      "emoji": "👩‍🏫",
      "skin_tone_support_unicode_version": "4.0",
      "name": "woman teacher",
    },
    {
      "emoji": "🧑‍⚖️",
      "skin_tone_support_unicode_version": "12.1",
      "name": "judge",
    },
    {
      "emoji": "👨‍⚖️",
      "skin_tone_support_unicode_version": "4.0",
      "name": "man judge",
    },
    {
      "emoji": "👩‍⚖️",
      "skin_tone_support_unicode_version": "4.0",
      "name": "woman judge",
    },
    {
      "emoji": "🧑‍🌾",
      "skin_tone_support_unicode_version": "12.1",
      "name": "farmer",
    },
    {
      "emoji": "👨‍🌾",
      "skin_tone_support_unicode_version": "4.0",
      "name": "man farmer",
    },
    {
      "emoji": "👩‍🌾",
      "skin_tone_support_unicode_version": "4.0",
      "name": "woman farmer",
    },
    {
      "emoji": "🧑‍🍳",
      "skin_tone_support_unicode_version": "12.1",
      "name": "cook",
    },
    {
      "emoji": "👨‍🍳",
      "skin_tone_support_unicode_version": "4.0",
      "name": "man cook",
    },
    {
      "emoji": "👩‍🍳",
      "skin_tone_support_unicode_version": "4.0",
      "name": "woman cook",
    },
    {
      "emoji": "🧑‍🔧",
      "skin_tone_support_unicode_version": "12.1",
      "name": "mechanic",
    },
    {
      "emoji": "👨‍🔧",
      "skin_tone_support_unicode_version": "4.0",
      "name": "man mechanic",
    },
    {
      "emoji": "👩‍🔧",
      "skin_tone_support_unicode_version": "4.0",
      "name": "woman mechanic",
    },
    {
      "emoji": "🧑‍🏭",
      "skin_tone_support_unicode_version": "12.1",
      "name": "factory worker",
    },
    {
      "emoji": "👨‍🏭",
      "skin_tone_support_unicode_version": "4.0",
      "name": "man factory worker",
    },
    {
      "emoji": "👩‍🏭",
      "skin_tone_support_unicode_version": "4.0",
      "name": "woman factory worker",
    },
    {
      "emoji": "🧑‍💼",
      "skin_tone_support_unicode_version": "12.1",
      "name": "office worker",
    },
    {
      "emoji": "👨‍💼",
      "skin_tone_support_unicode_version": "4.0",
      "name": "man office worker",
    },
    {
      "emoji": "👩‍💼",
      "skin_tone_support_unicode_version": "4.0",
      "name": "woman office worker",
    },
    {
      "emoji": "🧑‍🔬",
      "skin_tone_support_unicode_version": "12.1",
      "name": "scientist",
    },
    {
      "emoji": "👨‍🔬",
      "skin_tone_support_unicode_version": "4.0",
      "name": "man scientist",
    },
    {
      "emoji": "👩‍🔬",
      "skin_tone_support_unicode_version": "4.0",
      "name": "woman scientist",
    },
    {
      "emoji": "🧑‍💻",
      "skin_tone_support_unicode_version": "12.1",
      "name": "technologist",
    },
    {
      "emoji": "👨‍💻",
      "skin_tone_support_unicode_version": "4.0",
      "name": "man technologist",
    },
    {
      "emoji": "👩‍💻",
      "skin_tone_support_unicode_version": "4.0",
      "name": "woman technologist",
    },
    {
      "emoji": "🧑‍🎤",
      "skin_tone_support_unicode_version": "12.1",
      "name": "singer",
    },
    {
      "emoji": "👨‍🎤",
      "skin_tone_support_unicode_version": "4.0",
      "name": "man singer",
    },
    {
      "emoji": "👩‍🎤",
      "skin_tone_support_unicode_version": "4.0",
      "name": "woman singer",
    },
    {
      "emoji": "🧑‍🎨",
      "skin_tone_support_unicode_version": "12.1",
      "name": "artist",
    },
    {
      "emoji": "👨‍🎨",
      "skin_tone_support_unicode_version": "4.0",
      "name": "man artist",
    },
    {
      "emoji": "👩‍🎨",
      "skin_tone_support_unicode_version": "4.0",
      "name": "woman artist",
    },
    {
      "emoji": "🧑‍✈️",
      "skin_tone_support_unicode_version": "12.1",
      "name": "pilot",
    },
    {
      "emoji": "👨‍✈️",
      "skin_tone_support_unicode_version": "4.0",
      "name": "man pilot",
    },
    {
      "emoji": "👩‍✈️",
      "skin_tone_support_unicode_version": "4.0",
      "name": "woman pilot",
    },
    {
      "emoji": "🧑‍🚀",
      "skin_tone_support_unicode_version": "12.1",
      "name": "astronaut",
    },
    {
      "emoji": "👨‍🚀",
      "skin_tone_support_unicode_version": "4.0",
      "name": "man astronaut",
    },
    {
      "emoji": "👩‍🚀",
      "skin_tone_support_unicode_version": "4.0",
      "name": "woman astronaut",
    },
    {
      "emoji": "🧑‍🚒",
      "skin_tone_support_unicode_version": "12.1",
      "name": "firefighter",
    },
    {
      "emoji": "👨‍🚒",
      "skin_tone_support_unicode_version": "4.0",
      "name": "man firefighter",
    },
    {
      "emoji": "👩‍🚒",
      "skin_tone_support_unicode_version": "4.0",
      "name": "woman firefighter",
    },
    {
      "emoji": "👮",
      "skin_tone_support_unicode_version": "1.0",
      "name": "police officer",
    },
    {
      "emoji": "👮‍♂️",
      "skin_tone_support_unicode_version": "4.0",
      "name": "man police officer",
    },
    {
      "emoji": "👮‍♀️",
      "skin_tone_support_unicode_version": "4.0",
      "name": "woman police officer",
    },
    {
      "emoji": "🕵️",
      "skin_tone_support_unicode_version": "2.0",
      "name": "detective",
    },
    {
      "emoji": "🕵️‍♂️",
      "skin_tone_support_unicode_version": "4.0",
      "name": "man detective",
    },
    {
      "emoji": "🕵️‍♀️",
      "skin_tone_support_unicode_version": "4.0",
      "name": "woman detective",
    },
    {
      "emoji": "💂",
      "skin_tone_support_unicode_version": "1.0",
      "name": "guard",
    },
    {
      "emoji": "💂‍♂️",
      "skin_tone_support_unicode_version": "4.0",
      "name": "man guard",
    },
    {
      "emoji": "💂‍♀️",
      "skin_tone_support_unicode_version": "4.0",
      "name": "woman guard",
    },
    {
      "emoji": "🥷",
      "skin_tone_support_unicode_version": "13.0",
      "name": "ninja",
    },
    {
      "emoji": "👷",
      "skin_tone_support_unicode_version": "1.0",
      "name": "construction worker",
    },
    {
      "emoji": "👷‍♂️",
      "skin_tone_support_unicode_version": "4.0",
      "name": "man construction worker",
    },
    {
      "emoji": "👷‍♀️",
      "skin_tone_support_unicode_version": "4.0",
      "name": "woman construction worker",
    },
    {
      "emoji": "🤴",
      "skin_tone_support_unicode_version": "3.0",
      "name": "prince",
    },
    {
      "emoji": "👸",
      "skin_tone_support_unicode_version": "1.0",
      "name": "princess",
    },
    {
      "emoji": "👳",
      "skin_tone_support_unicode_version": "1.0",
      "name": "person wearing turban",
    },
    {
      "emoji": "👳‍♂️",
      "skin_tone_support_unicode_version": "4.0",
      "name": "man wearing turban",
    },
    {
      "emoji": "👳‍♀️",
      "skin_tone_support_unicode_version": "4.0",
      "name": "woman wearing turban",
    },
    {
      "emoji": "👲",
      "skin_tone_support_unicode_version": "1.0",
      "name": "person with skullcap",
    },
    {
      "emoji": "🧕",
      "skin_tone_support_unicode_version": "5.0",
      "name": "woman with headscarf",
    },
    {
      "emoji": "🤵",
      "skin_tone_support_unicode_version": "3.0",
      "name": "person in tuxedo",
    },
    {
      "emoji": "🤵‍♂️",
      "skin_tone_support_unicode_version": "13.0",
      "name": "man in tuxedo",
    },
    {
      "emoji": "🤵‍♀️",
      "skin_tone_support_unicode_version": "13.0",
      "name": "woman in tuxedo",
    },
    {
      "emoji": "👰",
      "skin_tone_support_unicode_version": "1.0",
      "name": "person with veil",
    },
    {
      "emoji": "👰‍♂️",
      "skin_tone_support_unicode_version": "13.0",
      "name": "man with veil",
    },
    {
      "emoji": "👰‍♀️",
      "skin_tone_support_unicode_version": "13.0",
      "name": "woman with veil",
    },
    {
      "emoji": "🤰",
      "skin_tone_support_unicode_version": "3.0",
      "name": "pregnant woman",
    },
    {
      "emoji": "🤱",
      "skin_tone_support_unicode_version": "5.0",
      "name": "breast-feeding",
    },
    {
      "emoji": "👩‍🍼",
      "skin_tone_support_unicode_version": "13.0",
      "name": "woman feeding baby",
    },
    {
      "emoji": "👨‍🍼",
      "skin_tone_support_unicode_version": "13.0",
      "name": "man feeding baby",
    },
    {
      "emoji": "🧑‍🍼",
      "skin_tone_support_unicode_version": "13.0",
      "name": "person feeding baby",
    },
    {
      "emoji": "👼",
      "skin_tone_support_unicode_version": "1.0",
      "name": "baby angel",
    },
    {
      "emoji": "🎅",
      "skin_tone_support_unicode_version": "1.0",
      "name": "Santa Claus",
    },
    {
      "emoji": "🤶",
      "skin_tone_support_unicode_version": "3.0",
      "name": "Mrs. Claus",
    },
    {
      "emoji": "🧑‍🎄",
      "skin_tone_support_unicode_version": "13.0",
      "name": "mx claus",
    },
    {
      "emoji": "🦸",
      "skin_tone_support_unicode_version": "11.0",
      "name": "superhero",
    },
    {
      "emoji": "🦸‍♂️",
      "skin_tone_support_unicode_version": "11.0",
      "name": "man superhero",
    },
    {
      "emoji": "🦸‍♀️",
      "skin_tone_support_unicode_version": "11.0",
      "name": "woman superhero",
    },
    {
      "emoji": "🦹",
      "skin_tone_support_unicode_version": "11.0",
      "name": "supervillain",
    },
    {
      "emoji": "🦹‍♂️",
      "skin_tone_support_unicode_version": "11.0",
      "name": "man supervillain",
    },
    {
      "emoji": "🦹‍♀️",
      "skin_tone_support_unicode_version": "11.0",
      "name": "woman supervillain",
    },
    {
      "emoji": "🧙",
      "skin_tone_support_unicode_version": "5.0",
      "name": "mage",
    },
    {
      "emoji": "🧙‍♂️",
      "skin_tone_support_unicode_version": "5.0",
      "name": "man mage",
    },
    {
      "emoji": "🧙‍♀️",
      "skin_tone_support_unicode_version": "5.0",
      "name": "woman mage",
    },
    {
      "emoji": "🧚",
      "skin_tone_support_unicode_version": "5.0",
      "name": "fairy",
    },
    {
      "emoji": "🧚‍♂️",
      "skin_tone_support_unicode_version": "5.0",
      "name": "man fairy",
    },
    {
      "emoji": "🧚‍♀️",
      "skin_tone_support_unicode_version": "5.0",
      "name": "woman fairy",
    },
    {
      "emoji": "🧛",
      "skin_tone_support_unicode_version": "5.0",
      "name": "vampire",
    },
    {
      "emoji": "🧛‍♂️",
      "skin_tone_support_unicode_version": "5.0",
      "name": "man vampire",
    },
    {
      "emoji": "🧛‍♀️",
      "skin_tone_support_unicode_version": "5.0",
      "name": "woman vampire",
    },
    {
      "emoji": "🧜",
      "skin_tone_support_unicode_version": "5.0",
      "name": "merperson",
    },
    {
      "emoji": "🧜‍♂️",
      "skin_tone_support_unicode_version": "5.0",
      "name": "merman",
    },
    {
      "emoji": "🧜‍♀️",
      "skin_tone_support_unicode_version": "5.0",
      "name": "mermaid",
    },
    {
      "emoji": "🧝",
      "skin_tone_support_unicode_version": "5.0",
      "name": "elf",
    },
    {
      "emoji": "🧝‍♂️",
      "skin_tone_support_unicode_version": "5.0",
      "name": "man elf",
    },
    {
      "emoji": "🧝‍♀️",
      "skin_tone_support_unicode_version": "5.0",
      "name": "woman elf",
    },
    {
      "emoji": "🧞",
      "name": "genie",
    },
    {
      "emoji": "🧞‍♂️",
      "name": "man genie",
    },
    {
      "emoji": "🧞‍♀️",
      "name": "woman genie",
    },
    {
      "emoji": "🧟",
      "name": "zombie",
    },
    {
      "emoji": "🧟‍♂️",
      "name": "man zombie",
    },
    {
      "emoji": "🧟‍♀️",
      "name": "woman zombie",
    },
    {
      "emoji": "💆",
      "skin_tone_support_unicode_version": "1.0",
      "name": "person getting massage",
    },
    {
      "emoji": "💆‍♂️",
      "skin_tone_support_unicode_version": "4.0",
      "name": "man getting massage",
    },
    {
      "emoji": "💆‍♀️",
      "skin_tone_support_unicode_version": "4.0",
      "name": "woman getting massage",
    },
    {
      "emoji": "💇",
      "skin_tone_support_unicode_version": "1.0",
      "name": "person getting haircut",
    },
    {
      "emoji": "💇‍♂️",
      "skin_tone_support_unicode_version": "4.0",
      "name": "man getting haircut",
    },
    {
      "emoji": "💇‍♀️",
      "skin_tone_support_unicode_version": "4.0",
      "name": "woman getting haircut",
    },
    {
      "emoji": "🚶",
      "skin_tone_support_unicode_version": "1.0",
      "name": "person walking",
    },
    {
      "emoji": "🚶‍♂️",
      "skin_tone_support_unicode_version": "4.0",
      "name": "man walking",
    },
    {
      "emoji": "🚶‍♀️",
      "skin_tone_support_unicode_version": "4.0",
      "name": "woman walking",
    },
    {
      "emoji": "🧍",
      "skin_tone_support_unicode_version": "12.0",
      "name": "person standing",
    },
    {
      "emoji": "🧍‍♂️",
      "skin_tone_support_unicode_version": "12.0",
      "name": "man standing",
    },
    {
      "emoji": "🧍‍♀️",
      "skin_tone_support_unicode_version": "12.0",
      "name": "woman standing",
    },
    {
      "emoji": "🧎",
      "skin_tone_support_unicode_version": "12.0",
      "name": "person kneeling",
    },
    {
      "emoji": "🧎‍♂️",
      "skin_tone_support_unicode_version": "12.0",
      "name": "man kneeling",
    },
    {
      "emoji": "🧎‍♀️",
      "skin_tone_support_unicode_version": "12.0",
      "name": "woman kneeling",
    },
    {
      "emoji": "🧑‍🦯",
      "skin_tone_support_unicode_version": "12.1",
      "name": "person with white cane",
    },
    {
      "emoji": "👨‍🦯",
      "skin_tone_support_unicode_version": "12.0",
      "name": "man with white cane",
    },
    {
      "emoji": "👩‍🦯",
      "skin_tone_support_unicode_version": "12.0",
      "name": "woman with white cane",
    },
    {
      "emoji": "🧑‍🦼",
      "skin_tone_support_unicode_version": "12.1",
      "name": "person in motorized wheelchair",
    },
    {
      "emoji": "👨‍🦼",
      "skin_tone_support_unicode_version": "12.0",
      "name": "man in motorized wheelchair",
    },
    {
      "emoji": "👩‍🦼",
      "skin_tone_support_unicode_version": "12.0",
      "name": "woman in motorized wheelchair",
    },
    {
      "emoji": "🧑‍🦽",
      "skin_tone_support_unicode_version": "12.1",
      "name": "person in manual wheelchair",
    },
    {
      "emoji": "👨‍🦽",
      "skin_tone_support_unicode_version": "12.0",
      "name": "man in manual wheelchair",
    },
    {
      "emoji": "👩‍🦽",
      "skin_tone_support_unicode_version": "12.0",
      "name": "woman in manual wheelchair",
    },
    {
      "emoji": "🏃",
      "skin_tone_support_unicode_version": "1.0",
      "name": "person running",
    },
    {
      "emoji": "🏃‍♂️",
      "skin_tone_support_unicode_version": "4.0",
      "name": "man running",
    },
    {
      "emoji": "🏃‍♀️",
      "skin_tone_support_unicode_version": "4.0",
      "name": "woman running",
    },
    {
      "emoji": "💃",
      "skin_tone_support_unicode_version": "1.0",
      "name": "woman dancing",
    },
    {
      "emoji": "🕺",
      "skin_tone_support_unicode_version": "3.0",
      "name": "man dancing",
    },
    {
      "emoji": "🕴️",
      "skin_tone_support_unicode_version": "4.0",
      "name": "person in suit levitating",
    },
    {
      "emoji": "👯",
      "name": "people with bunny ears",
    },
    {
      "emoji": "👯‍♂️",
      "name": "men with bunny ears",
    },
    {
      "emoji": "👯‍♀️",
      "name": "women with bunny ears",
    },
    {
      "emoji": "🧖",
      "skin_tone_support_unicode_version": "5.0",
      "name": "person in steamy room",
    },
    {
      "emoji": "🧖‍♂️",
      "skin_tone_support_unicode_version": "5.0",
      "name": "man in steamy room",
    },
    {
      "emoji": "🧖‍♀️",
      "skin_tone_support_unicode_version": "5.0",
      "name": "woman in steamy room",
    },
    {
      "emoji": "🧗",
      "skin_tone_support_unicode_version": "5.0",
      "name": "person climbing",
    },
    {
      "emoji": "🧗‍♂️",
      "skin_tone_support_unicode_version": "5.0",
      "name": "man climbing",
    },
    {
      "emoji": "🧗‍♀️",
      "skin_tone_support_unicode_version": "5.0",
      "name": "woman climbing",
    },
    {
      "emoji": "🤺",
      "name": "person fencing",
    },
    {
      "emoji": "🏇",
      "skin_tone_support_unicode_version": "1.0",
      "name": "horse racing",
    },
    {
      "emoji": "⛷️",
      "name": "skier",
    },
    {
      "emoji": "🏂",
      "skin_tone_support_unicode_version": "1.0",
      "name": "snowboarder",
    },
    {
      "emoji": "🏌️",
      "skin_tone_support_unicode_version": "4.0",
      "name": "person golfing",
    },
    {
      "emoji": "🏌️‍♂️",
      "skin_tone_support_unicode_version": "4.0",
      "name": "man golfing",
    },
    {
      "emoji": "🏌️‍♀️",
      "skin_tone_support_unicode_version": "4.0",
      "name": "woman golfing",
    },
    {
      "emoji": "🏄",
      "skin_tone_support_unicode_version": "1.0",
      "name": "person surfing",
    },
    {
      "emoji": "🏄‍♂️",
      "skin_tone_support_unicode_version": "4.0",
      "name": "man surfing",
    },
    {
      "emoji": "🏄‍♀️",
      "skin_tone_support_unicode_version": "4.0",
      "name": "woman surfing",
    },
    {
      "emoji": "🚣",
      "skin_tone_support_unicode_version": "1.0",
      "name": "person rowing boat",
    },
    {
      "emoji": "🚣‍♂️",
      "skin_tone_support_unicode_version": "4.0",
      "name": "man rowing boat",
    },
    {
      "emoji": "🚣‍♀️",
      "skin_tone_support_unicode_version": "4.0",
      "name": "woman rowing boat",
    },
    {
      "emoji": "🏊",
      "skin_tone_support_unicode_version": "1.0",
      "name": "person swimming",
    },
    {
      "emoji": "🏊‍♂️",
      "skin_tone_support_unicode_version": "4.0",
      "name": "man swimming",
    },
    {
      "emoji": "🏊‍♀️",
      "skin_tone_support_unicode_version": "4.0",
      "name": "woman swimming",
    },
    {
      "emoji": "⛹️",
      "skin_tone_support_unicode_version": "2.0",
      "name": "person bouncing ball",
    },
    {
      "emoji": "⛹️‍♂️",
      "skin_tone_support_unicode_version": "4.0",
      "name": "man bouncing ball",
    },
    {
      "emoji": "⛹️‍♀️",
      "skin_tone_support_unicode_version": "4.0",
      "name": "woman bouncing ball",
    },
    {
      "emoji": "🏋️",
      "skin_tone_support_unicode_version": "2.0",
      "name": "person lifting weights",
    },
    {
      "emoji": "🏋️‍♂️",
      "skin_tone_support_unicode_version": "4.0",
      "name": "man lifting weights",
    },
    {
      "emoji": "🏋️‍♀️",
      "skin_tone_support_unicode_version": "4.0",
      "name": "woman lifting weights",
    },
    {
      "emoji": "🚴",
      "skin_tone_support_unicode_version": "1.0",
      "name": "person biking",
    },
    {
      "emoji": "🚴‍♂️",
      "skin_tone_support_unicode_version": "4.0",
      "name": "man biking",
    },
    {
      "emoji": "🚴‍♀️",
      "skin_tone_support_unicode_version": "4.0",
      "name": "woman biking",
    },
    {
      "emoji": "🚵",
      "skin_tone_support_unicode_version": "1.0",
      "name": "person mountain biking",
    },
    {
      "emoji": "🚵‍♂️",
      "skin_tone_support_unicode_version": "4.0",
      "name": "man mountain biking",
    },
    {
      "emoji": "🚵‍♀️",
      "skin_tone_support_unicode_version": "4.0",
      "name": "woman mountain biking",
    },
    {
      "emoji": "🤸",
      "skin_tone_support_unicode_version": "3.0",
      "name": "person cartwheeling",
    },
    {
      "emoji": "🤸‍♂️",
      "skin_tone_support_unicode_version": "4.0",
      "name": "man cartwheeling",
    },
    {
      "emoji": "🤸‍♀️",
      "skin_tone_support_unicode_version": "4.0",
      "name": "woman cartwheeling",
    },
    {
      "emoji": "🤼",
      "name": "people wrestling",
    },
    {
      "emoji": "🤼‍♂️",
      "name": "men wrestling",
    },
    {
      "emoji": "🤼‍♀️",
      "name": "women wrestling",
    },
    {
      "emoji": "🤽",
      "skin_tone_support_unicode_version": "3.0",
      "name": "person playing water polo",
    },
    {
      "emoji": "🤽‍♂️",
      "skin_tone_support_unicode_version": "4.0",
      "name": "man playing water polo",
    },
    {
      "emoji": "🤽‍♀️",
      "skin_tone_support_unicode_version": "4.0",
      "name": "woman playing water polo",
    },
    {
      "emoji": "🤾",
      "skin_tone_support_unicode_version": "3.0",
      "name": "person playing handball",
    },
    {
      "emoji": "🤾‍♂️",
      "skin_tone_support_unicode_version": "4.0",
      "name": "man playing handball",
    },
    {
      "emoji": "🤾‍♀️",
      "skin_tone_support_unicode_version": "4.0",
      "name": "woman playing handball",
    },
    {
      "emoji": "🤹",
      "skin_tone_support_unicode_version": "3.0",
      "name": "person juggling",
    },
    {
      "emoji": "🤹‍♂️",
      "skin_tone_support_unicode_version": "4.0",
      "name": "man juggling",
    },
    {
      "emoji": "🤹‍♀️",
      "skin_tone_support_unicode_version": "4.0",
      "name": "woman juggling",
    },
    {
      "emoji": "🧘",
      "skin_tone_support_unicode_version": "5.0",
      "name": "person in lotus position",
    },
    {
      "emoji": "🧘‍♂️",
      "skin_tone_support_unicode_version": "5.0",
      "name": "man in lotus position",
    },
    {
      "emoji": "🧘‍♀️",
      "skin_tone_support_unicode_version": "5.0",
      "name": "woman in lotus position",
    },
    {
      "emoji": "🛀",
      "skin_tone_support_unicode_version": "1.0",
      "name": "person taking bath",
    },
    {
      "emoji": "🛌",
      "skin_tone_support_unicode_version": "4.0",
      "name": "person in bed",
    },
    {
      "emoji": "🧑‍🤝‍🧑",
      "skin_tone_support_unicode_version": "12.0",
      "name": "people holding hands",
    },
    {
      "emoji": "👭",
      "skin_tone_support_unicode_version": "12.0",
      "name": "women holding hands",
    },
    {
      "emoji": "👫",
      "skin_tone_support_unicode_version": "12.0",
      "name": "woman and man holding hands",
    },
    {
      "emoji": "👬",
      "skin_tone_support_unicode_version": "12.0",
      "name": "men holding hands",
    },
    {
      "emoji": "💏",
      "skin_tone_support_unicode_version": "13.1",
      "name": "kiss",
    },
    {
      "emoji": "👩‍❤️‍💋‍👨",
      "skin_tone_support_unicode_version": "13.1",
      "name": "kiss woman, man",
    },
    {
      "emoji": "👨‍❤️‍💋‍👨",
      "skin_tone_support_unicode_version": "13.1",
      "name": "kiss man, man",
    },
    {
      "emoji": "👩‍❤️‍💋‍👩",
      "skin_tone_support_unicode_version": "13.1",
      "name": "kiss woman, woman",
    },
    {
      "emoji": "💑",
      "skin_tone_support_unicode_version": "13.1",
      "name": "couple with heart",
    },
    {
      "emoji": "👩‍❤️‍👨",
      "skin_tone_support_unicode_version": "13.1",
      "name": "couple with heart woman, man",
    },
    {
      "emoji": "👨‍❤️‍👨",
      "skin_tone_support_unicode_version": "13.1",
      "name": "couple with heart man, man",
    },
    {
      "emoji": "👩‍❤️‍👩",
      "skin_tone_support_unicode_version": "13.1",
      "name": "couple with heart woman, woman",
    },
    {
      "emoji": "👪",
      "name": "family",
    },
    {
      "emoji": "👨‍👩‍👦",
      "name": "family man, woman, boy",
    },
    {
      "emoji": "👨‍👩‍👧",
      "name": "family man, woman, girl",
    },
    {
      "emoji": "👨‍👩‍👧‍👦",
      "name": "family man, woman, girl, boy",
    },
    {
      "emoji": "👨‍👩‍👦‍👦",
      "name": "family man, woman, boy, boy",
    },
    {
      "emoji": "👨‍👩‍👧‍👧",
      "name": "family man, woman, girl, girl",
    },
    {
      "emoji": "👨‍👨‍👦",
      "name": "family man, man, boy",
    },
    {
      "emoji": "👨‍👨‍👧",
      "name": "family man, man, girl",
    },
    {
      "emoji": "👨‍👨‍👧‍👦",
      "name": "family man, man, girl, boy",
    },
    {
      "emoji": "👨‍👨‍👦‍👦",
      "name": "family man, man, boy, boy",
    },
    {
      "emoji": "👨‍👨‍👧‍👧",
      "name": "family man, man, girl, girl",
    },
    {
      "emoji": "👩‍👩‍👦",
      "name": "family woman, woman, boy",
    },
    {
      "emoji": "👩‍👩‍👧",
      "name": "family woman, woman, girl",
    },
    {
      "emoji": "👩‍👩‍👧‍👦",
      "name": "family woman, woman, girl, boy",
    },
    {
      "emoji": "👩‍👩‍👦‍👦",
      "name": "family woman, woman, boy, boy",
    },
    {
      "emoji": "👩‍👩‍👧‍👧",
      "name": "family woman, woman, girl, girl",
    },
    {
      "emoji": "👨‍👦",
      "name": "family man, boy",
    },
    {
      "emoji": "👨‍👦‍👦",
      "name": "family man, boy, boy",
    },
    {
      "emoji": "👨‍👧",
      "name": "family man, girl",
    },
    {
      "emoji": "👨‍👧‍👦",
      "name": "family man, girl, boy",
    },
    {
      "emoji": "👨‍👧‍👧",
      "name": "family man, girl, girl",
    },
    {
      "emoji": "👩‍👦",
      "name": "family woman, boy",
    },
    {
      "emoji": "👩‍👦‍👦",
      "name": "family woman, boy, boy",
    },
    {
      "emoji": "👩‍👧",
      "name": "family woman, girl",
    },
    {
      "emoji": "👩‍👧‍👦",
      "name": "family woman, girl, boy",
    },
    {
      "emoji": "👩‍👧‍👧",
      "name": "family woman, girl, girl",
    },
    {
      "emoji": "🗣️",
      "name": "speaking head",
    },
    {
      "emoji": "👤",
      "name": "bust in silhouette",
    },
    {
      "emoji": "👥",
      "name": "busts in silhouette",
    },
    {
      "emoji": "🫂",
      "name": "people hugging",
    },
    {
      "emoji": "👣",
      "name": "footprints",
    }
  ],
  "Природа": [
    {
      "emoji": "🐵",
      "name": "monkey face",
    },
    {
      "emoji": "🐒",
      "name": "monkey",
    },
    {
      "emoji": "🦍",
      "name": "gorilla",
    },
    {
      "emoji": "🦧",
      "name": "orangutan",
    },
    {
      "emoji": "🐶",
      "name": "dog face",
    },
    {
      "emoji": "🐕",
      "name": "dog",
    },
    {
      "emoji": "🦮",
      "name": "guide dog",
    },
    {
      "emoji": "🐕‍🦺",
      "name": "service dog",
    },
    {
      "emoji": "🐩",
      "name": "poodle",
    },
    {
      "emoji": "🐺",
      "name": "wolf",
    },
    {
      "emoji": "🦊",
      "name": "fox",
    },
    {
      "emoji": "🦝",
      "name": "raccoon",
    },
    {
      "emoji": "🐱",
      "name": "cat face",
    },
    {
      "emoji": "🐈",
      "name": "cat",
    },
    {
      "emoji": "🐈‍⬛",
      "name": "black cat",
    },
    {
      "emoji": "🦁",
      "name": "lion",
    },
    {
      "emoji": "🐯",
      "name": "tiger face",
    },
    {
      "emoji": "🐅",
      "name": "tiger",
    },
    {
      "emoji": "🐆",
      "name": "leopard",
    },
    {
      "emoji": "🐴",
      "name": "horse face",
    },
    {
      "emoji": "🐎",
      "name": "horse",
    },
    {
      "emoji": "🦄",
      "name": "unicorn",
    },
    {
      "emoji": "🦓",
      "name": "zebra",
    },
    {
      "emoji": "🦌",
      "name": "deer",
    },
    {
      "emoji": "🦬",
      "name": "bison",
    },
    {
      "emoji": "🐮",
      "name": "cow face",
    },
    {
      "emoji": "🐂",
      "name": "ox",
    },
    {
      "emoji": "🐃",
      "name": "water buffalo",
    },
    {
      "emoji": "🐄",
      "name": "cow",
    },
    {
      "emoji": "🐷",
      "name": "pig face",
    },
    {
      "emoji": "🐖",
      "name": "pig",
    },
    {
      "emoji": "🐗",
      "name": "boar",
    },
    {
      "emoji": "🐽",
      "name": "pig nose",
    },
    {
      "emoji": "🐏",
      "name": "ram",
    },
    {
      "emoji": "🐑",
      "name": "ewe",
    },
    {
      "emoji": "🐐",
      "name": "goat",
    },
    {
      "emoji": "🐪",
      "name": "camel",
    },
    {
      "emoji": "🐫",
      "name": "two-hump camel",
    },
    {
      "emoji": "🦙",
      "name": "llama",
    },
    {
      "emoji": "🦒",
      "name": "giraffe",
    },
    {
      "emoji": "🐘",
      "name": "elephant",
    },
    {
      "emoji": "🦣",
      "name": "mammoth",
    },
    {
      "emoji": "🦏",
      "name": "rhinoceros",
    },
    {
      "emoji": "🦛",
      "name": "hippopotamus",
    },
    {
      "emoji": "🐭",
      "name": "mouse face",
    },
    {
      "emoji": "🐁",
      "name": "mouse",
    },
    {
      "emoji": "🐀",
      "name": "rat",
    },
    {
      "emoji": "🐹",
      "name": "hamster",
    },
    {
      "emoji": "🐰",
      "name": "rabbit face",
    },
    {
      "emoji": "🐇",
      "name": "rabbit",
    },
    {
      "emoji": "🐿️",
      "name": "chipmunk",
    },
    {
      "emoji": "🦫",
      "name": "beaver",
    },
    {
      "emoji": "🦔",
      "name": "hedgehog",
    },
    {
      "emoji": "🦇",
      "name": "bat",
    },
    {
      "emoji": "🐻",
      "name": "bear",
    },
    {
      "emoji": "🐻‍❄️",
      "name": "polar bear",
    },
    {
      "emoji": "🐨",
      "name": "koala",
    },
    {
      "emoji": "🐼",
      "name": "panda",
    },
    {
      "emoji": "🦥",
      "name": "sloth",
    },
    {
      "emoji": "🦦",
      "name": "otter",
    },
    {
      "emoji": "🦨",
      "name": "skunk",
    },
    {
      "emoji": "🦘",
      "name": "kangaroo",
    },
    {
      "emoji": "🦡",
      "name": "badger",
    },
    {
      "emoji": "🐾",
      "name": "paw prints",
    },
    {
      "emoji": "🦃",
      "name": "turkey",
    },
    {
      "emoji": "🐔",
      "name": "chicken",
    },
    {
      "emoji": "🐓",
      "name": "rooster",
    },
    {
      "emoji": "🐣",
      "name": "hatching chick",
    },
    {
      "emoji": "🐤",
      "name": "baby chick",
    },
    {
      "emoji": "🐥",
      "name": "front-facing baby chick",
    },
    {
      "emoji": "🐦",
      "name": "bird",
    },
    {
      "emoji": "🐧",
      "name": "penguin",
    },
    {
      "emoji": "🕊️",
      "name": "dove",
    },
    {
      "emoji": "🦅",
      "name": "eagle",
    },
    {
      "emoji": "🦆",
      "name": "duck",
    },
    {
      "emoji": "🦢",
      "name": "swan",
    },
    {
      "emoji": "🦉",
      "name": "owl",
    },
    {
      "emoji": "🦤",
      "name": "dodo",
    },
    {
      "emoji": "🪶",
      "name": "feather",
    },
    {
      "emoji": "🦩",
      "name": "flamingo",
    },
    {
      "emoji": "🦚",
      "name": "peacock",
    },
    {
      "emoji": "🦜",
      "name": "parrot",
    },
    {
      "emoji": "🐸",
      "name": "frog",
    },
    {
      "emoji": "🐊",
      "name": "crocodile",
    },
    {
      "emoji": "🐢",
      "name": "turtle",
    },
    {
      "emoji": "🦎",
      "name": "lizard",
    },
    {
      "emoji": "🐍",
      "name": "snake",
    },
    {
      "emoji": "🐲",
      "name": "dragon face",
    },
    {
      "emoji": "🐉",
      "name": "dragon",
    },
    {
      "emoji": "🦕",
      "name": "sauropod",
    },
    {
      "emoji": "🦖",
      "name": "T-Rex",
    },
    {
      "emoji": "🐳",
      "name": "spouting whale",
    },
    {
      "emoji": "🐋",
      "name": "whale",
    },
    {
      "emoji": "🐬",
      "name": "dolphin",
    },
    {
      "emoji": "🦭",
      "name": "seal",
    },
    {
      "emoji": "🐟",
      "name": "fish",
    },
    {
      "emoji": "🐠",
      "name": "tropical fish",
    },
    {
      "emoji": "🐡",
      "name": "blowfish",
    },
    {
      "emoji": "🦈",
      "name": "shark",
    },
    {
      "emoji": "🐙",
      "name": "octopus",
    },
    {
      "emoji": "🐚",
      "name": "spiral shell",
    },
    {
      "emoji": "🐌",
      "name": "snail",
    },
    {
      "emoji": "🦋",
      "name": "butterfly",
    },
    {
      "emoji": "🐛",
      "name": "bug",
    },
    {
      "emoji": "🐜",
      "name": "ant",
    },
    {
      "emoji": "🐝",
      "name": "honeybee",
    },
    {
      "emoji": "🪲",
      "name": "beetle",
    },
    {
      "emoji": "🐞",
      "name": "lady beetle",
    },
    {
      "emoji": "🦗",
      "name": "cricket",
    },
    {
      "emoji": "🪳",
      "name": "cockroach",
    },
    {
      "emoji": "🕷️",
      "name": "spider",
    },
    {
      "emoji": "🕸️",
      "name": "spider web",
    },
    {
      "emoji": "🦂",
      "name": "scorpion",
    },
    {
      "emoji": "🦟",
      "name": "mosquito",
    },
    {
      "emoji": "🪰",
      "name": "fly",
    },
    {
      "emoji": "🪱",
      "name": "worm",
    },
    {
      "emoji": "🦠",
      "name": "microbe",
    },
    {
      "emoji": "💐",
      "name": "bouquet",
    },
    {
      "emoji": "🌸",
      "name": "cherry blossom",
    },
    {
      "emoji": "💮",
      "name": "white flower",
    },
    {
      "emoji": "🏵️",
      "name": "rosette",
    },
    {
      "emoji": "🌹",
      "name": "rose",
    },
    {
      "emoji": "🥀",
      "name": "wilted flower",
    },
    {
      "emoji": "🌺",
      "name": "hibiscus",
    },
    {
      "emoji": "🌻",
      "name": "sunflower",
    },
    {
      "emoji": "🌼",
      "name": "blossom",
    },
    {
      "emoji": "🌷",
      "name": "tulip",
    },
    {
      "emoji": "🌱",
      "name": "seedling",
    },
    {
      "emoji": "🪴",
      "name": "potted plant",
    },
    {
      "emoji": "🌲",
      "name": "evergreen tree",
    },
    {
      "emoji": "🌳",
      "name": "deciduous tree",
    },
    {
      "emoji": "🌴",
      "name": "palm tree",
    },
    {
      "emoji": "🌵",
      "name": "cactus",
    },
    {
      "emoji": "🌾",
      "name": "sheaf of rice",
    },
    {
      "emoji": "🌿",
      "name": "herb",
    },
    {
      "emoji": "☘️",
      "name": "shamrock",
    },
    {
      "emoji": "🍀",
      "name": "four leaf clover",
    },
    {
      "emoji": "🍁",
      "name": "maple leaf",
    },
    {
      "emoji": "🍂",
      "name": "fallen leaf",
    },
    {
      "emoji": "🍃",
      "name": "leaf fluttering in wind",
    },
  ],
  "Еда и напитки": [
    {
      "emoji": "🍇",
      "name": "grapes",
    },
    {
      "emoji": "🍈",
      "name": "melon",
    },
    {
      "emoji": "🍉",
      "name": "watermelon",
    },
    {
      "emoji": "🍊",
      "name": "tangerine",
    },
    {
      "emoji": "🍋",
      "name": "lemon",
    },
    {
      "emoji": "🍌",
      "name": "banana",
    },
    {
      "emoji": "🍍",
      "name": "pineapple",
    },
    {
      "emoji": "🥭",
      "name": "mango",
    },
    {
      "emoji": "🍎",
      "name": "red apple",
    },
    {
      "emoji": "🍏",
      "name": "green apple",
    },
    {
      "emoji": "🍐",
      "name": "pear",
    },
    {
      "emoji": "🍑",
      "name": "peach",
    },
    {
      "emoji": "🍒",
      "name": "cherries",
    },
    {
      "emoji": "🍓",
      "name": "strawberry",
    },
    {
      "emoji": "🫐",
      "name": "blueberries",
    },
    {
      "emoji": "🥝",
      "name": "kiwi fruit",
    },
    {
      "emoji": "🍅",
      "name": "tomato",
    },
    {
      "emoji": "🫒",
      "name": "olive",
    },
    {
      "emoji": "🥥",
      "name": "coconut",
    },
    {
      "emoji": "🥑",
      "name": "avocado",
    },
    {
      "emoji": "🍆",
      "name": "eggplant",
    },
    {
      "emoji": "🥔",
      "name": "potato",
    },
    {
      "emoji": "🥕",
      "name": "carrot",
    },
    {
      "emoji": "🌽",
      "name": "ear of corn",
    },
    {
      "emoji": "🌶️",
      "name": "hot pepper",
    },
    {
      "emoji": "🫑",
      "name": "bell pepper",
    },
    {
      "emoji": "🥒",
      "name": "cucumber",
    },
    {
      "emoji": "🥬",
      "name": "leafy green",
    },
    {
      "emoji": "🥦",
      "name": "broccoli",
    },
    {
      "emoji": "🧄",
      "name": "garlic",
    },
    {
      "emoji": "🧅",
      "name": "onion",
    },
    {
      "emoji": "🍄",
      "name": "mushroom",
    },
    {
      "emoji": "🥜",
      "name": "peanuts",
    },
    {
      "emoji": "🌰",
      "name": "chestnut",
    },
    {
      "emoji": "🍞",
      "name": "bread",
    },
    {
      "emoji": "🥐",
      "name": "croissant",
    },
    {
      "emoji": "🥖",
      "name": "baguette bread",
    },
    {
      "emoji": "🫓",
      "name": "flatbread",
    },
    {
      "emoji": "🥨",
      "name": "pretzel",
    },
    {
      "emoji": "🥯",
      "name": "bagel",
    },
    {
      "emoji": "🥞",
      "name": "pancakes",
    },
    {
      "emoji": "🧇",
      "name": "waffle",
    },
    {
      "emoji": "🧀",
      "name": "cheese wedge",
    },
    {
      "emoji": "🍖",
      "name": "meat on bone",
    },
    {
      "emoji": "🍗",
      "name": "poultry leg",
    },
    {
      "emoji": "🥩",
      "name": "cut of meat",
    },
    {
      "emoji": "🥓",
      "name": "bacon",
    },
    {
      "emoji": "🍔",
      "name": "hamburger",
    },
    {
      "emoji": "🍟",
      "name": "french fries",
    },
    {
      "emoji": "🍕",
      "name": "pizza",
    },
    {
      "emoji": "🌭",
      "name": "hot dog",
    },
    {
      "emoji": "🥪",
      "name": "sandwich",
    },
    {
      "emoji": "🌮",
      "name": "taco",
    },
    {
      "emoji": "🌯",
      "name": "burrito",
    },
    {
      "emoji": "🫔",
      "name": "tamale",
    },
    {
      "emoji": "🥙",
      "name": "stuffed flatbread",
    },
    {
      "emoji": "🧆",
      "name": "falafel",
    },
    {
      "emoji": "🥚",
      "name": "egg",
    },
    {
      "emoji": "🍳",
      "name": "cooking",
    },
    {
      "emoji": "🥘",
      "name": "shallow pan of food",
    },
    {
      "emoji": "🍲",
      "name": "pot of food",
    },
    {
      "emoji": "🫕",
      "name": "fondue",
    },
    {
      "emoji": "🥣",
      "name": "bowl with spoon",
    },
    {
      "emoji": "🥗",
      "name": "green salad",
    },
    {
      "emoji": "🍿",
      "name": "popcorn",
    },
    {
      "emoji": "🧈",
      "name": "butter",
    },
    {
      "emoji": "🧂",
      "name": "salt",
    },
    {
      "emoji": "🥫",
      "name": "canned food",
    },
    {
      "emoji": "🍱",
      "name": "bento box",
    },
    {
      "emoji": "🍘",
      "name": "rice cracker",
    },
    {
      "emoji": "🍙",
      "name": "rice ball",
    },
    {
      "emoji": "🍚",
      "name": "cooked rice",
    },
    {
      "emoji": "🍛",
      "name": "curry rice",
    },
    {
      "emoji": "🍜",
      "name": "steaming bowl",
    },
    {
      "emoji": "🍝",
      "name": "spaghetti",
    },
    {
      "emoji": "🍠",
      "name": "roasted sweet potato",
    },
    {
      "emoji": "🍢",
      "name": "oden",
    },
    {
      "emoji": "🍣",
      "name": "sushi",
    },
    {
      "emoji": "🍤",
      "name": "fried shrimp",
    },
    {
      "emoji": "🍥",
      "name": "fish cake with swirl",
    },
    {
      "emoji": "🥮",
      "name": "moon cake",
    },
    {
      "emoji": "🍡",
      "name": "dango",
    },
    {
      "emoji": "🥟",
      "name": "dumpling",
    },
    {
      "emoji": "🥠",
      "name": "fortune cookie",
    },
    {
      "emoji": "🥡",
      "name": "takeout box",
    },
    {
      "emoji": "🦀",
      "name": "crab",
    },
    {
      "emoji": "🦞",
      "name": "lobster",
    },
    {
      "emoji": "🦐",
      "name": "shrimp",
    },
    {
      "emoji": "🦑",
      "name": "squid",
    },
    {
      "emoji": "🦪",
      "name": "oyster",
    },
    {
      "emoji": "🍦",
      "name": "soft ice cream",
    },
    {
      "emoji": "🍧",
      "name": "shaved ice",
    },
    {
      "emoji": "🍨",
      "name": "ice cream",
    },
    {
      "emoji": "🍩",
      "name": "doughnut",
    },
    {
      "emoji": "🍪",
      "name": "cookie",
    },
    {
      "emoji": "🎂",
      "name": "birthday cake",
    },
    {
      "emoji": "🍰",
      "name": "shortcake",
    },
    {
      "emoji": "🧁",
      "name": "cupcake",
    },
    {
      "emoji": "🥧",
      "name": "pie",
    },
    {
      "emoji": "🍫",
      "name": "chocolate bar",
    },
    {
      "emoji": "🍬",
      "name": "candy",
    },
    {
      "emoji": "🍭",
      "name": "lollipop",
    },
    {
      "emoji": "🍮",
      "name": "custard",
    },
    {
      "emoji": "🍯",
      "name": "honey pot",
    },
    {
      "emoji": "🍼",
      "name": "baby bottle",
    },
    {
      "emoji": "🥛",
      "name": "glass of milk",
    },
    {
      "emoji": "☕",
      "name": "hot beverage",
    },
    {
      "emoji": "🫖",
      "name": "teapot",
    },
    {
      "emoji": "🍵",
      "name": "teacup without handle",
    },
    {
      "emoji": "🍶",
      "name": "sake",
    },
    {
      "emoji": "🍾",
      "name": "bottle with popping cork",
    },
    {
      "emoji": "🍷",
      "name": "wine glass",
    },
    {
      "emoji": "🍸",
      "name": "cocktail glass",
    },
    {
      "emoji": "🍹",
      "name": "tropical drink",
    },
    {
      "emoji": "🍺",
      "name": "beer mug",
    },
    {
      "emoji": "🍻",
      "name": "clinking beer mugs",
    },
    {
      "emoji": "🥂",
      "name": "clinking glasses",
    },
    {
      "emoji": "🥃",
      "name": "tumbler glass",
    },
    {
      "emoji": "🥤",
      "name": "cup with straw",
    },
    {
      "emoji": "🧋",
      "name": "bubble tea",
    },
    {
      "emoji": "🧃",
      "name": "beverage box",
    },
    {
      "emoji": "🧉",
      "name": "mate",
    },
    {
      "emoji": "🧊",
      "name": "ice",
    },
    {
      "emoji": "🥢",
      "name": "chopsticks",
    },
    {
      "emoji": "🍽️",
      "name": "fork and knife with plate",
    },
    {
      "emoji": "🍴",
      "name": "fork and knife",
    },
    {
      "emoji": "🥄",
      "name": "spoon",
    },
    {
      "emoji": "🔪",
      "name": "kitchen knife",
    },
    {
      "emoji": "🏺",
      "name": "amphora",
    }
  ],
  "Путешествие и места": [
    {
      "emoji": "🌍",
      "name": "globe showing Europe-Africa",
    },
    {
      "emoji": "🌎",
      "name": "globe showing Americas",
    },
    {
      "emoji": "🌏",
      "name": "globe showing Asia-Australia",
    },
    {
      "emoji": "🌐",
      "name": "globe with meridians",
    },
    {
      "emoji": "🗺️",
      "name": "world map",
    },
    {
      "emoji": "🗾",
      "name": "map of Japan",
    },
    {
      "emoji": "🧭",
      "name": "compass",
    },
    {
      "emoji": "🏔️",
      "name": "snow-capped mountain",
    },
    {
      "emoji": "⛰️",
      "name": "mountain",
    },
    {
      "emoji": "🌋",
      "name": "volcano",
    },
    {
      "emoji": "🗻",
      "name": "mount fuji",
    },
    {
      "emoji": "🏕️",
      "name": "camping",
    },
    {
      "emoji": "🏖️",
      "name": "beach with umbrella",
    },
    {
      "emoji": "🏜️",
      "name": "desert",
    },
    {
      "emoji": "🏝️",
      "name": "desert island",
    },
    {
      "emoji": "🏞️",
      "name": "national park",
    },
    {
      "emoji": "🏟️",
      "name": "stadium",
    },
    {
      "emoji": "🏛️",
      "name": "classical building",
    },
    {
      "emoji": "🏗️",
      "name": "building construction",
    },
    {
      "emoji": "🧱",
      "name": "brick",
    },
    {
      "emoji": "🪨",
      "name": "rock",
    },
    {
      "emoji": "🪵",
      "name": "wood",
    },
    {
      "emoji": "🛖",
      "name": "hut",
    },
    {
      "emoji": "🏘️",
      "name": "houses",
    },
    {
      "emoji": "🏚️",
      "name": "derelict house",
    },
    {
      "emoji": "🏠",
      "name": "house",
    },
    {
      "emoji": "🏡",
      "name": "house with garden",
    },
    {
      "emoji": "🏢",
      "name": "office building",
    },
    {
      "emoji": "🏣",
      "name": "Japanese post office",
    },
    {
      "emoji": "🏤",
      "name": "post office",
    },
    {
      "emoji": "🏥",
      "name": "hospital",
    },
    {
      "emoji": "🏦",
      "name": "bank",
    },
    {
      "emoji": "🏨",
      "name": "hotel",
    },
    {
      "emoji": "🏩",
      "name": "love hotel",
    },
    {
      "emoji": "🏪",
      "name": "convenience store",
    },
    {
      "emoji": "🏫",
      "name": "school",
    },
    {
      "emoji": "🏬",
      "name": "department store",
    },
    {
      "emoji": "🏭",
      "name": "factory",
    },
    {
      "emoji": "🏯",
      "name": "Japanese castle",
    },
    {
      "emoji": "🏰",
      "name": "castle",
    },
    {
      "emoji": "💒",
      "name": "wedding",
    },
    {
      "emoji": "🗼",
      "name": "Tokyo tower",
    },
    {
      "emoji": "🗽",
      "name": "Statue of Liberty",
    },
    {
      "emoji": "⛪",
      "name": "church",
    },
    {
      "emoji": "🕌",
      "name": "mosque",
    },
    {
      "emoji": "🛕",
      "name": "hindu temple",
    },
    {
      "emoji": "🕍",
      "name": "synagogue",
    },
    {
      "emoji": "⛩️",
      "name": "shinto shrine",
    },
    {
      "emoji": "🕋",
      "name": "kaaba",
    },
    {
      "emoji": "⛲",
      "name": "fountain",
    },
    {
      "emoji": "⛺",
      "name": "tent",
    },
    {
      "emoji": "🌁",
      "name": "foggy",
    },
    {
      "emoji": "🌃",
      "name": "night with stars",
    },
    {
      "emoji": "🏙️",
      "name": "cityscape",
    },
    {
      "emoji": "🌄",
      "name": "sunrise over mountains",
    },
    {
      "emoji": "🌅",
      "name": "sunrise",
    },
    {
      "emoji": "🌆",
      "name": "cityscape at dusk",
    },
    {
      "emoji": "🌇",
      "name": "sunset",
    },
    {
      "emoji": "🌉",
      "name": "bridge at night",
    },
    {
      "emoji": "♨️",
      "name": "hot springs",
    },
    {
      "emoji": "🎠",
      "name": "carousel horse",
    },
    {
      "emoji": "🎡",
      "name": "ferris wheel",
    },
    {
      "emoji": "🎢",
      "name": "roller coaster",
    },
    {
      "emoji": "💈",
      "name": "barber pole",
    },
    {
      "emoji": "🎪",
      "name": "circus tent",
    },
    {
      "emoji": "🚂",
      "name": "locomotive",
    },
    {
      "emoji": "🚃",
      "name": "railway car",
    },
    {
      "emoji": "🚄",
      "name": "high-speed train",
    },
    {
      "emoji": "🚅",
      "name": "bullet train",
    },
    {
      "emoji": "🚆",
      "name": "train",
    },
    {
      "emoji": "🚇",
      "name": "metro",
    },
    {
      "emoji": "🚈",
      "name": "light rail",
    },
    {
      "emoji": "🚉",
      "name": "station",
    },
    {
      "emoji": "🚊",
      "name": "tram",
    },
    {
      "emoji": "🚝",
      "name": "monorail",
    },
    {
      "emoji": "🚞",
      "name": "mountain railway",
    },
    {
      "emoji": "🚋",
      "name": "tram car",
    },
    {
      "emoji": "🚌",
      "name": "bus",
    },
    {
      "emoji": "🚍",
      "name": "oncoming bus",
    },
    {
      "emoji": "🚎",
      "name": "trolleybus",
    },
    {
      "emoji": "🚐",
      "name": "minibus",
    },
    {
      "emoji": "🚑",
      "name": "ambulance",
    },
    {
      "emoji": "🚒",
      "name": "fire engine",
    },
    {
      "emoji": "🚓",
      "name": "police car",
    },
    {
      "emoji": "🚔",
      "name": "oncoming police car",
    },
    {
      "emoji": "🚕",
      "name": "taxi",
    },
    {
      "emoji": "🚖",
      "name": "oncoming taxi",
    },
    {
      "emoji": "🚗",
      "name": "automobile",
    },
    {
      "emoji": "🚘",
      "name": "oncoming automobile",
    },
    {
      "emoji": "🚙",
      "name": "sport utility vehicle",
    },
    {
      "emoji": "🛻",
      "name": "pickup truck",
    },
    {
      "emoji": "🚚",
      "name": "delivery truck",
    },
    {
      "emoji": "🚛",
      "name": "articulated lorry",
    },
    {
      "emoji": "🚜",
      "name": "tractor",
    },
    {
      "emoji": "🏎️",
      "name": "racing car",
    },
    {
      "emoji": "🏍️",
      "name": "motorcycle",
    },
    {
      "emoji": "🛵",
      "name": "motor scooter",
    },
    {
      "emoji": "🦽",
      "name": "manual wheelchair",
    },
    {
      "emoji": "🦼",
      "name": "motorized wheelchair",
    },
    {
      "emoji": "🛺",
      "name": "auto rickshaw",
    },
    {
      "emoji": "🚲",
      "name": "bicycle",
    },
    {
      "emoji": "🛴",
      "name": "kick scooter",
    },
    {
      "emoji": "🛹",
      "name": "skateboard",
    },
    {
      "emoji": "🛼",
      "name": "roller skate",
    },
    {
      "emoji": "🚏",
      "name": "bus stop",
    },
    {
      "emoji": "🛣️",
      "name": "motorway",
    },
    {
      "emoji": "🛤️",
      "name": "railway track",
    },
    {
      "emoji": "🛢️",
      "name": "oil drum",
    },
    {
      "emoji": "⛽",
      "name": "fuel pump",
    },
    {
      "emoji": "🚨",
      "name": "police car light",
    },
    {
      "emoji": "🚥",
      "name": "horizontal traffic light",
    },
    {
      "emoji": "🚦",
      "name": "vertical traffic light",
    },
    {
      "emoji": "🛑",
      "name": "stop sign",
    },
    {
      "emoji": "🚧",
      "name": "construction",
    },
    {
      "emoji": "⚓",
      "name": "anchor",
    },
    {
      "emoji": "⛵",
      "name": "sailboat",
    },
    {
      "emoji": "🛶",
      "name": "canoe",
    },
    {
      "emoji": "🚤",
      "name": "speedboat",
    },
    {
      "emoji": "🛳️",
      "name": "passenger ship",
    },
    {
      "emoji": "⛴️",
      "name": "ferry",
    },
    {
      "emoji": "🛥️",
      "name": "motor boat",
    },
    {
      "emoji": "🚢",
      "name": "ship",
    },
    {
      "emoji": "✈️",
      "name": "airplane",
    },
    {
      "emoji": "🛩️",
      "name": "small airplane",
    },
    {
      "emoji": "🛫",
      "name": "airplane departure",
    },
    {
      "emoji": "🛬",
      "name": "airplane arrival",
    },
    {
      "emoji": "🪂",
      "name": "parachute",
    },
    {
      "emoji": "💺",
      "name": "seat",
    },
    {
      "emoji": "🚁",
      "name": "helicopter",
    },
    {
      "emoji": "🚟",
      "name": "suspension railway",
    },
    {
      "emoji": "🚠",
      "name": "mountain cableway",
    },
    {
      "emoji": "🚡",
      "name": "aerial tramway",
    },
    {
      "emoji": "🛰️",
      "name": "satellite",
    },
    {
      "emoji": "🚀",
      "name": "rocket",
    },
    {
      "emoji": "🛸",
      "name": "flying saucer",
    },
    {
      "emoji": "🛎️",
      "name": "bellhop bell",
    },
    {
      "emoji": "🧳",
      "name": "luggage",
    },
    {
      "emoji": "⌛",
      "name": "hourglass done",
    },
    {
      "emoji": "⏳",
      "name": "hourglass not done",
    },
    {
      "emoji": "⌚",
      "name": "watch",
    },
    {
      "emoji": "⏰",
      "name": "alarm clock",
    },
    {
      "emoji": "⏱️",
      "name": "stopwatch",
    },
    {
      "emoji": "⏲️",
      "name": "timer clock",
    },
    {
      "emoji": "🕰️",
      "name": "mantelpiece clock",
    },
    {
      "emoji": "🕛",
      "name": "twelve o’clock",
    },
    {
      "emoji": "🕧",
      "name": "twelve-thirty",
    },
    {
      "emoji": "🕐",
      "name": "one o’clock",
    },
    {
      "emoji": "🕜",
      "name": "one-thirty",
    },
    {
      "emoji": "🕑",
      "name": "two o’clock",
    },
    {
      "emoji": "🕝",
      "name": "two-thirty",
    },
    {
      "emoji": "🕒",
      "name": "three o’clock",
    },
    {
      "emoji": "🕞",
      "name": "three-thirty",
    },
    {
      "emoji": "🕓",
      "name": "four o’clock",
    },
    {
      "emoji": "🕟",
      "name": "four-thirty",
    },
    {
      "emoji": "🕔",
      "name": "five o’clock",
    },
    {
      "emoji": "🕠",
      "name": "five-thirty",
    },
    {
      "emoji": "🕕",
      "name": "six o’clock",
    },
    {
      "emoji": "🕡",
      "name": "six-thirty",
    },
    {
      "emoji": "🕖",
      "name": "seven o’clock",
    },
    {
      "emoji": "🕢",
      "name": "seven-thirty",
    },
    {
      "emoji": "🕗",
      "name": "eight o’clock",
    },
    {
      "emoji": "🕣",
      "name": "eight-thirty",
    },
    {
      "emoji": "🕘",
      "name": "nine o’clock",
    },
    {
      "emoji": "🕤",
      "name": "nine-thirty",
    },
    {
      "emoji": "🕙",
      "name": "ten o’clock",
    },
    {
      "emoji": "🕥",
      "name": "ten-thirty",
    },
    {
      "emoji": "🕚",
      "name": "eleven o’clock",
    },
    {
      "emoji": "🕦",
      "name": "eleven-thirty",
    },
    {
      "emoji": "🌑",
      "name": "new moon",
    },
    {
      "emoji": "🌒",
      "name": "waxing crescent moon",
    },
    {
      "emoji": "🌓",
      "name": "first quarter moon",
    },
    {
      "emoji": "🌔",
      "name": "waxing gibbous moon",
    },
    {
      "emoji": "🌕",
      "name": "full moon",
    },
    {
      "emoji": "🌖",
      "name": "waning gibbous moon",
    },
    {
      "emoji": "🌗",
      "name": "last quarter moon",
    },
    {
      "emoji": "🌘",
      "name": "waning crescent moon",
    },
    {
      "emoji": "🌙",
      "name": "crescent moon",
    },
    {
      "emoji": "🌚",
      "name": "new moon face",
    },
    {
      "emoji": "🌛",
      "name": "first quarter moon face",
    },
    {
      "emoji": "🌜",
      "name": "last quarter moon face",
    },
    {
      "emoji": "🌡️",
      "name": "thermometer",
    },
    {
      "emoji": "☀️",
      "name": "sun",
    },
    {
      "emoji": "🌝",
      "name": "full moon face",
    },
    {
      "emoji": "🌞",
      "name": "sun with face",
    },
    {
      "emoji": "🪐",
      "name": "ringed planet",
    },
    {
      "emoji": "⭐",
      "name": "star",
    },
    {
      "emoji": "🌟",
      "name": "glowing star",
    },
    {
      "emoji": "🌠",
      "name": "shooting star",
    },
    {
      "emoji": "🌌",
      "name": "milky way",
    },
    {
      "emoji": "☁️",
      "name": "cloud",
    },
    {
      "emoji": "⛅",
      "name": "sun behind cloud",
    },
    {
      "emoji": "⛈️",
      "name": "cloud with lightning and rain",
    },
    {
      "emoji": "🌤️",
      "name": "sun behind small cloud",
    },
    {
      "emoji": "🌥️",
      "name": "sun behind large cloud",
    },
    {
      "emoji": "🌦️",
      "name": "sun behind rain cloud",
    },
    {
      "emoji": "🌧️",
      "name": "cloud with rain",
    },
    {
      "emoji": "🌨️",
      "name": "cloud with snow",
    },
    {
      "emoji": "🌩️",
      "name": "cloud with lightning",
    },
    {
      "emoji": "🌪️",
      "name": "tornado",
    },
    {
      "emoji": "🌫️",
      "name": "fog",
    },
    {
      "emoji": "🌬️",
      "name": "wind face",
    },
    {
      "emoji": "🌀",
      "name": "cyclone",
    },
    {
      "emoji": "🌈",
      "name": "rainbow",
    },
    {
      "emoji": "🌂",
      "name": "closed umbrella",
    },
    {
      "emoji": "☂️",
      "name": "umbrella",
    },
    {
      "emoji": "☔",
      "name": "umbrella with rain drops",
    },
    {
      "emoji": "⛱️",
      "name": "umbrella on ground",
    },
    {
      "emoji": "⚡",
      "name": "high voltage",
    },
    {
      "emoji": "❄️",
      "name": "snowflake",
    },
    {
      "emoji": "☃️",
      "name": "snowman",
    },
    {
      "emoji": "⛄",
      "name": "snowman without snow",
    },
    {
      "emoji": "☄️",
      "name": "comet",
    },
    {
      "emoji": "🔥",
      "name": "fire",
    },
    {
      "emoji": "💧",
      "name": "droplet",
    },
    {
      "emoji": "🌊",
      "name": "water wave",
    }
  ],
  "Активности": [
    {
      "emoji": "🎃",
      "name": "jack-o-lantern",
    },
    {
      "emoji": "🎄",
      "name": "Christmas tree",
    },
    {
      "emoji": "🎆",
      "name": "fireworks",
    },
    {
      "emoji": "🎇",
      "name": "sparkler",
    },
    {
      "emoji": "🧨",
      "name": "firecracker",
    },
    {
      "emoji": "✨",
      "name": "sparkles",
    },
    {
      "emoji": "🎈",
      "name": "balloon",
    },
    {
      "emoji": "🎉",
      "name": "party popper",
    },
    {
      "emoji": "🎊",
      "name": "confetti ball",
    },
    {
      "emoji": "🎋",
      "name": "tanabata tree",
    },
    {
      "emoji": "🎍",
      "name": "pine decoration",
    },
    {
      "emoji": "🎎",
      "name": "Japanese dolls",
    },
    {
      "emoji": "🎏",
      "name": "carp streamer",
    },
    {
      "emoji": "🎐",
      "name": "wind chime",
    },
    {
      "emoji": "🎑",
      "name": "moon viewing ceremony",
    },
    {
      "emoji": "🧧",
      "name": "red envelope",
    },
    {
      "emoji": "🎀",
      "name": "ribbon",
    },
    {
      "emoji": "🎁",
      "name": "wrapped gift",
    },
    {
      "emoji": "🎗️",
      "name": "reminder ribbon",
    },
    {
      "emoji": "🎟️",
      "name": "admission tickets",
    },
    {
      "emoji": "🎫",
      "name": "ticket",
    },
    {
      "emoji": "🎖️",
      "name": "military medal",
    },
    {
      "emoji": "🏆",
      "name": "trophy",
    },
    {
      "emoji": "🏅",
      "name": "sports medal",
    },
    {
      "emoji": "🥇",
      "name": "1st place medal",
    },
    {
      "emoji": "🥈",
      "name": "2nd place medal",
    },
    {
      "emoji": "🥉",
      "name": "3rd place medal",
    },
    {
      "emoji": "⚽",
      "name": "soccer ball",
    },
    {
      "emoji": "⚾",
      "name": "baseball",
    },
    {
      "emoji": "🥎",
      "name": "softball",
    },
    {
      "emoji": "🏀",
      "name": "basketball",
    },
    {
      "emoji": "🏐",
      "name": "volleyball",
    },
    {
      "emoji": "🏈",
      "name": "american football",
    },
    {
      "emoji": "🏉",
      "name": "rugby football",
    },
    {
      "emoji": "🎾",
      "name": "tennis",
    },
    {
      "emoji": "🥏",
      "name": "flying disc",
    },
    {
      "emoji": "🎳",
      "name": "bowling",
    },
    {
      "emoji": "🏏",
      "name": "cricket game",
    },
    {
      "emoji": "🏑",
      "name": "field hockey",
    },
    {
      "emoji": "🏒",
      "name": "ice hockey",
    },
    {
      "emoji": "🥍",
      "name": "lacrosse",
    },
    {
      "emoji": "🏓",
      "name": "ping pong",
    },
    {
      "emoji": "🏸",
      "name": "badminton",
    },
    {
      "emoji": "🥊",
      "name": "boxing glove",
    },
    {
      "emoji": "🥋",
      "name": "martial arts uniform",
    },
    {
      "emoji": "🥅",
      "name": "goal net",
    },
    {
      "emoji": "⛳",
      "name": "flag in hole",
    },
    {
      "emoji": "⛸️",
      "name": "ice skate",
    },
    {
      "emoji": "🎣",
      "name": "fishing pole",
    },
    {
      "emoji": "🤿",
      "name": "diving mask",
    },
    {
      "emoji": "🎽",
      "name": "running shirt",
    },
    {
      "emoji": "🎿",
      "name": "skis",
    },
    {
      "emoji": "🛷",
      "name": "sled",
    },
    {
      "emoji": "🥌",
      "name": "curling stone",
    },
    {
      "emoji": "🎯",
      "name": "bullseye",
    },
    {
      "emoji": "🪀",
      "name": "yo-yo",
    },
    {
      "emoji": "🪁",
      "name": "kite",
    },
    {
      "emoji": "🎱",
      "name": "pool 8 ball",
    },
    {
      "emoji": "🔮",
      "name": "crystal ball",
    },
    {
      "emoji": "🪄",
      "name": "magic wand",
    },
    {
      "emoji": "🧿",
      "name": "nazar amulet",
    },
    {
      "emoji": "🎮",
      "name": "video game",
    },
    {
      "emoji": "🕹️",
      "name": "joystick",
    },
    {
      "emoji": "🎰",
      "name": "slot machine",
    },
    {
      "emoji": "🎲",
      "name": "game die",
    },
    {
      "emoji": "🧩",
      "name": "puzzle piece",
    },
    {
      "emoji": "🧸",
      "name": "teddy bear",
    },
    {
      "emoji": "🪅",
      "name": "piñata",
    },
    {
      "emoji": "🪆",
      "name": "nesting dolls",
    },
    {
      "emoji": "♠️",
      "name": "spade suit",
    },
    {
      "emoji": "♥️",
      "name": "heart suit",
    },
    {
      "emoji": "♦️",
      "name": "diamond suit",
    },
    {
      "emoji": "♣️",
      "name": "club suit",
    },
    {
      "emoji": "♟️",
      "name": "chess pawn",
    },
    {
      "emoji": "🃏",
      "name": "joker",
    },
    {
      "emoji": "🀄",
      "name": "mahjong red dragon",
    },
    {
      "emoji": "🎴",
      "name": "flower playing cards",
    },
    {
      "emoji": "🎭",
      "name": "performing arts",
    },
    {
      "emoji": "🖼️",
      "name": "framed picture",
    },
    {
      "emoji": "🎨",
      "name": "artist palette",
    },
    {
      "emoji": "🧵",
      "name": "thread",
    },
    {
      "emoji": "🪡",
      "name": "sewing needle",
    },
    {
      "emoji": "🧶",
      "name": "yarn",
    },
    {
      "emoji": "🪢",
      "name": "knot",
    }
  ],
  "Предметы": [
    {
      "emoji": "👓",
      "name": "glasses",
    },
    {
      "emoji": "🕶️",
      "name": "sunglasses",
    },
    {
      "emoji": "🥽",
      "name": "goggles",
    },
    {
      "emoji": "🥼",
      "name": "lab coat",
    },
    {
      "emoji": "🦺",
      "name": "safety vest",
    },
    {
      "emoji": "👔",
      "name": "necktie",
    },
    {
      "emoji": "👕",
      "name": "t-shirt",
    },
    {
      "emoji": "👖",
      "name": "jeans",
    },
    {
      "emoji": "🧣",
      "name": "scarf",
    },
    {
      "emoji": "🧤",
      "name": "gloves",
    },
    {
      "emoji": "🧥",
      "name": "coat",
    },
    {
      "emoji": "🧦",
      "name": "socks",
    },
    {
      "emoji": "👗",
      "name": "dress",
    },
    {
      "emoji": "👘",
      "name": "kimono",
    },
    {
      "emoji": "🥻",
      "name": "sari",
    },
    {
      "emoji": "🩱",
      "name": "one-piece swimsuit",
    },
    {
      "emoji": "🩲",
      "name": "briefs",
    },
    {
      "emoji": "🩳",
      "name": "shorts",
    },
    {
      "emoji": "👙",
      "name": "bikini",
    },
    {
      "emoji": "👚",
      "name": "woman’s clothes",
    },
    {
      "emoji": "👛",
      "name": "purse",
    },
    {
      "emoji": "👜",
      "name": "handbag",
    },
    {
      "emoji": "👝",
      "name": "clutch bag",
    },
    {
      "emoji": "🛍️",
      "name": "shopping bags",
    },
    {
      "emoji": "🎒",
      "name": "backpack",
    },
    {
      "emoji": "🩴",
      "name": "thong sandal",
    },
    {
      "emoji": "👞",
      "name": "man’s shoe",
    },
    {
      "emoji": "👟",
      "name": "running shoe",
    },
    {
      "emoji": "🥾",
      "name": "hiking boot",
    },
    {
      "emoji": "🥿",
      "name": "flat shoe",
    },
    {
      "emoji": "👠",
      "name": "high-heeled shoe",
    },
    {
      "emoji": "👡",
      "name": "woman’s sandal",
    },
    {
      "emoji": "🩰",
      "name": "ballet shoes",
    },
    {
      "emoji": "👢",
      "name": "woman’s boot",
    },
    {
      "emoji": "👑",
      "name": "crown",
    },
    {
      "emoji": "👒",
      "name": "woman’s hat",
    },
    {
      "emoji": "🎩",
      "name": "top hat",
    },
    {
      "emoji": "🎓",
      "name": "graduation cap",
    },
    {
      "emoji": "🧢",
      "name": "billed cap",
    },
    {
      "emoji": "🪖",
      "name": "military helmet",
    },
    {
      "emoji": "⛑️",
      "name": "rescue worker’s helmet",
    },
    {
      "emoji": "📿",
      "name": "prayer beads",
    },
    {
      "emoji": "💄",
      "name": "lipstick",
    },
    {
      "emoji": "💍",
      "name": "ring",
    },
    {
      "emoji": "💎",
      "name": "gem stone",
    },
    {
      "emoji": "🔇",
      "name": "muted speaker",
    },
    {
      "emoji": "🔈",
      "name": "speaker low volume",
    },
    {
      "emoji": "🔉",
      "name": "speaker medium volume",
    },
    {
      "emoji": "🔊",
      "name": "speaker high volume",
    },
    {
      "emoji": "📢",
      "name": "loudspeaker",
    },
    {
      "emoji": "📣",
      "name": "megaphone",
    },
    {
      "emoji": "📯",
      "name": "postal horn",
    },
    {
      "emoji": "🔔",
      "name": "bell",
    },
    {
      "emoji": "🔕",
      "name": "bell with slash",
    },
    {
      "emoji": "🎼",
      "name": "musical score",
    },
    {
      "emoji": "🎵",
      "name": "musical note",
    },
    {
      "emoji": "🎶",
      "name": "musical notes",
    },
    {
      "emoji": "🎙️",
      "name": "studio microphone",
    },
    {
      "emoji": "🎚️",
      "name": "level slider",
    },
    {
      "emoji": "🎛️",
      "name": "control knobs",
    },
    {
      "emoji": "🎤",
      "name": "microphone",
    },
    {
      "emoji": "🎧",
      "name": "headphone",
    },
    {
      "emoji": "📻",
      "name": "radio",
    },
    {
      "emoji": "🎷",
      "name": "saxophone",
    },
    {
      "emoji": "🪗",
      "name": "accordion",
    },
    {
      "emoji": "🎸",
      "name": "guitar",
    },
    {
      "emoji": "🎹",
      "name": "musical keyboard",
    },
    {
      "emoji": "🎺",
      "name": "trumpet",
    },
    {
      "emoji": "🎻",
      "name": "violin",
    },
    {
      "emoji": "🪕",
      "name": "banjo",
    },
    {
      "emoji": "🥁",
      "name": "drum",
    },
    {
      "emoji": "🪘",
      "name": "long drum",
    },
    {
      "emoji": "📱",
      "name": "mobile phone",
    },
    {
      "emoji": "📲",
      "name": "mobile phone with arrow",
    },
    {
      "emoji": "☎️",
      "name": "telephone",
    },
    {
      "emoji": "📞",
      "name": "telephone receiver",
    },
    {
      "emoji": "📟",
      "name": "pager",
    },
    {
      "emoji": "📠",
      "name": "fax machine",
    },
    {
      "emoji": "🔋",
      "name": "battery",
    },
    {
      "emoji": "🔌",
      "name": "electric plug",
    },
    {
      "emoji": "💻",
      "name": "laptop",
    },
    {
      "emoji": "🖥️",
      "name": "desktop computer",
    },
    {
      "emoji": "🖨️",
      "name": "printer",
    },
    {
      "emoji": "⌨️",
      "name": "keyboard",
    },
    {
      "emoji": "🖱️",
      "name": "computer mouse",
    },
    {
      "emoji": "🖲️",
      "name": "trackball",
    },
    {
      "emoji": "💽",
      "name": "computer disk",
    },
    {
      "emoji": "💾",
      "name": "floppy disk",
    },
    {
      "emoji": "💿",
      "name": "optical disk",
    },
    {
      "emoji": "📀",
      "name": "dvd",
    },
    {
      "emoji": "🧮",
      "name": "abacus",
    },
    {
      "emoji": "🎥",
      "name": "movie camera",
    },
    {
      "emoji": "🎞️",
      "name": "film frames",
    },
    {
      "emoji": "📽️",
      "name": "film projector",
    },
    {
      "emoji": "🎬",
      "name": "clapper board",
    },
    {
      "emoji": "📺",
      "name": "television",
    },
    {
      "emoji": "📷",
      "name": "camera",
    },
    {
      "emoji": "📸",
      "name": "camera with flash",
    },
    {
      "emoji": "📹",
      "name": "video camera",
    },
    {
      "emoji": "📼",
      "name": "videocassette",
    },
    {
      "emoji": "🔍",
      "name": "magnifying glass tilted left",
    },
    {
      "emoji": "🔎",
      "name": "magnifying glass tilted right",
    },
    {
      "emoji": "🕯️",
      "name": "candle",
    },
    {
      "emoji": "💡",
      "name": "light bulb",
    },
    {
      "emoji": "🔦",
      "name": "flashlight",
    },
    {
      "emoji": "🏮",
      "name": "red paper lantern",
    },
    {
      "emoji": "🪔",
      "name": "diya lamp",
    },
    {
      "emoji": "📔",
      "name": "notebook with decorative cover",
    },
    {
      "emoji": "📕",
      "name": "closed book",
    },
    {
      "emoji": "📖",
      "name": "open book",
    },
    {
      "emoji": "📗",
      "name": "green book",
    },
    {
      "emoji": "📘",
      "name": "blue book",
    },
    {
      "emoji": "📙",
      "name": "orange book",
    },
    {
      "emoji": "📚",
      "name": "books",
    },
    {
      "emoji": "📓",
      "name": "notebook",
    },
    {
      "emoji": "📒",
      "name": "ledger",
    },
    {
      "emoji": "📃",
      "name": "page with curl",
    },
    {
      "emoji": "📜",
      "name": "scroll",
    },
    {
      "emoji": "📄",
      "name": "page facing up",
    },
    {
      "emoji": "📰",
      "name": "newspaper",
    },
    {
      "emoji": "🗞️",
      "name": "rolled-up newspaper",
    },
    {
      "emoji": "📑",
      "name": "bookmark tabs",
    },
    {
      "emoji": "🔖",
      "name": "bookmark",
    },
    {
      "emoji": "🏷️",
      "name": "label",
    },
    {
      "emoji": "💰",
      "name": "money bag",
    },
    {
      "emoji": "🪙",
      "name": "coin",
    },
    {
      "emoji": "💴",
      "name": "yen banknote",
    },
    {
      "emoji": "💵",
      "name": "dollar banknote",
    },
    {
      "emoji": "💶",
      "name": "euro banknote",
    },
    {
      "emoji": "💷",
      "name": "pound banknote",
    },
    {
      "emoji": "💸",
      "name": "money with wings",
    },
    {
      "emoji": "💳",
      "name": "credit card",
    },
    {
      "emoji": "🧾",
      "name": "receipt",
    },
    {
      "emoji": "💹",
      "name": "chart increasing with yen",
    },
    {
      "emoji": "✉️",
      "name": "envelope",
    },
    {
      "emoji": "📧",
      "name": "e-mail",
    },
    {
      "emoji": "📨",
      "name": "incoming envelope",
    },
    {
      "emoji": "📩",
      "name": "envelope with arrow",
    },
    {
      "emoji": "📤",
      "name": "outbox tray",
    },
    {
      "emoji": "📥",
      "name": "inbox tray",
    },
    {
      "emoji": "📦",
      "name": "package",
    },
    {
      "emoji": "📫",
      "name": "closed mailbox with raised flag",
    },
    {
      "emoji": "📪",
      "name": "closed mailbox with lowered flag",
    },
    {
      "emoji": "📬",
      "name": "open mailbox with raised flag",
    },
    {
      "emoji": "📭",
      "name": "open mailbox with lowered flag",
    },
    {
      "emoji": "📮",
      "name": "postbox",
    },
    {
      "emoji": "🗳️",
      "name": "ballot box with ballot",
    },
    {
      "emoji": "✏️",
      "name": "pencil",
    },
    {
      "emoji": "✒️",
      "name": "black nib",
    },
    {
      "emoji": "🖋️",
      "name": "fountain pen",
    },
    {
      "emoji": "🖊️",
      "name": "pen",
    },
    {
      "emoji": "🖌️",
      "name": "paintbrush",
    },
    {
      "emoji": "🖍️",
      "name": "crayon",
    },
    {
      "emoji": "📝",
      "name": "memo",
    },
    {
      "emoji": "💼",
      "name": "briefcase",
    },
    {
      "emoji": "📁",
      "name": "file folder",
    },
    {
      "emoji": "📂",
      "name": "open file folder",
    },
    {
      "emoji": "🗂️",
      "name": "card index dividers",
    },
    {
      "emoji": "📅",
      "name": "calendar",
    },
    {
      "emoji": "📆",
      "name": "tear-off calendar",
    },
    {
      "emoji": "🗒️",
      "name": "spiral notepad",
    },
    {
      "emoji": "🗓️",
      "name": "spiral calendar",
    },
    {
      "emoji": "📇",
      "name": "card index",
    },
    {
      "emoji": "📈",
      "name": "chart increasing",
    },
    {
      "emoji": "📉",
      "name": "chart decreasing",
    },
    {
      "emoji": "📊",
      "name": "bar chart",
    },
    {
      "emoji": "📋",
      "name": "clipboard",
    },
    {
      "emoji": "📌",
      "name": "pushpin",
    },
    {
      "emoji": "📍",
      "name": "round pushpin",
    },
    {
      "emoji": "📎",
      "name": "paperclip",
    },
    {
      "emoji": "🖇️",
      "name": "linked paperclips",
    },
    {
      "emoji": "📏",
      "name": "straight ruler",
    },
    {
      "emoji": "📐",
      "name": "triangular ruler",
    },
    {
      "emoji": "✂️",
      "name": "scissors",
    },
    {
      "emoji": "🗃️",
      "name": "card file box",
    },
    {
      "emoji": "🗄️",
      "name": "file cabinet",
    },
    {
      "emoji": "🗑️",
      "name": "wastebasket",
    },
    {
      "emoji": "🔒",
      "name": "locked",
    },
    {
      "emoji": "🔓",
      "name": "unlocked",
    },
    {
      "emoji": "🔏",
      "name": "locked with pen",
    },
    {
      "emoji": "🔐",
      "name": "locked with key",
    },
    {
      "emoji": "🔑",
      "name": "key",
    },
    {
      "emoji": "🗝️",
      "name": "old key",
    },
    {
      "emoji": "🔨",
      "name": "hammer",
    },
    {
      "emoji": "🪓",
      "name": "axe",
    },
    {
      "emoji": "⛏️",
      "name": "pick",
    },
    {
      "emoji": "⚒️",
      "name": "hammer and pick",
    },
    {
      "emoji": "🛠️",
      "name": "hammer and wrench",
    },
    {
      "emoji": "🗡️",
      "name": "dagger",
    },
    {
      "emoji": "⚔️",
      "name": "crossed swords",
    },
    {
      "emoji": "🔫",
      "name": "water pistol",
    },
    {
      "emoji": "🪃",
      "name": "boomerang",
    },
    {
      "emoji": "🏹",
      "name": "bow and arrow",
    },
    {
      "emoji": "🛡️",
      "name": "shield",
    },
    {
      "emoji": "🪚",
      "name": "carpentry saw",
    },
    {
      "emoji": "🔧",
      "name": "wrench",
    },
    {
      "emoji": "🪛",
      "name": "screwdriver",
    },
    {
      "emoji": "🔩",
      "name": "nut and bolt",
    },
    {
      "emoji": "⚙️",
      "name": "gear",
    },
    {
      "emoji": "🗜️",
      "name": "clamp",
    },
    {
      "emoji": "⚖️",
      "name": "balance scale",
    },
    {
      "emoji": "🦯",
      "name": "white cane",
    },
    {
      "emoji": "🔗",
      "name": "link",
    },
    {
      "emoji": "⛓️",
      "name": "chains",
    },
    {
      "emoji": "🪝",
      "name": "hook",
    },
    {
      "emoji": "🧰",
      "name": "toolbox",
    },
    {
      "emoji": "🧲",
      "name": "magnet",
    },
    {
      "emoji": "🪜",
      "name": "ladder",
    },
    {
      "emoji": "⚗️",
      "name": "alembic",
    },
    {
      "emoji": "🧪",
      "name": "test tube",
    },
    {
      "emoji": "🧫",
      "name": "petri dish",
    },
    {
      "emoji": "🧬",
      "name": "dna",
    },
    {
      "emoji": "🔬",
      "name": "microscope",
    },
    {
      "emoji": "🔭",
      "name": "telescope",
    },
    {
      "emoji": "📡",
      "name": "satellite antenna",
    },
    {
      "emoji": "💉",
      "name": "syringe",
    },
    {
      "emoji": "🩸",
      "name": "drop of blood",
    },
    {
      "emoji": "💊",
      "name": "pill",
    },
    {
      "emoji": "🩹",
      "name": "adhesive bandage",
    },
    {
      "emoji": "🩺",
      "name": "stethoscope",
    },
    {
      "emoji": "🚪",
      "name": "door",
    },
    {
      "emoji": "🛗",
      "name": "elevator",
    },
    {
      "emoji": "🪞",
      "name": "mirror",
    },
    {
      "emoji": "🪟",
      "name": "window",
    },
    {
      "emoji": "🛏️",
      "name": "bed",
    },
    {
      "emoji": "🛋️",
      "name": "couch and lamp",
    },
    {
      "emoji": "🪑",
      "name": "chair",
    },
    {
      "emoji": "🚽",
      "name": "toilet",
    },
    {
      "emoji": "🪠",
      "name": "plunger",
    },
    {
      "emoji": "🚿",
      "name": "shower",
    },
    {
      "emoji": "🛁",
      "name": "bathtub",
    },
    {
      "emoji": "🪤",
      "name": "mouse trap",
    },
    {
      "emoji": "🪒",
      "name": "razor",
    },
    {
      "emoji": "🧴",
      "name": "lotion bottle",
    },
    {
      "emoji": "🧷",
      "name": "safety pin",
    },
    {
      "emoji": "🧹",
      "name": "broom",
    },
    {
      "emoji": "🧺",
      "name": "basket",
    },
    {
      "emoji": "🧻",
      "name": "roll of paper",
    },
    {
      "emoji": "🪣",
      "name": "bucket",
    },
    {
      "emoji": "🧼",
      "name": "soap",
    },
    {
      "emoji": "🪥",
      "name": "toothbrush",
    },
    {
      "emoji": "🧽",
      "name": "sponge",
    },
    {
      "emoji": "🧯",
      "name": "fire extinguisher",
    },
    {
      "emoji": "🛒",
      "name": "shopping cart",
    },
    {
      "emoji": "🚬",
      "name": "cigarette",
    },
    {
      "emoji": "⚰️",
      "name": "coffin",
    },
    {
      "emoji": "🪦",
      "name": "headstone",
    },
    {
      "emoji": "⚱️",
      "name": "funeral urn",
    },
    {
      "emoji": "🗿",
      "name": "moai",
    },
    {
      "emoji": "🪧",
      "name": "placard",
    },
  ],
  "Символы": [
    {
      "emoji": "🏧",
      "name": "ATM sign",
    },
    {
      "emoji": "🚮",
      "name": "litter in bin sign",
    },
    {
      "emoji": "🚰",
      "name": "potable water",
    },
    {
      "emoji": "♿",
      "name": "wheelchair symbol",
    },
    {
      "emoji": "🚹",
      "name": "men’s room",
    },
    {
      "emoji": "🚺",
      "name": "women’s room",
    },
    {
      "emoji": "🚻",
      "name": "restroom",
    },
    {
      "emoji": "🚼",
      "name": "baby symbol",
    },
    {
      "emoji": "🚾",
      "name": "water closet",
    },
    {
      "emoji": "🛂",
      "name": "passport control",
    },
    {
      "emoji": "🛃",
      "name": "customs",
    },
    {
      "emoji": "🛄",
      "name": "baggage claim",
    },
    {
      "emoji": "🛅",
      "name": "left luggage",
    },
    {
      "emoji": "⚠️",
      "name": "warning",
    },
    {
      "emoji": "🚸",
      "name": "children crossing",
    },
    {
      "emoji": "⛔",
      "name": "no entry",
    },
    {
      "emoji": "🚫",
      "name": "prohibited",
    },
    {
      "emoji": "🚳",
      "name": "no bicycles",
    },
    {
      "emoji": "🚭",
      "name": "no smoking",
    },
    {
      "emoji": "🚯",
      "name": "no littering",
    },
    {
      "emoji": "🚱",
      "name": "non-potable water",
    },
    {
      "emoji": "🚷",
      "name": "no pedestrians",
    },
    {
      "emoji": "📵",
      "name": "no mobile phones",
    },
    {
      "emoji": "🔞",
      "name": "no one under eighteen",
    },
    {
      "emoji": "☢️",
      "name": "radioactive",
    },
    {
      "emoji": "☣️",
      "name": "biohazard",
    },
    {
      "emoji": "⬆️",
      "name": "up arrow",
    },
    {
      "emoji": "↗️",
      "name": "up-right arrow",
    },
    {
      "emoji": "➡️",
      "name": "right arrow",
    },
    {
      "emoji": "↘️",
      "name": "down-right arrow",
    },
    {
      "emoji": "⬇️",
      "name": "down arrow",
    },
    {
      "emoji": "↙️",
      "name": "down-left arrow",
    },
    {
      "emoji": "⬅️",
      "name": "left arrow",
    },
    {
      "emoji": "↖️",
      "name": "up-left arrow",
    },
    {
      "emoji": "↕️",
      "name": "up-down arrow",
    },
    {
      "emoji": "↔️",
      "name": "left-right arrow",
    },
    {
      "emoji": "↩️",
      "name": "right arrow curving left",
    },
    {
      "emoji": "↪️",
      "name": "left arrow curving right",
    },
    {
      "emoji": "⤴️",
      "name": "right arrow curving up",
    },
    {
      "emoji": "⤵️",
      "name": "right arrow curving down",
    },
    {
      "emoji": "🔃",
      "name": "clockwise vertical arrows",
    },
    {
      "emoji": "🔄",
      "name": "counterclockwise arrows button",
    },
    {
      "emoji": "🔙",
      "name": "BACK arrow",
    },
    {
      "emoji": "🔚",
      "name": "END arrow",
    },
    {
      "emoji": "🔛",
      "name": "ON! arrow",
    },
    {
      "emoji": "🔜",
      "name": "SOON arrow",
    },
    {
      "emoji": "🔝",
      "name": "TOP arrow",
    },
    {
      "emoji": "🛐",
      "name": "place of worship",
    },
    {
      "emoji": "⚛️",
      "name": "atom symbol",
    },
    {
      "emoji": "🕉️",
      "name": "om",
    },
    {
      "emoji": "✡️",
      "name": "star of David",
    },
    {
      "emoji": "☸️",
      "name": "wheel of dharma",
    },
    {
      "emoji": "☯️",
      "name": "yin yang",
    },
    {
      "emoji": "✝️",
      "name": "latin cross",
    },
    {
      "emoji": "☦️",
      "name": "orthodox cross",
    },
    {
      "emoji": "☪️",
      "name": "star and crescent",
    },
    {
      "emoji": "☮️",
      "name": "peace symbol",
    },
    {
      "emoji": "🕎",
      "name": "menorah",
    },
    {
      "emoji": "🔯",
      "name": "dotted six-pointed star",
    },
    {
      "emoji": "♈",
      "name": "Aries",
    },
    {
      "emoji": "♉",
      "name": "Taurus",
    },
    {
      "emoji": "♊",
      "name": "Gemini",
    },
    {
      "emoji": "♋",
      "name": "Cancer",
    },
    {
      "emoji": "♌",
      "name": "Leo",
    },
    {
      "emoji": "♍",
      "name": "Virgo",
    },
    {
      "emoji": "♎",
      "name": "Libra",
    },
    {
      "emoji": "♏",
      "name": "Scorpio",
    },
    {
      "emoji": "♐",
      "name": "Sagittarius",
    },
    {
      "emoji": "♑",
      "name": "Capricorn",
    },
    {
      "emoji": "♒",
      "name": "Aquarius",
    },
    {
      "emoji": "♓",
      "name": "Pisces",
    },
    {
      "emoji": "⛎",
      "name": "Ophiuchus",
    },
    {
      "emoji": "🔀",
      "name": "shuffle tracks button",
    },
    {
      "emoji": "🔁",
      "name": "repeat button",
    },
    {
      "emoji": "🔂",
      "name": "repeat single button",
    },
    {
      "emoji": "▶️",
      "name": "play button",
    },
    {
      "emoji": "⏩",
      "name": "fast-forward button",
    },
    {
      "emoji": "⏭️",
      "name": "next track button",
    },
    {
      "emoji": "⏯️",
      "name": "play or pause button",
    },
    {
      "emoji": "◀️",
      "name": "reverse button",
    },
    {
      "emoji": "⏪",
      "name": "fast reverse button",
    },
    {
      "emoji": "⏮️",
      "name": "last track button",
    },
    {
      "emoji": "🔼",
      "name": "upwards button",
    },
    {
      "emoji": "⏫",
      "name": "fast up button",
    },
    {
      "emoji": "🔽",
      "name": "downwards button",
    },
    {
      "emoji": "⏬",
      "name": "fast down button",
    },
    {
      "emoji": "⏸️",
      "name": "pause button",
    },
    {
      "emoji": "⏹️",
      "name": "stop button",
    },
    {
      "emoji": "⏺️",
      "name": "record button",
    },
    {
      "emoji": "⏏️",
      "name": "eject button",
    },
    {
      "emoji": "🎦",
      "name": "cinema",
    },
    {
      "emoji": "🔅",
      "name": "dim button",
    },
    {
      "emoji": "🔆",
      "name": "bright button",
    },
    {
      "emoji": "📶",
      "name": "antenna bars",
    },
    {
      "emoji": "📳",
      "name": "vibration mode",
    },
    {
      "emoji": "📴",
      "name": "mobile phone off",
    },
    {
      "emoji": "♀️",
      "name": "female sign",
    },
    {
      "emoji": "♂️",
      "name": "male sign",
    },
    {
      "emoji": "⚧️",
      "name": "transgender symbol",
    },
    {
      "emoji": "✖️",
      "name": "multiply",
    },
    {
      "emoji": "➕",
      "name": "plus",
    },
    {
      "emoji": "➖",
      "name": "minus",
    },
    {
      "emoji": "➗",
      "name": "divide",
    },
    {
      "emoji": "♾️",
      "name": "infinity",
    },
    {
      "emoji": "‼️",
      "name": "double exclamation mark",
    },
    {
      "emoji": "⁉️",
      "name": "exclamation question mark",
    },
    {
      "emoji": "❓",
      "name": "red question mark",
    },
    {
      "emoji": "❔",
      "name": "white question mark",
    },
    {
      "emoji": "❕",
      "name": "white exclamation mark",
    },
    {
      "emoji": "❗",
      "name": "red exclamation mark",
    },
    {
      "emoji": "〰️",
      "name": "wavy dash",
    },
    {
      "emoji": "💱",
      "name": "currency exchange",
    },
    {
      "emoji": "💲",
      "name": "heavy dollar sign",
    },
    {
      "emoji": "⚕️",
      "name": "medical symbol",
    },
    {
      "emoji": "♻️",
      "name": "recycling symbol",
    },
    {
      "emoji": "⚜️",
      "name": "fleur-de-lis",
    },
    {
      "emoji": "🔱",
      "name": "trident emblem",
    },
    {
      "emoji": "📛",
      "name": "name badge",
    },
    {
      "emoji": "🔰",
      "name": "Japanese symbol for beginner",
    },
    {
      "emoji": "⭕",
      "name": "hollow red circle",
    },
    {
      "emoji": "✅",
      "name": "check mark button",
    },
    {
      "emoji": "☑️",
      "name": "check box with check",
    },
    {
      "emoji": "✔️",
      "name": "check mark",
    },
    {
      "emoji": "❌",
      "name": "cross mark",
    },
    {
      "emoji": "❎",
      "name": "cross mark button",
    },
    {
      "emoji": "➰",
      "name": "curly loop",
    },
    {
      "emoji": "➿",
      "name": "double curly loop",
    },
    {
      "emoji": "〽️",
      "name": "part alternation mark",
    },
    {
      "emoji": "✳️",
      "name": "eight-spoked asterisk",
    },
    {
      "emoji": "✴️",
      "name": "eight-pointed star",
    },
    {
      "emoji": "❇️",
      "name": "sparkle",
    },
    {
      "emoji": "©️",
      "name": "copyright",
    },
    {
      "emoji": "®️",
      "name": "registered",
    },
    {
      "emoji": "™️",
      "name": "trade mark",
    },
    {
      "emoji": "#️⃣",
      "name": "keycap #",
    },
    {
      "emoji": "*️⃣",
      "name": "keycap *",
    },
    {
      "emoji": "0️⃣",
      "name": "keycap 0",
    },
    {
      "emoji": "1️⃣",
      "name": "keycap 1",
    },
    {
      "emoji": "2️⃣",
      "name": "keycap 2",
    },
    {
      "emoji": "3️⃣",
      "name": "keycap 3",
    },
    {
      "emoji": "4️⃣",
      "name": "keycap 4",
    },
    {
      "emoji": "5️⃣",
      "name": "keycap 5",
    },
    {
      "emoji": "6️⃣",
      "name": "keycap 6",
    },
    {
      "emoji": "7️⃣",
      "name": "keycap 7",
    },
    {
      "emoji": "8️⃣",
      "name": "keycap 8",
    },
    {
      "emoji": "9️⃣",
      "name": "keycap 9",
    },
    {
      "emoji": "🔟",
      "name": "keycap 10",
    },
    {
      "emoji": "🔠",
      "name": "input latin uppercase",
    },
    {
      "emoji": "🔡",
      "name": "input latin lowercase",
    },
    {
      "emoji": "🔢",
      "name": "input numbers",
    },
    {
      "emoji": "🔣",
      "name": "input symbols",
    },
    {
      "emoji": "🔤",
      "name": "input latin letters",
    },
    {
      "emoji": "🅰️",
      "name": "A button (blood type)",
    },
    {
      "emoji": "🆎",
      "name": "AB button (blood type)",
    },
    {
      "emoji": "🅱️",
      "name": "B button (blood type)",
    },
    {
      "emoji": "🆑",
      "name": "CL button",
    },
    {
      "emoji": "🆒",
      "name": "COOL button",
    },
    {
      "emoji": "🆓",
      "name": "FREE button",
    },
    {
      "emoji": "ℹ️",
      "name": "information",
    },
    {
      "emoji": "🆔",
      "name": "ID button",
    },
    {
      "emoji": "Ⓜ️",
      "name": "circled M",
    },
    {
      "emoji": "🆕",
      "name": "NEW button",
    },
    {
      "emoji": "🆖",
      "name": "NG button",
    },
    {
      "emoji": "🅾️",
      "name": "O button (blood type)",
    },
    {
      "emoji": "🆗",
      "name": "OK button",
    },
    {
      "emoji": "🅿️",
      "name": "P button",
    },
    {
      "emoji": "🆘",
      "name": "SOS button",
    },
    {
      "emoji": "🆙",
      "name": "UP! button",
    },
    {
      "emoji": "🆚",
      "name": "VS button",
    },
    {
      "emoji": "🈁",
      "name": "Japanese “here” button",
    },
    {
      "emoji": "🈂️",
      "name": "Japanese “service charge” button",
    },
    {
      "emoji": "🈷️",
      "name": "Japanese “monthly amount” button",
    },
    {
      "emoji": "🈶",
      "name": "Japanese “not free of charge” button",
    },
    {
      "emoji": "🈯",
      "name": "Japanese “reserved” button",
    },
    {
      "emoji": "🉐",
      "name": "Japanese “bargain” button",
    },
    {
      "emoji": "🈹",
      "name": "Japanese “discount” button",
    },
    {
      "emoji": "🈚",
      "name": "Japanese “free of charge” button",
    },
    {
      "emoji": "🈲",
      "name": "Japanese “prohibited” button",
    },
    {
      "emoji": "🉑",
      "name": "Japanese “acceptable” button",
    },
    {
      "emoji": "🈸",
      "name": "Japanese “application” button",
    },
    {
      "emoji": "🈴",
      "name": "Japanese “passing grade” button",
    },
    {
      "emoji": "🈳",
      "name": "Japanese “vacancy” button",
    },
    {
      "emoji": "㊗️",
      "name": "Japanese “congratulations” button",
    },
    {
      "emoji": "㊙️",
      "name": "Japanese “secret” button",
    },
    {
      "emoji": "🈺",
      "name": "Japanese “open for business” button",
    },
    {
      "emoji": "🈵",
      "name": "Japanese “no vacancy” button",
    },
    {
      "emoji": "🔴",
      "name": "red circle",
    },
    {
      "emoji": "🟠",
      "name": "orange circle",
    },
    {
      "emoji": "🟡",
      "name": "yellow circle",
    },
    {
      "emoji": "🟢",
      "name": "green circle",
    },
    {
      "emoji": "🔵",
      "name": "blue circle",
    },
    {
      "emoji": "🟣",
      "name": "purple circle",
    },
    {
      "emoji": "🟤",
      "name": "brown circle",
    },
    {
      "emoji": "⚫",
      "name": "black circle",
    },
    {
      "emoji": "⚪",
      "name": "white circle",
    },
    {
      "emoji": "🟥",
      "name": "red square",
    },
    {
      "emoji": "🟧",
      "name": "orange square",
    },
    {
      "emoji": "🟨",
      "name": "yellow square",
    },
    {
      "emoji": "🟩",
      "name": "green square",
    },
    {
      "emoji": "🟦",
      "name": "blue square",
    },
    {
      "emoji": "🟪",
      "name": "purple square",
    },
    {
      "emoji": "🟫",
      "name": "brown square",
    },
    {
      "emoji": "⬛",
      "name": "black large square",
    },
    {
      "emoji": "⬜",
      "name": "white large square",
    },
    {
      "emoji": "◼️",
      "name": "black medium square",
    },
    {
      "emoji": "◻️",
      "name": "white medium square",
    },
    {
      "emoji": "◾",
      "name": "black medium-small square",
    },
    {
      "emoji": "◽",
      "name": "white medium-small square",
    },
    {
      "emoji": "▪️",
      "name": "black small square",
    },
    {
      "emoji": "▫️",
      "name": "white small square",
    },
    {
      "emoji": "🔶",
      "name": "large orange diamond",
    },
    {
      "emoji": "🔷",
      "name": "large blue diamond",
    },
    {
      "emoji": "🔸",
      "name": "small orange diamond",
    },
    {
      "emoji": "🔹",
      "name": "small blue diamond",
    },
    {
      "emoji": "🔺",
      "name": "red triangle pointed up",
    },
    {
      "emoji": "🔻",
      "name": "red triangle pointed down",
    },
    {
      "emoji": "💠",
      "name": "diamond with a dot",
    },
    {
      "emoji": "🔘",
      "name": "radio button",
    },
    {
      "emoji": "🔳",
      "name": "white square button",
    },
    {
      "emoji": "🔲",
      "name": "black square button",
    }
  ],
  "Флаги": [
    {
      "emoji": "🏁",
      "name": "chequered flag",
    },
    {
      "emoji": "🚩",
      "name": "triangular flag",
    },
    {
      "emoji": "🎌",
      "name": "crossed flags",
    },
    {
      "emoji": "🏴",
      "name": "black flag",
    },
    {
      "emoji": "🏳️",
      "name": "white flag",
    },
    {
      "emoji": "🏳️‍🌈",
      "name": "rainbow flag",
    },
    {
      "emoji": "🏳️‍⚧️",
      "name": "transgender flag",
    },
    {
      "emoji": "🏴‍☠️",
      "name": "pirate flag",
    },
    {
      "emoji": "🇦🇨",
      "name": "flag Ascension Island",
    },
    {
      "emoji": "🇦🇩",
      "name": "flag Andorra",
    },
    {
      "emoji": "🇦🇪",
      "name": "flag United Arab Emirates",
    },
    {
      "emoji": "🇦🇫",
      "name": "flag Afghanistan",
    },
    {
      "emoji": "🇦🇬",
      "name": "flag Antigua & Barbuda",
    },
    {
      "emoji": "🇦🇮",
      "name": "flag Anguilla",
    },
    {
      "emoji": "🇦🇱",
      "name": "flag Albania",
    },
    {
      "emoji": "🇦🇲",
      "name": "flag Armenia",
    },
    {
      "emoji": "🇦🇴",
      "name": "flag Angola",
    },
    {
      "emoji": "🇦🇶",
      "name": "flag Antarctica",
    },
    {
      "emoji": "🇦🇷",
      "name": "flag Argentina",
    },
    {
      "emoji": "🇦🇸",
      "name": "flag American Samoa",
    },
    {
      "emoji": "🇦🇹",
      "name": "flag Austria",
    },
    {
      "emoji": "🇦🇺",
      "name": "flag Australia",
    },
    {
      "emoji": "🇦🇼",
      "name": "flag Aruba",
    },
    {
      "emoji": "🇦🇽",
      "name": "flag Åland Islands",
    },
    {
      "emoji": "🇦🇿",
      "name": "flag Azerbaijan",
    },
    {
      "emoji": "🇧🇦",
      "name": "flag Bosnia & Herzegovina",
    },
    {
      "emoji": "🇧🇧",
      "name": "flag Barbados",
    },
    {
      "emoji": "🇧🇩",
      "name": "flag Bangladesh",
    },
    {
      "emoji": "🇧🇪",
      "name": "flag Belgium",
    },
    {
      "emoji": "🇧🇫",
      "name": "flag Burkina Faso",
    },
    {
      "emoji": "🇧🇬",
      "name": "flag Bulgaria",
    },
    {
      "emoji": "🇧🇭",
      "name": "flag Bahrain",
    },
    {
      "emoji": "🇧🇮",
      "name": "flag Burundi",
    },
    {
      "emoji": "🇧🇯",
      "name": "flag Benin",
    },
    {
      "emoji": "🇧🇱",
      "name": "flag St. Barthélemy",
    },
    {
      "emoji": "🇧🇲",
      "name": "flag Bermuda",
    },
    {
      "emoji": "🇧🇳",
      "name": "flag Brunei",
    },
    {
      "emoji": "🇧🇴",
      "name": "flag Bolivia",
    },
    {
      "emoji": "🇧🇶",
      "name": "flag Caribbean Netherlands",
    },
    {
      "emoji": "🇧🇷",
      "name": "flag Brazil",
    },
    {
      "emoji": "🇧🇸",
      "name": "flag Bahamas",
    },
    {
      "emoji": "🇧🇹",
      "name": "flag Bhutan",
    },
    {
      "emoji": "🇧🇻",
      "name": "flag Bouvet Island",
    },
    {
      "emoji": "🇧🇼",
      "name": "flag Botswana",
    },
    {
      "emoji": "🇧🇾",
      "name": "flag Belarus",
    },
    {
      "emoji": "🇧🇿",
      "name": "flag Belize",
    },
    {
      "emoji": "🇨🇦",
      "name": "flag Canada",
    },
    {
      "emoji": "🇨🇨",
      "name": "flag Cocos (Keeling) Islands",
    },
    {
      "emoji": "🇨🇩",
      "name": "flag Congo - Kinshasa",
    },
    {
      "emoji": "🇨🇫",
      "name": "flag Central African Republic",
    },
    {
      "emoji": "🇨🇬",
      "name": "flag Congo - Brazzaville",
    },
    {
      "emoji": "🇨🇭",
      "name": "flag Switzerland",
    },
    {
      "emoji": "🇨🇮",
      "name": "flag Côte d’Ivoire",
    },
    {
      "emoji": "🇨🇰",
      "name": "flag Cook Islands",
    },
    {
      "emoji": "🇨🇱",
      "name": "flag Chile",
    },
    {
      "emoji": "🇨🇲",
      "name": "flag Cameroon",
    },
    {
      "emoji": "🇨🇳",
      "name": "flag China",
    },
    {
      "emoji": "🇨🇴",
      "name": "flag Colombia",
    },
    {
      "emoji": "🇨🇵",
      "name": "flag Clipperton Island",
    },
    {
      "emoji": "🇨🇷",
      "name": "flag Costa Rica",
    },
    {
      "emoji": "🇨🇺",
      "name": "flag Cuba",
    },
    {
      "emoji": "🇨🇻",
      "name": "flag Cape Verde",
    },
    {
      "emoji": "🇨🇼",
      "name": "flag Curaçao",
    },
    {
      "emoji": "🇨🇽",
      "name": "flag Christmas Island",
    },
    {
      "emoji": "🇨🇾",
      "name": "flag Cyprus",
    },
    {
      "emoji": "🇨🇿",
      "name": "flag Czechia",
    },
    {
      "emoji": "🇩🇪",
      "name": "flag Germany",
    },
    {
      "emoji": "🇩🇬",
      "name": "flag Diego Garcia",
    },
    {
      "emoji": "🇩🇯",
      "name": "flag Djibouti",
    },
    {
      "emoji": "🇩🇰",
      "name": "flag Denmark",
    },
    {
      "emoji": "🇩🇲",
      "name": "flag Dominica",
    },
    {
      "emoji": "🇩🇴",
      "name": "flag Dominican Republic",
    },
    {
      "emoji": "🇩🇿",
      "name": "flag Algeria",
    },
    {
      "emoji": "🇪🇦",
      "name": "flag Ceuta & Melilla",
    },
    {
      "emoji": "🇪🇨",
      "name": "flag Ecuador",
    },
    {
      "emoji": "🇪🇪",
      "name": "flag Estonia",
    },
    {
      "emoji": "🇪🇬",
      "name": "flag Egypt",
    },
    {
      "emoji": "🇪🇭",
      "name": "flag Western Sahara",
    },
    {
      "emoji": "🇪🇷",
      "name": "flag Eritrea",
    },
    {
      "emoji": "🇪🇸",
      "name": "flag Spain",
    },
    {
      "emoji": "🇪🇹",
      "name": "flag Ethiopia",
    },
    {
      "emoji": "🇪🇺",
      "name": "flag European Union",
    },
    {
      "emoji": "🇫🇮",
      "name": "flag Finland",
    },
    {
      "emoji": "🇫🇯",
      "name": "flag Fiji",
    },
    {
      "emoji": "🇫🇰",
      "name": "flag Falkland Islands",
    },
    {
      "emoji": "🇫🇲",
      "name": "flag Micronesia",
    },
    {
      "emoji": "🇫🇴",
      "name": "flag Faroe Islands",
    },
    {
      "emoji": "🇫🇷",
      "name": "flag France",
    },
    {
      "emoji": "🇬🇦",
      "name": "flag Gabon",
    },
    {
      "emoji": "🇬🇧",
      "name": "flag United Kingdom",
    },
    {
      "emoji": "🇬🇩",
      "name": "flag Grenada",
    },
    {
      "emoji": "🇬🇪",
      "name": "flag Georgia",
    },
    {
      "emoji": "🇬🇫",
      "name": "flag French Guiana",
    },
    {
      "emoji": "🇬🇬",
      "name": "flag Guernsey",
    },
    {
      "emoji": "🇬🇭",
      "name": "flag Ghana",
    },
    {
      "emoji": "🇬🇮",
      "name": "flag Gibraltar",
    },
    {
      "emoji": "🇬🇱",
      "name": "flag Greenland",
    },
    {
      "emoji": "🇬🇲",
      "name": "flag Gambia",
    },
    {
      "emoji": "🇬🇳",
      "name": "flag Guinea",
    },
    {
      "emoji": "🇬🇵",
      "name": "flag Guadeloupe",
    },
    {
      "emoji": "🇬🇶",
      "name": "flag Equatorial Guinea",
    },
    {
      "emoji": "🇬🇷",
      "name": "flag Greece",
    },
    {
      "emoji": "🇬🇸",
      "name": "flag South Georgia & South Sandwich Islands",
    },
    {
      "emoji": "🇬🇹",
      "name": "flag Guatemala",
    },
    {
      "emoji": "🇬🇺",
      "name": "flag Guam",
    },
    {
      "emoji": "🇬🇼",
      "name": "flag Guinea-Bissau",
    },
    {
      "emoji": "🇬🇾",
      "name": "flag Guyana",
    },
    {
      "emoji": "🇭🇰",
      "name": "flag Hong Kong SAR China",
    },
    {
      "emoji": "🇭🇲",
      "name": "flag Heard & McDonald Islands",
    },
    {
      "emoji": "🇭🇳",
      "name": "flag Honduras",
    },
    {
      "emoji": "🇭🇷",
      "name": "flag Croatia",
    },
    {
      "emoji": "🇭🇹",
      "name": "flag Haiti",
    },
    {
      "emoji": "🇭🇺",
      "name": "flag Hungary",
    },
    {
      "emoji": "🇮🇨",
      "name": "flag Canary Islands",
    },
    {
      "emoji": "🇮🇩",
      "name": "flag Indonesia",
    },
    {
      "emoji": "🇮🇪",
      "name": "flag Ireland",
    },
    {
      "emoji": "🇮🇱",
      "name": "flag Israel",
    },
    {
      "emoji": "🇮🇲",
      "name": "flag Isle of Man",
    },
    {
      "emoji": "🇮🇳",
      "name": "flag India",
    },
    {
      "emoji": "🇮🇴",
      "name": "flag British Indian Ocean Territory",
    },
    {
      "emoji": "🇮🇶",
      "name": "flag Iraq",
    },
    {
      "emoji": "🇮🇷",
      "name": "flag Iran",
    },
    {
      "emoji": "🇮🇸",
      "name": "flag Iceland",
    },
    {
      "emoji": "🇮🇹",
      "name": "flag Italy",
    },
    {
      "emoji": "🇯🇪",
      "name": "flag Jersey",
    },
    {
      "emoji": "🇯🇲",
      "name": "flag Jamaica",
    },
    {
      "emoji": "🇯🇴",
      "name": "flag Jordan",
    },
    {
      "emoji": "🇯🇵",
      "name": "flag Japan",
    },
    {
      "emoji": "🇰🇪",
      "name": "flag Kenya",
    },
    {
      "emoji": "🇰🇬",
      "name": "flag Kyrgyzstan",
    },
    {
      "emoji": "🇰🇭",
      "name": "flag Cambodia",
    },
    {
      "emoji": "🇰🇮",
      "name": "flag Kiribati",
    },
    {
      "emoji": "🇰🇲",
      "name": "flag Comoros",
    },
    {
      "emoji": "🇰🇳",
      "name": "flag St. Kitts & Nevis",
    },
    {
      "emoji": "🇰🇵",
      "name": "flag North Korea",
    },
    {
      "emoji": "🇰🇷",
      "name": "flag South Korea",
    },
    {
      "emoji": "🇰🇼",
      "name": "flag Kuwait",
    },
    {
      "emoji": "🇰🇾",
      "name": "flag Cayman Islands",
    },
    {
      "emoji": "🇰🇿",
      "name": "flag Kazakhstan",
    },
    {
      "emoji": "🇱🇦",
      "name": "flag Laos",
    },
    {
      "emoji": "🇱🇧",
      "name": "flag Lebanon",
    },
    {
      "emoji": "🇱🇨",
      "name": "flag St. Lucia",
    },
    {
      "emoji": "🇱🇮",
      "name": "flag Liechtenstein",
    },
    {
      "emoji": "🇱🇰",
      "name": "flag Sri Lanka",
    },
    {
      "emoji": "🇱🇷",
      "name": "flag Liberia",
    },
    {
      "emoji": "🇱🇸",
      "name": "flag Lesotho",
    },
    {
      "emoji": "🇱🇹",
      "name": "flag Lithuania",
    },
    {
      "emoji": "🇱🇺",
      "name": "flag Luxembourg",
    },
    {
      "emoji": "🇱🇻",
      "name": "flag Latvia",
    },
    {
      "emoji": "🇱🇾",
      "name": "flag Libya",
    },
    {
      "emoji": "🇲🇦",
      "name": "flag Morocco",
    },
    {
      "emoji": "🇲🇨",
      "name": "flag Monaco",
    },
    {
      "emoji": "🇲🇩",
      "name": "flag Moldova",
    },
    {
      "emoji": "🇲🇪",
      "name": "flag Montenegro",
    },
    {
      "emoji": "🇲🇫",
      "name": "flag St. Martin",
    },
    {
      "emoji": "🇲🇬",
      "name": "flag Madagascar",
    },
    {
      "emoji": "🇲🇭",
      "name": "flag Marshall Islands",
    },
    {
      "emoji": "🇲🇰",
      "name": "flag North Macedonia",
    },
    {
      "emoji": "🇲🇱",
      "name": "flag Mali",
    },
    {
      "emoji": "🇲🇲",
      "name": "flag Myanmar (Burma)",
    },
    {
      "emoji": "🇲🇳",
      "name": "flag Mongolia",
    },
    {
      "emoji": "🇲🇴",
      "name": "flag Macao SAR China",
    },
    {
      "emoji": "🇲🇵",
      "name": "flag Northern Mariana Islands",
    },
    {
      "emoji": "🇲🇶",
      "name": "flag Martinique",
    },
    {
      "emoji": "🇲🇷",
      "name": "flag Mauritania",
    },
    {
      "emoji": "🇲🇸",
      "name": "flag Montserrat",
    },
    {
      "emoji": "🇲🇹",
      "name": "flag Malta",
    },
    {
      "emoji": "🇲🇺",
      "name": "flag Mauritius",
    },
    {
      "emoji": "🇲🇻",
      "name": "flag Maldives",
    },
    {
      "emoji": "🇲🇼",
      "name": "flag Malawi",
    },
    {
      "emoji": "🇲🇽",
      "name": "flag Mexico",
    },
    {
      "emoji": "🇲🇾",
      "name": "flag Malaysia",
    },
    {
      "emoji": "🇲🇿",
      "name": "flag Mozambique",
    },
    {
      "emoji": "🇳🇦",
      "name": "flag Namibia",
    },
    {
      "emoji": "🇳🇨",
      "name": "flag New Caledonia",
    },
    {
      "emoji": "🇳🇪",
      "name": "flag Niger",
    },
    {
      "emoji": "🇳🇫",
      "name": "flag Norfolk Island",
    },
    {
      "emoji": "🇳🇬",
      "name": "flag Nigeria",
    },
    {
      "emoji": "🇳🇮",
      "name": "flag Nicaragua",
    },
    {
      "emoji": "🇳🇱",
      "name": "flag Netherlands",
    },
    {
      "emoji": "🇳🇴",
      "name": "flag Norway",
    },
    {
      "emoji": "🇳🇵",
      "name": "flag Nepal",
    },
    {
      "emoji": "🇳🇷",
      "name": "flag Nauru",
    },
    {
      "emoji": "🇳🇺",
      "name": "flag Niue",
    },
    {
      "emoji": "🇳🇿",
      "name": "flag New Zealand",
    },
    {
      "emoji": "🇴🇲",
      "name": "flag Oman",
    },
    {
      "emoji": "🇵🇦",
      "name": "flag Panama",
    },
    {
      "emoji": "🇵🇪",
      "name": "flag Peru",
    },
    {
      "emoji": "🇵🇫",
      "name": "flag French Polynesia",
    },
    {
      "emoji": "🇵🇬",
      "name": "flag Papua New Guinea",
    },
    {
      "emoji": "🇵🇭",
      "name": "flag Philippines",
    },
    {
      "emoji": "🇵🇰",
      "name": "flag Pakistan",
    },
    {
      "emoji": "🇵🇱",
      "name": "flag Poland",
    },
    {
      "emoji": "🇵🇲",
      "name": "flag St. Pierre & Miquelon",
    },
    {
      "emoji": "🇵🇳",
      "name": "flag Pitcairn Islands",
    },
    {
      "emoji": "🇵🇷",
      "name": "flag Puerto Rico",
    },
    {
      "emoji": "🇵🇸",
      "name": "flag Palestinian Territories",
    },
    {
      "emoji": "🇵🇹",
      "name": "flag Portugal",
    },
    {
      "emoji": "🇵🇼",
      "name": "flag Palau",
    },
    {
      "emoji": "🇵🇾",
      "name": "flag Paraguay",
    },
    {
      "emoji": "🇶🇦",
      "name": "flag Qatar",
    },
    {
      "emoji": "🇷🇪",
      "name": "flag Réunion",
    },
    {
      "emoji": "🇷🇴",
      "name": "flag Romania",
    },
    {
      "emoji": "🇷🇸",
      "name": "flag Serbia",
    },
    {
      "emoji": "🇷🇺",
      "name": "flag Russia",
    },
    {
      "emoji": "🇷🇼",
      "name": "flag Rwanda",
    },
    {
      "emoji": "🇸🇦",
      "name": "flag Saudi Arabia",
    },
    {
      "emoji": "🇸🇧",
      "name": "flag Solomon Islands",
    },
    {
      "emoji": "🇸🇨",
      "name": "flag Seychelles",
    },
    {
      "emoji": "🇸🇩",
      "name": "flag Sudan",
    },
    {
      "emoji": "🇸🇪",
      "name": "flag Sweden",
    },
    {
      "emoji": "🇸🇬",
      "name": "flag Singapore",
    },
    {
      "emoji": "🇸🇭",
      "name": "flag St. Helena",
    },
    {
      "emoji": "🇸🇮",
      "name": "flag Slovenia",
    },
    {
      "emoji": "🇸🇯",
      "name": "flag Svalbard & Jan Mayen",
    },
    {
      "emoji": "🇸🇰",
      "name": "flag Slovakia",
    },
    {
      "emoji": "🇸🇱",
      "name": "flag Sierra Leone",
    },
    {
      "emoji": "🇸🇲",
      "name": "flag San Marino",
    },
    {
      "emoji": "🇸🇳",
      "name": "flag Senegal",
    },
    {
      "emoji": "🇸🇴",
      "name": "flag Somalia",
    },
    {
      "emoji": "🇸🇷",
      "name": "flag Suriname",
    },
    {
      "emoji": "🇸🇸",
      "name": "flag South Sudan",
    },
    {
      "emoji": "🇸🇹",
      "name": "flag São Tomé & Príncipe",
    },
    {
      "emoji": "🇸🇻",
      "name": "flag El Salvador",
    },
    {
      "emoji": "🇸🇽",
      "name": "flag Sint Maarten",
    },
    {
      "emoji": "🇸🇾",
      "name": "flag Syria",
    },
    {
      "emoji": "🇸🇿",
      "name": "flag Eswatini",
    },
    {
      "emoji": "🇹🇦",
      "name": "flag Tristan da Cunha",
    },
    {
      "emoji": "🇹🇨",
      "name": "flag Turks & Caicos Islands",
    },
    {
      "emoji": "🇹🇩",
      "name": "flag Chad",
    },
    {
      "emoji": "🇹🇫",
      "name": "flag French Southern Territories",
    },
    {
      "emoji": "🇹🇬",
      "name": "flag Togo",
    },
    {
      "emoji": "🇹🇭",
      "name": "flag Thailand",
    },
    {
      "emoji": "🇹🇯",
      "name": "flag Tajikistan",
    },
    {
      "emoji": "🇹🇰",
      "name": "flag Tokelau",
    },
    {
      "emoji": "🇹🇱",
      "name": "flag Timor-Leste",
    },
    {
      "emoji": "🇹🇲",
      "name": "flag Turkmenistan",
    },
    {
      "emoji": "🇹🇳",
      "name": "flag Tunisia",
    },
    {
      "emoji": "🇹🇴",
      "name": "flag Tonga",
    },
    {
      "emoji": "🇹🇷",
      "name": "flag Turkey",
    },
    {
      "emoji": "🇹🇹",
      "name": "flag Trinidad & Tobago",
    },
    {
      "emoji": "🇹🇻",
      "name": "flag Tuvalu",
    },
    {
      "emoji": "🇹🇼",
      "name": "flag Taiwan",
    },
    {
      "emoji": "🇹🇿",
      "name": "flag Tanzania",
    },
    {
      "emoji": "🇺🇦",
      "name": "flag Ukraine",
    },
    {
      "emoji": "🇺🇬",
      "name": "flag Uganda",
    },
    {
      "emoji": "🇺🇲",
      "name": "flag U.S. Outlying Islands",
    },
    {
      "emoji": "🇺🇳",
      "name": "flag United Nations",
    },
    {
      "emoji": "🇺🇸",
      "name": "flag United States",
    },
    {
      "emoji": "🇺🇾",
      "name": "flag Uruguay",
    },
    {
      "emoji": "🇺🇿",
      "name": "flag Uzbekistan",
    },
    {
      "emoji": "🇻🇦",
      "name": "flag Vatican City",
    },
    {
      "emoji": "🇻🇨",
      "name": "flag St. Vincent & Grenadines",
    },
    {
      "emoji": "🇻🇪",
      "name": "flag Venezuela",
    },
    {
      "emoji": "🇻🇬",
      "name": "flag British Virgin Islands",
    },
    {
      "emoji": "🇻🇮",
      "name": "flag U.S. Virgin Islands",
    },
    {
      "emoji": "🇻🇳",
      "name": "flag Vietnam",
    },
    {
      "emoji": "🇻🇺",
      "name": "flag Vanuatu",
    },
    {
      "emoji": "🇼🇫",
      "name": "flag Wallis & Futuna",
    },
    {
      "emoji": "🇼🇸",
      "name": "flag Samoa",
    },
    {
      "emoji": "🇽🇰",
      "name": "flag Kosovo",
    },
    {
      "emoji": "🇾🇪",
      "name": "flag Yemen",
    },
    {
      "emoji": "🇾🇹",
      "name": "flag Mayotte",
    },
    {
      "emoji": "🇿🇦",
      "name": "flag South Africa",
    },
    {
      "emoji": "🇿🇲",
      "name": "flag Zambia",
    },
    {
      "emoji": "🇿🇼",
      "name": "flag Zimbabwe",
    },
    {
      "emoji": "🏴󠁧󠁢󠁥󠁮󠁧󠁿",
      "name": "flag England",
    },
    {
      "emoji": "🏴󠁧󠁢󠁳󠁣󠁴󠁿",
      "name": "flag Scotland",
    },
    {
      "emoji": "🏴󠁧󠁢󠁷󠁬󠁳󠁿",
      "name": "flag Wales",
    }
  ]
}