import axios from 'axios';

const state = () => {
    return {
        form: {
            id: '',
            name: '',
            sound: null,
            pipeline_settings: [],
            pipeline_statuses: [],
            path_to_directory: null,
            users: [],
            miniature: null,
            miniature_path: null,
        },
        del_statuses: [],
    };
};

// getters
const getters = {};

// actions
const actions = {
    async EditFunnel({ state }, id) {
        let { data } = await axios.get(`pipeline/${id}/edit`);

        data.data.pipeline_statuses.forEach((el) => {
            if (!el.typeFunnelStatus) {
                el.typeFunnelStatus = {
                    id: 1,
                    name: 'активный статус',
                };
            }
        });
        let funnelData = data.data;

        for (const field in state.form) {
            state.form[field] = funnelData[field];
        }
        state.form.path_to_directory = funnelData.path_to_directory;
        state.form.users = funnelData.users;

        state.form.miniature = data.data.miniature;
        state.form.miniature_path = data.data.miniature;
        state.form.sound = data.data.sound;
    },

    async addNewStatus({ state }, id) {
        let data = {
            pipeline_id: id,
            name: 'Новый статус',
            color: 'rgba(223, 90, 82, 1)',
            typeFunnelStatus: 'активный статус',
            newEl: true,
        };
        let status = await axios.post('/pipeline-status', data);

        state.form.pipeline_statuses.push(status.data.data);
    },
};

// mutations
const mutations = {
    setNewStatus(state, data) {
        state.form.pipeline_statuses.find((el) => el.id === data.funnelStatus_id).id = data.new_status_id;
    },

    addNewStatus(state,data){
      state.form.pipeline_statuses.push(data);
    },

    addStatus(state, data) {
        state.form.pipeline_statuses.push(data);
    },

    CreateNewStatus(state) {
        let funnel_statuses = state.form.pipeline_statuses;
        let id = state.form.pipeline_statuses.length * 999;

        funnel_statuses.push({
            id: id,
            index: 1,
            type_id: 1,
            name: 'Новый список',
            color: 'rgba(223, 90, 82, 1)',
            type: 'Выберите тип',
            newEl: true,
        });
    },
    changeType(state, data) {},

    clearForm(state) {
        state.form = {
            id: '',
            name: '',
            pipeline_settings: [],
            pipeline_statuses: [],
        };
        state.del_statuses = [];
    },

    deleteStatus(state, id) {
        let funnel_statuses = state.form.pipeline_statuses;
        let delEl;
        state.form.pipeline_statuses = funnel_statuses.filter((el) => {
            if (el.id != id) {
                return el;
            } else {
                delEl = el;
            }
        });

        if (!delEl.newEl) {
            state.del_statuses.push(delEl);
            return delEl;
        }

        return null;
    },
    delStatus(state) {
        state.del_statuses = [];
    },

    updateField(state, data) {
        let id = data.idx;
        let field = data.field;
        let value = data.value;
        let idx = state.form.pipeline_statuses.findIndex((el) => el.id == id);

        state.form.pipeline_statuses[idx][field] = value;
    },
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
};
