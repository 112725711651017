<template>
    <div>
        <div class="container sm:px-10">
            <div class="block xl:grid grid-cols-2 gap-4">
                <div class="hidden xl:flex flex-col min-h-screen">
                    <router-link to="/" class="-intro-x flex items-center pt-5">
                        <img alt="logo" class="w-6" :src="require(`@/assets/images/logo.svg`)" />
                    </router-link>

                    <div class="my-auto">
                        <img
                            alt="intro"
                            class="-intro-x w-1/2 -mt-16"
                            :src="require(`@/assets/images/illustration.svg`)"
                        />
                    </div>
                </div>

                <div class="h-screen xl:h-auto flex py-5 xl:py-0 my-10 xl:my-0">
                    <form
                        method="post"
                        class="my-auto mx-auto xl:ml-20 bg-white dark:bg-dark-1 xl:bg-transparent px-5 sm:px-8 py-8 xl:p-0 rounded-md shadow-md xl:shadow-none w-full sm:w-3/4 lg:w-2/4 xl:w-auto"
                        @submit.prevent="onLoginSubmit"
                    >
                        <h2 class="intro-x font-bold text-2xl xl:text-3xl text-center xl:text-left">
                            Sign In
                        </h2>
                        <div class="intro-x mt-8 pb-2">
                            <input
                                type="text"
                                class="intro-x login__input form-control py-3 px-4 border-gray-300 block"
                                placeholder="Email"
                                autocomplete="off"
                                v-model="email"
                            />
                            <input
                                type="password"
                                class="intro-x login__input form-control py-3 px-4 border-gray-300 block mt-4"
                                placeholder="Password"
                                autocomplete="off"
                                v-model="password"
                            />
                        </div>
                        <div v-if="errorMessage" class="bg-red-500 text-white rounded-md px-3 py-3">
                            {{ errorMessage }}
                        </div>
                        <div class="intro-x mt-2 xl:mt-3 text-center xl:text-left">
                            <button type="submit" class="btn btn-primary py-3 px-4 w-full xl:w-32 xl:mr-3 align-top">
                                Login
                            </button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { ref, onMounted } from 'vue';
import { mapActions, mapGetters } from 'vuex';

export default {
    setup() {
        const email = ref('');
        const password = ref('');
        const errorMessage = ref('');

        onMounted(() => {
            cash('body')
                .removeClass('main')
                .removeClass('error-page')
                .addClass('login');
        });

        return {
            email,
            password,
            errorMessage,
        };
    },
    computed: {
        ...mapGetters({ getUser: 'user/getUser' }),
    },
    methods: {
        ...mapActions({ login: 'user/login' }),
        async onLoginSubmit() {
            try {
                this.errorMessage = null;

                const formData = {
                    email: this.email,
                    password: this.password,
                };

                if (localStorage.access_token) {
                    localStorage.clear();
                }

                const errorMessage = await this.login(formData);

                if (errorMessage) {
                    this.errorMessage = errorMessage;
                }

                await this.$router.push('/');
            } catch (e) {
                console.log('cant login: ' + e);
            }
        },
    },
};
</script>
