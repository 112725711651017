import emojis from './data-by-group';

const state = () => ({ emojis });

const getters = {
    getEmojis(state) {
        return state.emojis;
    },
};

export default {
    namespaced: true,
    state,
    getters,
};