import Velocity from 'velocity-animate';

// Setup side menu
const findActiveMenu = (subMenu, route) => {
    let match = false;

    subMenu.forEach((item) => {
        if (item.pageName == 'funnel' && route.name == 'dealEdit' && !item.ignore) {
            match = true;
        }
        if (item.pageName === route.name && !item.ignore) {
            match = true;
        } else if (!match && item.subMenu) {
            match = findActiveMenu(item.subMenu, route);
        }
    });
    return match;
};

const nestedMenu = (menu, route, userRoles = null) => {
    menu.forEach((item, key) => {
        if (typeof item !== 'string') {
            let menuItem = menu[key];
            if (item.path) {
                menuItem.active =
                    (item.path === route.path || (item.subMenu && findActiveMenu(item.subMenu, route))) && !item.ignore;
            } else if (item.childName) {
                menuItem.active =
                    item.childName.includes(route.name) ||
                    ((item.pageName === route.name || (item.subMenu && findActiveMenu(item.subMenu, route))) &&
                        !item.ignore);
            } else {
                menuItem.active =
                    (item.pageName === route.name || (item.subMenu && findActiveMenu(item.subMenu, route))) &&
                    !item.ignore;
            }
            if (item.subMenu) {
                menuItem.activeDropdown = findActiveMenu(item.subMenu, route);
                menuItem = {
                    ...item,
                    ...nestedMenu(item.subMenu, route),
                };
            }
        }
    });

    return menu;
};

const linkTo = (menu, router, event) => {
    if (menu.subMenu) {
        menu.activeDropdown = !menu.activeDropdown;
    } else {
        event.preventDefault();
        router.push({
            name: menu.pageName,
            params: menu.params,
        });
    }
};

const enter = (el, done) => {
    Velocity(el, 'slideDown', { duration: 300 }, { complete: done });
};

const leave = (el, done) => {
    Velocity(el, 'slideUp', { duration: 300 }, { complete: done });
};

export { nestedMenu, linkTo, enter, leave };
