<template>
    <div>
        <div class="user-top-bar">
            <ListOfUsers :users="users" />
        </div>
    </div>
</template>

<script>
import ListOfUsers from '@/components/users/ListOfUsers.vue';

export default {
    components: { ListOfUsers },
    props: {
        pipeline_id: {
            require: true,
        },
    },
    async created() {
        await this.fetchData();
        window.Echo.private('crm')
            .listen('AddedUserPipelineEvent', async (event) => {
                if (event.data.pipeline.id == this.pipeline_id) {
                    await this.fetchData();
                }
            })
            .listen('DetachedUserPipelineEvent', async (event) => {
                if (event.data.pipeline.id == this.pipeline_id) {
                    await this.fetchData();
                }
            });
    },
    watch: {
        async pipeline_id(value) {
            const { data } = await this.axios.get(`/users?pipeline_id=${value}`);

            this.users = data.data;
        },
    },
    data() {
        return {
            users: [],
        };
    },
    methods: {
        async fetchData() {
            const { data } = await this.axios.get(`/users?pipeline_id=${this.pipeline_id}`);

            this.users = data.data;
        },
    },
    beforeRouteUpdate(to, from, next) {
        console.log('ffff');
        console.log(from);
        next();
    },
};
</script>

<style scoped></style>