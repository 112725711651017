export const usersMixin = {
  data() {
    return {
      loading: false,
      form: {
        delete_avatar: false,
        additional_phone: null,
        name: null,
        email: null,
        avatar: null,
        position: null,
        password: null,
        permissions: [],
        roles: [],
        phone: null,
        avatarPath: null,
        subscription_notify: [],

      },
      permissions_active: [],
      permissions: [],
      roles_active: [],
      roles: [],
    };
  },
  methods: {
    clearForm() {
      for (const key in this.form) {
        this.form[key] = null;
      }
    },
    add_role(item, id) {
      if (item.checked) {
        let role = this.roles.find((item) => item.id == id);
        let permissions_active = this.permissions_active;

        for (const permission of role.permissions) {
          if (!permissions_active.includes(permission.name)) {
            this.permissions_active.push(permission.name);
          }
        }
      }
    },
    async fetchCreateUser() {
      try {
        const {data} = await this.axios.get('/users/create');
        this.roles = data.role;
        this.permissions = data.permissions;

      } catch (ex) {
        console.log('cant fetch users: ' + ex);
      }
    },
    async fetchUser(id = null) {
      let userId;
      if (id) {
        userId = id;
      } else {
        userId = this.$route.params.id;
      }
      this.loading = true

      try {
        const {data} = await this.axios.get(`/users/${userId}/edit`);
        for (const field in this.form) {
          this.form[field] = data.user[field];
        }
        this.roles = data.role;
        this.form.roles = data.user.role_title;
        this.form.permissions = data.user.userPermissions;
        this.permissions = data.permission;
        this.form.subscription_notify = data.user.subscriptions;

        this.loading = false;
      } catch (ex) {
        console.log('cant find user: ' + ex);
        this.loading = false;
        await this.$router.push('/error-page');
      }
    },
  }
};
