import axios from 'axios';
import { router } from '../main';

function validate(data, type) {
  switch (type) {
    case 'email':
      const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      return re.test(data);
    case 'password':
      return true
  }
  return false
}

async function login({state, commit, getters}, payload) {
  const payloadKeys = Object.keys(payload);
  payloadKeys.forEach(key => {
    validate(payload[key], key)
  });
  const user = await axios.post('/login', {
    ...payload
  });
  if (!user.data.token) throw new Error('user not found');
  return user.data;
}

async function verifyToken({ commit }) {
  const user = await axios.get('/profile');

  if (user) {
    return user.data.data;
  } else {
    commit('clearTokens');
    throw new Error('response without user data');
  }
}

async function logout(commit) {
  // await axios.post('/logout'); todo не работает
  commit('setUserData', {
    id: null,
    is_blocked: false,
    role_title: null,
    isLogged: false,
    name: null,
    surname: null,
    email: null,
    image: null,
    role: null,
    _refresh: null,
    _token: null
  });
  commit('clearTokens');
}


export default {
  login,
  verifyToken,
  logout,
}
