import axios from "axios";

const state = () => ({
  firstUsers: [],
  users: new Map(),
});

const getters = {
  getFirstUsers(state) {
    return state.firstUsers;
  },

  getUsers(state) {
    return (payload) => {
      return [...state.users.values()]
        .filter(user => user.name.toLowerCase().includes(payload.toLowerCase()));
    };
  },
};

const mutations = {
  setFirstUsers(state, payload) {
    state.firstUsers = payload;
    console.log(state.firstUsers);
  },

  setUsers(state, payload) {
    state.users = payload;
  },

  addUsers(state, payload) {
    payload
      .filter(user => !state.users.has(user.id))
      .forEach(user => state.users.set(user.id, user));
  },
};

const actions = {
  async fetchFirstUsers({ commit },pipeline_id = null) {
    try {
      //console.log('fetching users')
      const { data } = await axios.post(`searchUser?pipeline_id=${ pipeline_id ?? null}`, { word: '' });
      commit('setFirstUsers', data.data);
    } catch(ex) {
      console.error('Cannot fetch first users', ex);
    }
  },

  async fetchUsers({ commit, getters }, payload) {
    try {
      const { data } = await axios.post(`searchUser?pipeline_id=${ payload.pipeline_id ?? null }`,{ word: payload.word });
      commit('addUsers', data.data);
    } catch(ex) {
      console.error('Cannot fetch users by word: ' + payload.word, ex);
    }
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
