import { createStore } from 'vuex'
import main from './main'
import sideMenu from './side-menu'
import simpleMenu from './simple-menu'
import topMenu from './top-menu'
import user from './user'
import ship from './ship'
import funnel from './funnel';
import modalMedia from "./modal-media";
import searchUser from "./searchUser";
import emojis from "./emojis";
import loaderInbox from "@/store/loader.inbox";


const store = createStore({
  modules: {
    main,
    sideMenu,
    simpleMenu,
    topMenu,
    user,
    funnel,
    modalMedia,
    ship,
    searchUser,
    emojis,
    loaderInbox,
  },
  plugins: [
    // createPersistedState()
  ]
});

export function useStore() {
  return store;
}

export default store;
