import Highlight from './highlight/Main.vue';
import Tippy from './tippy/Main.vue';
import TippyContent from './tippy-content/Main.vue';
import CKEditor from './ckeditor/Main.vue';
import * as featherIcons from '@zhuowenli/vue-feather-icons';
import BaseCustomIcon from '@/components/base/BaseCustomIcon';
import TaskIcon from '@/components/base/icons/TaskIcon';
import PaidIcon from '@/components/base/icons/PaidIcon';
import NotPaidIcon from '@/components/base/icons/NotPaidIcon';
import TextDescriptionIcon from '@/components/base/icons/TextDescriptionIcon';
import CommentIcon from '@/components/base/icons/CommentIcon';
import MoveFolderIcon from '@/components/base/icons/MoveFolderIcon.vue';
import QrCodeIcon from '@/components/base/icons/QrCodeIcon.vue';
export default (app) => {
    // app.component('Chart', Chart);
    // app.component('GoogleMapLoader', GoogleMapLoader);
    app.component('Highlight', Highlight);
    app.component('Tippy', Tippy);
    app.component('TippyContent', TippyContent);
    // app.component('TomSelect', TomSelect)
    // app.component('LoadingIcon', LoadingIcon);
    // app.component('TinySlider', TinySlider);
    app.component('CKEditor', CKEditor);
    // app.component('FullCalendar', FullCalendar);
    // app.component('FullCalendarDraggable', FullCalendarDraggable);
    app.component('BaseCustomIcon', BaseCustomIcon);
    app.component('TaskIcon', TaskIcon);
    app.component('PaidIcon', PaidIcon);
    app.component('QrCodeIcon', QrCodeIcon);
    app.component('NotPaidIcon', NotPaidIcon);
    app.component('TextDescriptionIcon', TextDescriptionIcon);
    app.component('CommentIcon', CommentIcon);
    app.component('MoveFolderIcon', MoveFolderIcon);

    for (const [key, icon] of Object.entries(featherIcons)) {
        icon.props.size.default = '24';
        app.component(key, icon);
    }
};
