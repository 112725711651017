<template>
    <div v-if="notify" class="relative pr-10">
        <button type="button" class="block w-full rounded-md bg-gray-300" @click="handleClickNotification(notify)">
            <UserNotificationPipeline
                v-if="isShowPipeline"
                :pipeline="notify.pipeline"
                :notify="notify"
                @on-change-route="onChangeRoute"
                @show-modal-user="handleShowModalUser"
                @onClickNotify="() => $emit('onClickNotify')"
            />

            <a
                v-if="notify.link && (notify.text || notify.date_notify)"
                :href="notify.link"
                class="block py-2 pl-10 pr-4"
                @click.prevent="toRoute(notify.link)"
            >
                <p class="text-left mb-0">
                    <span v-if="notify.text" v-html="notify.text"></span>
                    <span v-if="notify.date_notify" class="text-gray-500 text-sm block mt-1">{{ dateNotify }}</span>
                </p>
            </a>
            <button v-else-if="notify.text || notify.date_notify" class="py-2 pl-10 pr-4 cursor-auto w-full">
                <p class="text-left mb-0">
                    <span v-if="notify.text" v-html="notify.text"></span>
                    <span v-if="notify.date_notify" class="text-gray-500 text-sm block mt-1">{{ dateNotify }}</span>
                </p>
            </button>
        </button>

        <button
            type="button"
            class="absolute top-2 right-2 w-5 h-5 bg-blue-600 rounded-full transition-all cursor-pointer"
            :class="{ 'bg-opacity-40': notify.read, 'bg-opacity-100': !notify.read }"
            @click="handleReadNotify(notify)"
        ></button>
    </div>
</template>

<script>
import { mapMutations } from 'vuex';
import { errorResponse } from '@/mixins/form/form-mixin';
import dayjs from 'dayjs';
import UserNotificationPipeline from '@/components/notifications/UserNotificationPipeline';

export default {
    name: 'UserNotification',
    components: {
        UserNotificationPipeline,
    },
    mixins: [errorResponse],
    props: {
        isShowPipeline: {
            default: false,
        },
        notify: {
            required: true,
        },
    },
    computed: {
        dateNotify() {
            return dayjs(this.notify.date_notify).format('D MMM в H:m');
        },
    },
    methods: {
        ...mapMutations({
            readNotificationById: 'user/readNotificationById',
        }),
        async handleReadNotify(notify) {
            this.$emit('readNotify', notify.id);
        },
        handleClickNotification(notify) {
            this.$emit('clickMessage',notify)
        },
        toRoute(link) {
            const options = { path: link };
            if (this.notify.note_id) {
                options.hash = `#note_id=${this.notify.note_id}`;
            }
            this.$router.push(options);

            this.onChangeRoute();
        },
        onChangeRoute() {
            this.$emit('on-change-route');
        },
        handleShowModalUser(user) {
            this.$emit('show-modal-user', user);
        },
    },
};
</script>

<style scoped lang="scss"></style>
