<template>
    <div>
        <ModalContainer
            class="header-offset"
            width="90%"
            height="auto"
            max-height="90%"
            :modal-open="show"
            v-on:closeModal="close"
        >
            <template v-slot:body>
                <Form :form="form" :copy="copy" v-on:close="$emit('close')" v-on:save="$emit('save')" />
            </template>
        </ModalContainer>
    </div>

    <modal-save-changes :show="showChanges" v-on:close="closeChange" />
</template>

<script>
import Form from '@/views/users/form';
import ModalContainer from '@/components/modals/ModalContainer';
import ModalSaveChanges from '@/components/modals/ModalSaveChanges';
import isEqual from 'lodash.isequal';
export default {
    name: 'ModalEdit',
    components: { ModalContainer, Form, ModalSaveChanges },
    props: {
        form: {
            required: true,
        },
        show: {
            required: true,
        },
        copy: {
            required: true,
        },
    },
    data() {
        return {
            showChanges: false,
        };
    },
    methods: {
        close() {
            if (isEqual(this.form, this.copy)) {
                this.$emit('close');
            } else {
                this.showChanges = true;
            }
        },
        closeChange(flag) {
            if (flag) {
                this.showChanges = false;
                this.$emit('close');
            } else {
                this.showChanges = false;
            }
        },
    },
};
</script>

<style scoped></style>
