const state = () => {
  return{
    loading:false,
  }
};

const mutations = {
  setLoading(state,flag){
    state.loading = flag;
  }
}

export default {
  namespaced: true,
  state,
  mutations,
};
