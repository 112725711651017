<template>
    <svg width="107px" height="133px" viewBox="0 0 107 133" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
        <defs>
            <circle id="path-1" cx="53.5" cy="53.5" r="53.5"></circle>
            <polygon id="path-3" points="7.3062266e-05 0.546576941 121.642019 0.546576941 121.642019 92.4774269 7.3062266e-05 92.4774269"></polygon>
            <circle id="path-5" cx="53.5" cy="53.5" r="53.5"></circle>
            <path d="M2.82870355,9.39568439 C2.82870355,9.39568439 10.8539215,-3.28955425 16.7592441,0.819036433 C16.7592441,0.819036433 19.2669934,-0.66516738 21.6312228,0.80288852 C23.9954521,2.27094442 22.6652668,4.9283292 22.6652668,4.9283292 C22.6652668,4.9283292 26.2007593,4.84618547 26.2567671,7.7478952 C26.3127749,10.650307 25.3172362,11.7736401 24.2208835,12.2650983 C24.2208835,12.2650983 27.0128724,15.5318913 20.9171233,19.0128196 C16.5114096,21.5283836 12.9773173,20.9653129 11.5624202,20.8550858 C11.5624202,20.8550858 9.94309459,19.0879425 7.45494799,20.0687527 C5.3889602,20.8838712 3.9166551,18.3872635 5.76911315,16.8012576 C6.74924969,15.9629703 6.65123603,14.6697331 5.99804504,13.8651458 C5.49117443,13.2402918 4.95700002,12.4855524 2.70758668,13.5948438 C2.17411236,13.8574229 1.42290772,14.1073645 0.913236723,13.4087918 C0.416167481,12.7277711 0.934939746,11.9870734 1.40820567,11.3060527 C1.99208701,10.4649571 2.34773655,9.90750302 2.82870355,9.39568439" id="path-7"></path>
        </defs>
        <g id="Examples" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
            <g id="notifications" transform="translate(-171.000000, -171.000000)">
                <g id="event" transform="translate(20.000000, 20.000000)">
                    <g id="empty" transform="translate(10.000000, 79.000000)">
                        <g id="empty-state" transform="translate(44.000000, 28.000000)">
                            <g id="taco" transform="translate(97.000000, 44.000000)">
                                <g id="body" transform="translate(0.000000, 26.000000)">
                                    <mask id="mask-2" fill="white">
                                        <use xlink:href="#path-1"></use>
                                    </mask>
                                    <use id="Mask" fill="#F7F0FA" xlink:href="#path-1"></use>
                                    <path d="M101.289497,86.3318069 C101.140577,83.9618554 98.253816,84.1554263 98.253816,84.1554263 C98.253816,84.1554263 99.2469991,81.9365405 97.2623512,80.820492 C95.2777033,79.7055924 93.2810272,81.008319 93.2810272,81.008319 C88.4640319,77.960582 82.5834028,87.8591218 82.2174028,88.4863606 C72.6504263,93.6122567 67.375215,94.2090526 61.5398347,95.205627 C52.2105577,96.7984228 32.4099005,92.3847758 24.4151775,102.987523 C20.5810554,108.073786 5.53839812,126.025911 16.7898911,129.475149 C40.9882761,136.893454 66.5578723,109.000284 66.5578723,109.000284 C66.5578723,109.000284 79.7882854,102.378665 90.4738817,97.6020007 C91.9006516,97.6502498 94.4030836,97.573281 97.3265014,95.7300491 C102.184736,92.6673779 99.7865484,90.0969626 99.7865484,90.0969626 C100.665177,89.6558277 101.43899,88.7023329 101.289497,86.3318069" id="Fill-1" fill="#627885" mask="url(#mask-2)"></path>
                                    <g id="Group-5" mask="url(#mask-2)">
                                        <g transform="translate(-1.000000, 77.452990)">
                                            <mask id="mask-4" fill="white">
                                                <use xlink:href="#path-3"></use>
                                            </mask>
                                            <g id="Clip-4" stroke="none" fill="none"></g>
                                            <path d="M121.629991,28.6325875 C121.598488,28.0874872 120.89169,27.9025322 120.591559,28.3574526 L118.333127,31.7791204 C117.996338,32.2897571 117.207634,31.9767121 117.305005,31.3724491 C118.425343,24.4239993 117.675587,17.924726 117.048977,14.3554388 C116.95046,13.793681 116.197268,13.7155633 115.967014,14.2371135 C115.783155,14.6524007 115.567793,15.0113972 115.356441,15.3100824 C115.047718,15.7460478 114.381014,15.5645391 114.326028,15.0320754 C113.753258,9.46849066 109.622441,4.84518962 107.256901,2.61768789 C106.832479,2.21905813 106.16062,2.6475564 106.331878,3.20471903 C106.92584,5.13583322 107.736883,8.47995779 108.184789,13.3559924 C108.325117,14.8769889 108.270704,18.4152588 107.307305,22.8714111 L106.275746,21.6083176 C96.7523005,21.6921792 105.391962,28.5699785 97.4510798,39.0406152 C71.5372488,73.2090443 67.9259343,25.8295426 67.9259343,25.8295426 C67.9259343,25.8295426 86.2253615,20.0723869 94.5305258,14.6219578 C102.83569,9.17210311 100.207249,1.13058062 89.4935869,3.42815848 C78.7793521,5.72573633 57.9270892,14.6087467 57.9270892,14.6087467 C57.9270892,14.6087467 24.766,-6.27566159 21.0023286,2.85778478 C19.6511643,6.13872595 14.0489014,6.33516886 8.61846948,13.8976464 C0.965690141,24.55611 -3.84214085,39.4714111 4.03344601,59.7188159 C19.7846197,100.213626 70.5314648,96.0469682 89.4935869,84.7049751 C116.394873,68.6138886 121.945587,34.165155 121.629991,28.6325875" id="Fill-3" stroke="none" fill="#8C9DA5" fill-rule="evenodd" mask="url(#mask-4)"></path>
                                        </g>
                                    </g>
                                    <path d="M79.0633305,90.7210997 C66.6737437,92.744117 49.286739,88.1799785 48.8514338,88.065674 C48.4178469,87.9467744 31.0852554,83.1775772 21.3659221,75.2113003 L20.0027296,80.3107744 C20.0027296,80.3107744 28.3353869,88.7124422 47.2597061,93.9400062 L47.2597061,93.9405806 L47.2797531,93.9457502 C47.2866263,93.9480478 47.2934995,93.9497709 47.3003728,93.9520685 L47.3003728,93.9509197 C66.2951427,98.9165599 77.7007108,95.8205737 77.7007108,95.8205737 L79.0633305,90.7210997 Z" id="Fill-6" fill="#1786C4" mask="url(#mask-2)"></path>
                                    <path d="M23.3430094,116.919403 C23.3430094,116.919403 31.5530939,127.450352 24.6798545,135.491874 C29.0666995,138.794068 51.9425587,133.216698 70.1955915,124.027535 C70.1955915,124.027535 71.8256948,116.061258 67.9652254,101.515867 C67.9652254,101.515867 38.6680423,115.316843 23.3430094,116.919403" id="Fill-8" fill="#627885" mask="url(#mask-2)"></path>
                                    <path d="M105.857681,116.303538 L102.930254,119.169192 C102.492657,119.59769 101.78815,119.124389 102.010385,118.55459 C103.590085,114.508555 104.435493,110.481475 104.885117,107.1385 C105.854244,105.153967 107.057634,103.210216 108.57662,101.334818 C108.57662,101.334818 107.219728,102.709344 105.12969,105.0276 C105.24539,103.82769 105.310113,102.759316 105.344479,101.867282 C105.366244,101.297482 104.646845,101.061406 104.311775,101.522645 C104.044291,101.889109 103.758479,102.194687 103.489277,102.441676 C103.095211,102.802396 102.481202,102.483607 102.540197,101.952292 C103.151915,96.3921536 100.088742,90.9980152 98.2455681,88.3201882 C97.9150798,87.8399945 97.167615,88.117427 97.217446,88.6981398 C97.3915681,90.7113924 97.4797746,94.1520152 96.8909671,99.0125412 C96.707108,100.528943 95.9086667,103.975884 94.0288357,108.127607 C94.0288357,108.127607 63.5929859,159.8076 74.4750423,159.753607 C81.076216,157.985621 93.9429202,144.072064 99.2198498,134.112638 C99.2198498,134.111489 99.2204225,134.11034 99.2204225,134.109766 C104.049446,126.034929 106.457944,119.071545 106.814779,116.792347 C106.898977,116.252991 106.247164,115.92214 105.857681,116.303538" id="Fill-10" fill="#627885" mask="url(#mask-2)"></path>
                                    <path d="M22.9878347,136.876854 C22.9878347,136.876854 46.2772338,121.854716 64.7971775,119.365864 C83.3165484,116.877013 85.4621446,113.590328 91.8445202,106.266224 C98.7028676,98.3958713 101.956201,93.7668263 104.922004,94.8564526 C107.887234,95.9460789 110.979619,104.409781 104.586933,109.514425 C97.895262,114.858591 92.6200507,123.386626 90.9275155,131.428148 C89.2349803,139.469671 81.5575718,157.65902 72.7953371,159.956598 L67.7887549,167.702882 L22.9878347,136.876854 Z" id="Fill-12" fill="#8C9DA5" mask="url(#mask-2)"></path>
                                    <path d="M91.8444056,106.266224 C91.8444056,106.266224 91.990462,93.9764803 97.8630723,94.2889509 C97.8630723,94.2889509 98.9719549,92.1740304 101.246997,92.1769024 C103.522039,92.1792 103.73511,94.6019958 103.73511,94.6019958 C103.73511,94.6019958 106.160218,93.0201135 107.443795,95.0161343 C108.727946,97.0115806 108.516021,98.2229785 107.96387,99.0380443 C107.96387,99.0380443 111.307701,100.107567 108.558406,105.158792 C106.570894,108.809643 103.869711,109.9412 102.837007,110.475387 C102.837007,110.475387 100.952021,109.942923 99.641523,111.699421 C98.5526873,113.157235 96.4580676,112.054972 97.0669221,110.152577 C97.3893915,109.145664 96.7667906,108.288667 95.9677765,108.009511 C95.3457484,107.793539 94.6509784,107.498875 93.5609972,109.241013 C93.3026779,109.653428 92.8868469,110.151428 92.2327437,109.884909 C91.5941052,109.625857 91.6382085,108.886612 91.6748657,108.207678 C91.7206873,107.371359 91.7298516,106.828556 91.8444056,106.266224" id="Fill-40" fill="#E4E7E9" mask="url(#mask-2)"></path>
                                    <path d="M98.3081718,86.1702298 C98.52697,85.9938907 99.4118995,85.167337 99.1822188,83.472299 C98.9204629,81.5417592 97.3871577,80.9754062 97.3871577,80.9754062 C97.3871577,80.9754062 97.615693,79.6594685 96.619646,79.2964512 C94.3732423,77.6651709 92.312416,80.0477592 92.0575333,80.4808526 C90.9933268,80.475683 89.6708009,80.2367349 88.4931859,80.8812055 C75.3486883,88.0743474 69.32143,88.4241536 62.5123408,89.2817246 C53.122923,90.4649772 33.5330451,85.189164 25.0846883,95.4323405 C21.0329136,100.346285 5.22389014,117.622922 16.3144347,121.56097 C40.1668667,130.030991 66.9261061,103.28259 66.9261061,103.28259 C66.9261061,103.28259 82.3651202,96.3818145 93.5306977,92.0750048 C94.5868854,91.6677592 95.2747822,90.7992747 95.9409136,90.1301052 C96.3103502,90.3294201 96.6734864,90.3581398 97.013139,90.1582505 C98.9548291,89.0175031 98.399815,86.6980983 98.2806789,86.2793647 C98.2898432,86.2431779 98.3001531,86.2064166 98.3081718,86.1702298" id="Fill-36" fill="#8C9DA5" mask="url(#mask-2)"></path>
                                </g>
                                <g id="head" transform="translate(16.000000, 30.000000)">
                                    <path d="M80.4729023,17.3848526 C78.4504516,8.97858962 77.204677,8.22440969 74.7704047,9.64890796 C72.3355596,11.074555 59.5330056,19.4733509 54.6587333,28.8038145 L49.1990901,27.7245273 L43.7102357,26.6394962 C42.7302263,16.1516277 34.0630714,3.50977993 32.3499164,1.26504637 C30.6361887,-0.980261592 29.1985362,-0.755673356 24.1461324,6.2519391 C11.9564423,24.4303751 13.1586864,40.559946 13.1586864,40.559946 C6.21843286,48.7892955 0.306874178,70.1659599 0.306874178,70.1659599 C0.306874178,70.1659599 6.26940939,64.0050048 18.6841981,69.5088526 C22.068123,71.0085965 25.0081512,72.9776208 27.5002732,75.047164 C28.641231,81.8847557 31.6373906,84.7532817 34.0699446,85.9543405 C36.055738,86.4758907 37.4034657,86.7711294 37.5569681,86.8044443 C37.7425455,86.8383336 39.1005831,87.0778561 41.135062,87.3506934 C43.8402545,87.165164 47.6978601,85.6516346 51.3412498,79.7589218 C54.4296254,78.7922159 57.8943108,78.0903059 61.5926864,77.9897869 C75.1616066,77.6210256 78.2355901,83.2190984 78.2355901,83.2190984 C78.2355901,83.2190984 80.9654845,63.5627211 77.6588836,53.3080567 C77.6588836,53.3080567 84.8734939,38.8419322 80.4729023,17.3848526 Z" id="Fill-14" fill="#8C9DA5"></path>
                                    <path d="M37.580108,86.8091543 L45.4385117,46.8221093 C43.3249906,42.5836526 44.4464742,39.2946699 43.5959108,37.2205315 C42.7453474,35.1458187 38.2508216,34.0671059 38.2508216,34.0671059 C38.8161455,33.6070159 43.9332723,32.2353619 43.9332723,32.2353619 C42.0660423,31.5805522 38.8092723,31.2227045 38.8092723,31.2227045 C44.0695915,28.640227 49.1918732,27.722919 49.1918732,27.722919 L49.1764085,27.7200471 L43.7179108,26.6413343 C42.7373286,16.1534657 34.0701737,3.5110436 32.3570188,1.26631003 C30.6444366,-0.978423529 29.2062113,-0.753835294 24.1538075,6.25377716 C11.9635446,24.4316388 13.1657887,40.5612097 13.1657887,40.5612097 C6.22668075,48.7911336 0.313976526,70.1672235 0.313976526,70.1672235 C0.313976526,70.1672235 6.27765728,64.0068429 18.6918732,69.5106907 C22.0757981,71.0104346 25.0152535,72.9794588 27.5073756,75.0478533 C28.6483333,81.8865938 31.6456385,84.7551197 34.0776197,85.9556042 C36.0639859,86.4777287 37.4111408,86.7729675 37.5646432,86.805708 L37.5646432,86.8062824 C37.5646432,86.8062824 37.5772441,86.8085799 37.580108,86.8091543" id="Fill-16" fill="#627885"></path>
                                    <path d="M67.9122864,66.9432048 C70.191338,62.9138277 74.2144742,52.938319 64.6835822,45.3362083 C55.3565962,37.8966512 45.7609812,46.8261875 45.3537418,47.2116062 C45.1234883,46.6998208 39.6231784,34.7868796 28.1792347,38.1212394 C16.4844178,41.5285474 16.4322958,52.2886789 17.0170939,56.8838346 C17.0170939,56.8838346 7.22616432,52.8521599 0.307103286,70.1655578 L4.38064319,70.7164021 L3.77923474,73.7750526 C6.84985446,72.9841114 11.2172254,73.9737931 16.8630188,78.7757308 C22.3856667,83.4714055 36.9225681,86.6667619 37.5571972,86.8046166 L37.5571972,86.805191 C37.5571972,86.805191 37.5686526,86.8069142 37.5720892,86.808063 C37.5755258,86.8086374 37.587554,86.8109349 37.587554,86.8109349 L37.587554,86.8103606 C38.2267653,86.9240907 52.8856667,89.5002498 59.771507,87.2560907 C66.8119953,84.9619592 71.2280516,85.7075232 73.7694319,87.6081945 L74.4006244,84.5552879 L78.3475822,85.5897723 C78.4965023,66.9380353 67.9122864,66.9432048 67.9122864,66.9432048" id="Fill-18" fill="#FEFEFE"></path>
                                    <path d="M18.6843127,69.50891 C6.26952394,64.0050623 0.306988732,70.1660173 0.306988732,70.1660173 L4.38052864,70.7168616 L3.77969296,73.7749377 C6.84973991,72.9839965 11.2171108,73.9736782 16.8629042,78.7756159 C22.5092704,83.5769792 37.5874394,86.8108201 37.5874394,86.8108201 C37.5874394,86.8108201 31.0991014,75.0121834 18.6843127,69.50891" id="Fill-20" fill="#E4E7E9"></path>
                                    <path d="M15.251015,38.6161377 C15.251015,38.6161377 19.4677474,29.250636 28.4407615,27.1161862 C33.1930338,26.1494803 34.1300854,27.6687536 35.674846,24.1620754 C37.2190338,20.6542484 34.405015,7.98999931 31.6872216,4.31100277 C30.2741981,2.3161308 28.7030901,3.05422768 27.4275315,4.70848374 C26.1519728,6.36273979 15.7481793,20.1281031 15.251015,38.6161377" id="Fill-22" fill="#DADFE1"></path>
                                    <path d="M31.7987399,9.22609619 C31.7987399,9.22609619 34.3618854,14.5616464 34.3647493,20.0270097 C34.3676131,25.4917986 31.5598948,23.9840131 29.5374441,24.6014872 C27.5149934,25.2183869 25.3951718,25.6888159 24.7742892,25.946719 C24.1528338,26.2057709 23.3692845,25.0340062 25.0990498,24.2953349 C26.8276695,23.5572381 29.2504864,22.1930512 27.8328808,21.0235841 C26.4152751,19.8535426 25.2302141,19.1114249 24.8138103,18.3302484 C24.3968338,17.5484976 24.9581484,17.5955979 26.2268338,17.9103661 C27.4955192,18.2245599 28.8478291,18.4916533 28.0390779,16.6162554 C27.229754,14.7408574 27.1902329,13.6529543 27.3826836,12.6741862 C27.5751343,11.695418 28.1123925,12.1825045 28.9583737,13.684546 C29.8037822,15.1860131 30.0495005,15.8695426 30.2763174,14.0722623 C30.5025615,12.2744076 31.1440638,7.66546644 31.7987399,9.22609619" id="Fill-24" fill="#F3F5F5"></path>
                                    <path d="M47.5499136,72.0684118 C47.1214817,74.2476644 43.6121202,76.0081834 39.8564676,75.2654913 C36.100815,74.5233737 33.5199136,71.5589239 33.9483455,69.3802457 C34.3762047,67.2009931 37.6513033,66.632917 41.4069559,67.375609 C45.1631812,68.1177266 47.9777728,69.8897336 47.5499136,72.0684118" id="Fill-26" fill="#393A3A"></path>
                                    <path d="M76.4580141,50.7131723 C76.4580141,50.7131723 76.1000329,40.4435737 68.605338,35.0546048 C64.5741831,32.3515045 63.1325211,33.4009232 63.0305681,29.5679889 C62.9280423,25.7362035 70.3236479,15.0886533 74.229939,12.7192761 C76.2930563,11.4113799 77.4672347,12.6922796 78.0211033,14.7078298 C78.5755446,16.7233799 82.9927465,33.4187294 76.4580141,50.7131723" id="Fill-28" fill="#DADFE1"></path>
                                    <path d="M72.2679155,17.2244242 C72.2679155,17.2244242 67.8776338,21.1854484 65.8070704,26.2412685 C63.7359343,31.2965142 66.9050704,30.9702581 68.5426197,32.3103204 C70.1795962,33.6503827 71.9637746,34.8927979 72.4403192,35.3678221 C72.9174366,35.8434208 74.0853146,35.0576491 72.7650798,33.7164381 C71.4448451,32.375227 69.719662,30.1913792 71.4734836,29.6485765 C73.2278779,29.1063481 74.6042441,28.8696976 75.2858404,28.3050678 C75.9668638,27.7410125 75.4301784,27.5715661 74.1374366,27.3797183 C72.8441221,27.1872962 71.492385,26.9202028 72.9500845,25.4928325 C74.407784,24.0648879 74.8568357,23.0740574 75.0487136,22.0952893 C75.2411643,21.1159467 74.5589953,21.3629363 73.2089765,22.4301612 C71.858385,23.4979606 71.3726761,24.0367426 71.8429202,22.287137 C72.3137371,20.5381059 73.4638592,16.0296837 72.2679155,17.2244242" id="Fill-30" fill="#F3F5F5"></path>
                                    <path d="M28.0432592,55.4261363 C29.8703953,57.2102055 32.7943859,57.1705723 34.5728366,55.338254 C34.8775502,55.0246346 35.1238413,54.677126 35.3220197,54.3112367 C35.2647427,55.3922471 34.8363108,56.4588976 34.024123,57.2952159 C32.2450995,59.1281087 29.3211089,59.1677419 27.4939728,57.3836727 C25.9789962,55.9046069 25.6966207,53.6386208 26.6571559,51.8620187 C26.589569,53.1486623 27.0500761,54.4571329 28.0432592,55.4261363" id="Fill-32" fill="#010202"></path>
                                    <path d="M53.8900761,61.0321114 C55.9325737,62.5634471 58.8262075,62.1441391 60.3532122,60.095274 C60.6143953,59.7454678 60.814292,59.3686651 60.9632122,58.9798 C61.0462638,60.0596616 60.7593061,61.1722637 60.0622451,62.1079522 C58.5352404,64.1562429 55.6416066,64.5755509 53.5985362,63.0447896 C51.9048554,61.7748035 51.3320854,59.5645336 52.0549211,57.6782221 C52.1540103,58.9625682 52.7800479,60.2003882 53.8900761,61.0321114" id="Fill-34" fill="#010202"></path>
                                </g>
                                <g id="arm" transform="translate(0.000000, 26.000000)">
                                    <mask id="mask-6" fill="white">
                                        <use xlink:href="#path-5"></use>
                                    </mask>
                                    <g id="Mask"></g>
                                    <path d="M75.8287035,84.3956844 C75.8287035,84.3956844 83.8539215,71.7104457 89.7592441,75.8190364 C89.7592441,75.8190364 92.2669934,74.3348326 94.6312228,75.8028885 C96.9954521,77.2709444 95.6652668,79.9283292 95.6652668,79.9283292 C95.6652668,79.9283292 99.2007593,79.8461855 99.2567671,82.7478952 C99.3127749,85.650307 98.3172362,86.7736401 97.2208835,87.2650983 C97.2208835,87.2650983 100.012872,90.5318913 93.9171233,94.0128196 C89.5114096,96.5283836 85.9773173,95.9653129 84.5624202,95.8550858 C84.5624202,95.8550858 82.9430946,94.0879425 80.454948,95.0687527 C78.3889602,95.8838712 76.9166551,93.3872635 78.7691132,91.8012576 C79.7492497,90.9629703 79.651236,89.6697331 78.998045,88.8651458 C78.4911744,88.2402918 77.957,87.4855524 75.7075867,88.5948438 C75.1741124,88.8574229 74.4229077,89.1073645 73.9132367,88.4087918 C73.4161675,87.7277711 73.9349397,86.9870734 74.4082057,86.3060527 C74.992087,85.4649571 75.3477366,84.907503 75.8287035,84.3956844" id="Fill-38" fill="#ACAEAF" opacity="0.156080163" mask="url(#mask-6)" transform="translate(86.485106, 85.492930) rotate(-7.000000) translate(-86.485106, -85.492930) "></path>
                                    <path d="M99.1822188,83.472299 C98.9204629,81.5417592 97.3871577,80.9754062 97.3871577,80.9754062 C97.3871577,80.9754062 97.615693,79.6594685 96.619646,79.2964512 C94.3732423,77.6651709 92.312416,80.0477592 92.0575333,80.4808526 C90.9933268,80.475683 89.6708009,80.2367349 88.4931859,80.8812055 C75.3486883,88.0743474 69.32143,88.4241536 62.5123408,89.2817246 C53.122923,90.4649772 42.4726563,79.2964512 25.0846883,95.4323405 C19.9843761,100.165388 15.5920726,103.909282 26.6826172,107.847331 C50.5350491,116.317351 65.5,110.662109 65.5,110.662109 C65.5,110.662109 82.6236306,98.6354097 93.5306977,92.0750048 C94.3537083,91.5799787 95.2747822,90.7992747 95.9409136,90.1301052 C96.3103502,90.3294201 96.6734864,90.3581398 97.013139,90.1582505 C98.9548291,89.0175031 98.399815,86.6980983 98.2806789,86.2793647 C98.2898432,86.2431779 98.3001531,86.2064166 98.3081718,86.1702298 C98.52697,85.9938907 99.4118995,85.167337 99.1822188,83.472299 Z" id="Fill-36" fill="#8C9DA5" mask="url(#mask-6)"></path>
                                    <g id="Group-4" mask="url(#mask-6)">
                                        <g transform="translate(79.000000, 73.000000)">
                                            <mask id="mask-8" fill="white">
                                                <use xlink:href="#path-7"></use>
                                            </mask>
                                            <use id="Mask" stroke="none" fill="#FFFFFF" fill-rule="evenodd" xlink:href="#path-7"></use>
                                            <path d="M12.406805,1.97004581 C14.7479149,1.46164596 16.8102105,1.66561175 18.593692,2.58194318" id="Path-2" stroke="#8C9DA5" stroke-width="0.5" fill="none" stroke-linecap="round" mask="url(#mask-8)" transform="translate(15.500248, 2.197920) scale(-1, 1) rotate(42.000000) translate(-15.500248, -2.197920) "></path>
                                            <path d="M19.406805,4.97004581 C21.7479149,4.46164596 23.8102105,4.66561175 25.593692,5.58194318" id="Path-2" stroke="#8C9DA5" stroke-width="0.5" fill="none" stroke-linecap="round" mask="url(#mask-8)" transform="translate(22.500248, 5.197920) scale(-1, 1) rotate(19.000000) translate(-22.500248, -5.197920) "></path>
                                        </g>
                                    </g>
                                    <g id="fingers" mask="url(#mask-6)">
                                        <g transform="translate(90.000000, 73.000000)"></g>
                                    </g>
                                </g>
                                <g id="stars" transform="translate(12.000000, 0.000000)" fill="#DFC0EB">
                                    <path d="M6.26966225,58.2585898 L8.01652622,61.4527492 C8.06222859,61.5360793 8.13086288,61.6047136 8.21419299,61.6502545 L11.4083524,63.39728 C11.7531388,63.5859032 11.7531388,64.0810391 11.4083524,64.2696623 L8.21419299,66.0166877 C8.13086288,66.0622286 8.06222859,66.1308629 8.01652622,66.214193 L6.26966225,69.4083524 C6.08103905,69.7531388 5.58590316,69.7531388 5.39727997,69.4083524 L3.65025451,66.214193 C3.60471363,66.1308629 3.53607934,66.0622286 3.45274922,66.0166877 L0.258589806,64.2696623 C-0.0861966019,64.0810391 -0.0861966019,63.5859032 0.258589806,63.39728 L3.45274922,61.6502545 C3.53607934,61.6047136 3.60471363,61.5360793 3.65025451,61.4527492 L5.39727997,58.2585898 C5.58590316,57.9138034 6.08103905,57.9138034 6.26966225,58.2585898 Z" id="Page-1"></path>
                                    <path d="M58.2990954,37.1773145 L59.4969167,39.3675436 C59.5282547,39.4246828 59.5753172,39.4717453 59.6324564,39.5029725 L61.8226855,40.7009046 C62.0591048,40.8302431 62.0591048,41.1697569 61.8226855,41.2990954 L59.6324564,42.4970275 C59.5753172,42.5282547 59.5282547,42.5753172 59.4969167,42.6324564 L58.2990954,44.8226855 C58.1697569,45.0591048 57.8302431,45.0591048 57.7009046,44.8226855 L56.5029725,42.6324564 C56.4717453,42.5753172 56.4246828,42.5282547 56.3675436,42.4970275 L54.1773145,41.2990954 C53.9408952,41.1697569 53.9408952,40.8302431 54.1773145,40.7009046 L56.3675436,39.5029725 C56.4246828,39.4717453 56.4717453,39.4246828 56.5029725,39.3675436 L57.7009046,37.1773145 C57.8302431,36.9408952 58.1697569,36.9408952 58.2990954,37.1773145 Z" id="Page-1"></path>
                                    <path d="M45.4486431,16.2659718 L47.2453751,19.5513153 C47.2923821,19.6370243 47.3629757,19.7076179 47.4486847,19.7544588 L50.7340282,21.5513569 C51.0886573,21.7453647 51.0886573,22.2546353 50.7340282,22.4486431 L47.4486847,24.2455412 C47.3629757,24.2923821 47.2923821,24.3629757 47.2453751,24.4486847 L45.4486431,27.7340282 C45.2546353,28.0886573 44.7453647,28.0886573 44.5513569,27.7340282 L42.7544588,24.4486847 C42.7076179,24.3629757 42.6370243,24.2923821 42.5513153,24.2455412 L39.2659718,22.4486431 C38.9113427,22.2546353 38.9113427,21.7453647 39.2659718,21.5513569 L42.5513153,19.7544588 C42.6370243,19.7076179 42.7076179,19.6370243 42.7544588,19.5513153 L44.5513569,16.2659718 C44.7453647,15.9113427 45.2546353,15.9113427 45.4486431,16.2659718 Z" id="Page-1"></path>
                                    <path d="M29.5981909,0.354629072 L31.9938334,4.7350871 C32.0565095,4.8493657 32.1506343,4.94349051 32.2649129,5.00594509 L36.6453709,7.40180913 C37.1182097,7.66048627 37.1182097,8.33951373 36.6453709,8.59819087 L32.2649129,10.9940549 C32.1506343,11.0565095 32.0565095,11.1506343 31.9938334,11.2649129 L29.5981909,15.6453709 C29.3395137,16.1182097 28.6604863,16.1182097 28.4018091,15.6453709 L26.0059451,11.2649129 C25.9434905,11.1506343 25.8493657,11.0565095 25.7350871,10.9940549 L21.3546291,8.59819087 C20.8817903,8.33951373 20.8817903,7.66048627 21.3546291,7.40180913 L25.7350871,5.00594509 C25.8493657,4.94349051 25.9434905,4.8493657 26.0059451,4.7350871 L28.4018091,0.354629072 C28.6604863,-0.118209691 29.3395137,-0.118209691 29.5981909,0.354629072 Z" id="Page-1"></path>
                                </g>
                            </g>
                        </g>
                    </g>
                </g>
            </g>
        </g>
    </svg>
</template>

<script>
export default {
    name: 'NoNotificationsImage',
};
</script>
