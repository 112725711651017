<template>
    <a
        v-if="pipeline"
        class="flex flex-col gap-2 rounded-t-md p-2 bg-gray-400"
        :href="`/pipeline/${pipeline.id}/show`"
        @click.prevent.stop="toRoute(`/pipeline/${pipeline.id}/show`)"
    >
        <UserNotificationDeal
            v-if="pipeline.deal"
            :pipelineId="pipeline.id"
            :deal="pipeline.deal"
            :notify="notify"
            @onClickNotify="$emit('onClickNotify')"
            @show-modal-user="handleShowModalUser"
            @on-change-route="onClickDealNotify"
        />

        <p v-if="pipeline.name" class="text-white mb-0 text-left">
            {{ pipeline.name }}: <span v-if="pipeline.status">{{ pipeline.status.name }}</span>
        </p>
    </a>
</template>

<script>
import UserNotificationDeal from '@/components/notifications/UserNotificationDeal';

export default {
    name: 'UserNotificationPipeline',
    components: {
        UserNotificationDeal,
    },
    props: {
        pipeline: {
            required: true,
        },
        notify: {
            required: true,
        },
    },
    methods: {
        toRoute(link) {
            const options = { path: link };
            if (this.notify.note_id) {
                options.hash = `#note_id=${this.notify.note_id}`;
            }
            this.$router.push(options);
            this.$emit('on-change-route');
        },
        onClickDealNotify() {
            this.$emit('on-click-notify');
        },
        handleShowModalUser(user) {
            this.$emit('show-modal-user', user);
        },
    },
};
</script>

<style scoped lang="scss"></style>
